import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay, tap } from 'rxjs';
import { Vehicle, VehicleFile } from '../models/vehicle/vehicle';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { MappingModel } from '../models/user/users';
import { CommonService } from './common.service';
import { BookingUsage } from '../models/booking/booking';
import { FileModel } from '../models/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { VehicleRequest, VehicleUsageRequest } from '../models/vehicle/vehicle-request';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { VehicleDataFilter, VehicleFieldFilter, VehicleFileFilter, VehicleFilter, VehiclesFilter, VehicleUsageFilter } from '../models/vehicle/vehicle-filter';
import { TrackResponse } from '../models/track/track-response';
import { TrackExportFilter, TrackFilter } from '../models/track/track-filter';
import { VehicleUsageResponse } from '../models/vehicle-usages/vehicle-usages-response';
import { VehicleDataResponse, VehicleFieldResponse, VehicleFileResponse, VehicleResponse } from '../models/vehicle/vehicle-response';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class VehiclesService {
  private _vehicleImageCache = new Map<string, Observable<any>>();
  private _tenantId: string;

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listVehicleFields$(tenantId: string, params?: VehicleFieldFilter): Observable<VehicleFieldResponse> {
    return this._http.get<VehicleFieldResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-fields`,
      { params: this._commonService.buildParams(params) });
  }

  createVehicle$(tenantId: string, request: VehicleRequest): Observable<Vehicle> {
    return this._http.post<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles`, request)
      .pipe(
        tap({
          error: error => {
            this._commonService.handleError(error, false);
            if (error.status === 500) {
              this._toastrService.error(this._translateService.instant('VEHICLES.'+error.error.errorCode));
            }
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant('VEHICLES.MESSAGES.ERROR_CREATING'));
            }
          }
        })
      );
  }

  listVehicles$(tenantId: string, params?: VehiclesFilter): Observable<VehicleResponse> {
    return this._http.get<VehicleResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles`,
      { params: this._commonService.buildParams(params) });
  }

  listVehiclesNew$(params?: VehiclesFilter): Observable<VehicleResponse> {
    return this._http.get<VehicleResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/vehicles`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getVehicle$(tenantId: string, vehicleId: string, params?: VehicleFilter): Observable<Vehicle> {
    return this._http.get<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getVehicleById$(vehicleId: string): Observable<Vehicle> {
    return this._http.get<Vehicle>(`${VECTORE_API_BASE_URL}/${this._tenantId}/vehicles/${vehicleId}`);
  }

  updateVehicle$(tenantId: string, vehicleId: string, request: VehicleRequest): Observable<Vehicle> {
    return this._http.patch<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`, request)
      .pipe(
        tap(response => {
          if (response) {
            this._toastrService.success(this._translateService.instant('VEHICLES.MESSAGES.SUCCESS_UPDATED'));
          }
        }),
        tap({
          error: error => {
            if (error.status === 404) {
              this._toastrService.error(this._translateService.instant('VEHICLES.MESSAGES.ERROR_UPDATING_NOT_FOUND'));
            }
            this._commonService.handleError(error);
          }
        })
      );
  }

  deleteVehicle$(tenantId: string, vehicleId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}`,
      { observe: 'response' });
  }

  getVehicleImage$(vehicleId: string, width?: number, height?: number, safe: boolean = true): Observable<SafeUrl | string | null> {
    if (!vehicleId) {
      return of(null);
    }

    const cacheKey = `${this._tenantId}-${vehicleId}-${width}-${height}`;
    if (!this._vehicleImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get(`${VECTORE_API_BASE_URL}/${this._tenantId}/vehicles/${vehicleId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      })
        .pipe(
          shareReplay(1),
          catchError(error => this._commonService.hanleErrorImage(error))
        );
      this._vehicleImageCache.set(cacheKey, requestObservable);
    }
    return this._vehicleImageCache.get(cacheKey)
      .pipe(
        map(response => {
          if (response) {
            let urlCreator = window.URL || window.webkitURL;
            if (!safe) {
              return urlCreator.createObjectURL(response);
            }
            return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
          }
          return null;
        })
      );
  }

  undeleteVehicle$(tenantId: string, vehicleId: string): Observable<HttpResponse<Object>> {
    return this._http.post<Vehicle>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/undelete`, {}, { observe: 'response' });
  }

  exportVehicles$(tenantId: string, exportData: MappingModel, params?: VehiclesFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/vehicles`, exportData, {
      observe: 'response',
      params: this._commonService.buildParams(params),
      responseType: 'blob'
    })
      .pipe(
        tap({
          next: response => {
            const now = new Date();
            const filename = `export_vehicles_${now.toLocaleDateString('it-IT')}.xlsx`;
            this._commonService.downloadFile(response, filename);
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listVehicleTracks$(tenantId: string, vehicleId: string, params?: TrackFilter): Observable<TrackResponse> {
    return this._http.get<TrackResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/tracks`,
      { params: this._commonService.buildParams(params) });
  }

  exportVehiclesTracks$(tenantId: string, vehicleId: string, exportData: MappingModel, params?: TrackExportFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/export/tracks`, exportData, {
      observe: 'response',
      params: this._commonService.buildParams(params),
      responseType: 'blob'
    })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createVehicleUsages$(tenantId: string, vehicleId: string, usageRequest: VehicleUsageRequest): Observable<BookingUsage> {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages`, usageRequest);
  }

  listVehicleUsages$(tenantId: string, vehicleId: string, params?: VehicleUsageFilter): Observable<VehicleUsageResponse> {
    return this._http.get<VehicleUsageResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages`,
      { params: this._commonService.buildParams(params) });
  }

  getVehicleUsage$(tenantId: string, vehicleId: string, usageId: string): Observable<BookingUsage> {
    return this._http.get<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`);
  }

  updateVehicleUsage$(tenantId: string, vehicleId: string, usageId: string, usageRequest: VehicleUsageRequest): Observable<BookingUsage> {
    return this._http.patch<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`, usageRequest);
  }

  deleteVehicleUsage$(tenantId: string, vehicleId: string, usageId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/usages/${usageId}`,
      { observe: 'response' });
  }

  linkVehicleFile$(tenantId: string, vehicleId: string, file: FileModel): Observable<HttpResponse<VehicleFile>> {
    return this._http.post<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files`, file,
      { observe: 'response' });
  }

  listVehicleFiles$(tenantId: string, vehicleId: string, params?: VehicleFileFilter): Observable<VehicleFileResponse> {
    return this._http.get<VehicleFileResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files`,
      { params: this._commonService.buildParams(params) });
  }

  getVehicleFile$(tenantId: string, vehicleId: string, fileId: string): Observable<VehicleFile> {
    return this._http.get<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`);
  }

  updateVehicleFile$(tenantId: string, vehicleId: string, fileId: string, file: FileModel): Observable<VehicleFile> {
    return this._http.patch<VehicleFile>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`, file);
  }

  deleteVehicleFile$(tenantId: string, vehicleId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}`,
      { observe: 'response' });
  }

  downloadVehicleFile$(tenantId: string, vehicleId: string, fileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/vehicles/${vehicleId}/files/${fileId}/content`,
      { observe: 'response', responseType: 'blob' });
  }

  getVehicleData$(tenantId: string, params?: VehicleDataFilter): Observable<VehicleDataResponse> {
    return this._http.get<VehicleDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/vehicle-data`,
      { params: this._commonService.buildParams(params) });
  }

  mapVehicleToRequest(vehicle: Vehicle): VehicleRequest {
    return {
      versionId: vehicle.versionId ?? null,
      licensePlate: vehicle.licensePlate ?? null,
      registrationDate: vehicle.registrationDate ?? null,
      fleetEntryDate: vehicle.fleetEntryDate ?? null,
      fleetEntryValue: vehicle.fleetEntryValue ?? null,
      displayName: vehicle.displayName ?? null,
      locationId: vehicle.locationId ?? null,
      purchaseTypeId: vehicle.purchaseTypeId ?? null,
      imageFileId: vehicle.imageFileId ?? null,
      mileage: vehicle.mileage ?? null,
      tagIds: vehicle.tagIds ?? null,
      extraFields: vehicle.extraFields ?? null,
      fleetExitDate: vehicle.fleetExitDate ?? null,
      disabledFrom: vehicle.disabledFrom ?? null,
      disabledTo: vehicle.disabledTo ?? null,
    }
  }
}