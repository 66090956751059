import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, Subscription, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { UserModel } from 'src/app/shared/models/user/users';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'button-add-user-primary',
    imports: [
        CommonModule,
        TranslateModule,
        TypeaheadModule,
        ReactiveFormsModule,
    ],
    templateUrl: './button-add-user-primary.component.html',
    styleUrls: ['./button-add-user-primary.component.scss']
})
export class ButtonAddUserPrimaryComponent {

  tenantId: string = this._tenantService.getTenantId();

  @Input() text: string;

  @Input() userId: string;
  user: Observable<UserModel>;
  userSrcImage: Observable<SafeUrl>;

  @Input() autoCompleteParams: any = {};

  isEditMode = false;

  form: FormGroup = new FormGroup({
    assignedUserName: new FormControl(),
  });
  assignedUserName$: Observable<string[]>;

  @Output() userIdChange = new EventEmitter<string>();

  subscription: Subscription;

  constructor(
    private _tenantService: TenantService,
    private _usersService: UsersService,
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.autoComplete();

    if (this.userId) {
      this.getUser();
    }
  }

  private getUser() {
    this.user = this._usersService.getUser$(this.tenantId, this.userId);
    this.userSrcImage = this._usersService.getUserImage$(this.tenantId, this.userId, 48, 48, true);
  }

  onAddUser() {
    this.isEditMode = true;
  }

  private autoComplete() {
    this.assignedUserName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['assignedUserName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._usersService.listUsers$({ displayNameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((users) => {
        let userNames: string[] = [];
        users.items.forEach((user) => {
          userNames.push(user.displayName);
        });
        return userNames;
      }),
    );
  }

  onSelectUser(match: TypeaheadMatch) {
    const displayName = match.value;

    this._usersService.listUsers$({ displayName: displayName }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.user = of(response.items[0]);
          this.userSrcImage = this._usersService.getUserImage$(this.tenantId, response.items[0].id, 48, 48, true);
          this.form.controls['assignedUserName'].setValue(null);
          this.isEditMode = false;
          this.userIdChange.emit(response.items[0].id);
        }
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this._elementRef.nativeElement.contains(event.target);
    const clickedOnInput = (event.target as HTMLElement).id === 'userId';
    const clickedOnButton = (event.target as HTMLElement).closest('#btn-add-user-primary');
    if (!clickedInside && !clickedOnInput && !clickedOnButton && this.isEditMode) {
      this.isEditMode = false;
    } else if (clickedOnButton && !this.isEditMode) {
      this.isEditMode = true;
    }
  }

  onDelete() {
    this.user = of(null);
    this.isEditMode = true;
    this.userIdChange.emit(null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}