export const BOOKING_TYPE = "bookingTypeId";
export const VEHICLE = "vehicleId";
export const HEADQUARTER = "headquarterId";
export const LOCATION = "locationId";
export const USER = "userId";
export const INSERTED_BY_USER = "insertedByUserId";
export const APPROVED_BY_USER = "approvedByUserId";
export const REJECTED_BY_USER = "rejectedByUserId";
export const IS_FRINGE = "isFringe";
export const STATUS = "status";
export const RANGE_START = "rangeStart";
export const RANGE_END = "rangeEnd";
