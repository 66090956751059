import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@Component({
    selector: 'input-date-picker',
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        BsDatepickerModule,
    ],
    templateUrl: './input-date-picker.component.html',
    styleUrls: ['./input-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputDatePickerComponent),
            multi: true
        }
    ]
})
export class InputDatePickerComponent implements ControlValueAccessor {
  value: Date | null = null;
  onChange = (value: any) => {};
  onTouched = () => {};
  
  @Input() cssClass: string;
  @Input() isMobile: boolean = false;
  @Input() placeholder: string = 'SELECT_DATE';
  @Input() maxDate: Date;

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
