import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Task, TaskComment } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { TaskCommentRequest } from 'src/app/shared/models/task/task-request';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'task-section-comments',
    templateUrl: './task-section-comments.component.html',
    styleUrls: ['./task-section-comments.component.scss'],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule],
})
export class TaskSectionCommentsComponent {


  tenantId = this._tenantService.getTenantId();
  @Input() task: Task;

  commentsForm: FormGroup;
  comments: Comment[];

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _usersService: UsersService,
  ) {
    this.commentsForm = new FormGroup({
      comment: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    this.getCommentsTask();
  }

  getCommentsTask() {
    let query = {
      includeUser: true,
      defaultQueryParams: {
        orderBy: '-Id'
      }
    };

    this._taskService.listCommentsTask$(this.tenantId, this.task.id, query)
      .subscribe({
        next: (response) => {
          let comments = response.items;
          this.comments = comments.map((comment: TaskComment) => {
            return <Comment>{
              displayName: comment.user?.displayName,
              comment: comment.content,
              date: comment.createDate,
              srcImage: this._usersService.getUserImage$(this.tenantId, comment.userId, 48, 48),
            };
          });
        }
      });
  }

  submitComment() {

    let requestComment: TaskCommentRequest = {
      content: this.commentsForm.value.comment,
    };

    this.commentsForm.patchValue({
      comment: null,
    });

    this._taskService.createCommentTask$(this.tenantId, this.task.id, requestComment).subscribe({
      next: (response) => {
        if (response.status === 201) {
          this.commentsForm.reset();
          this.getCommentsTask();
        }
      },
      error: () => {
        this.commentsForm.patchValue({
          comment: requestComment.content,
        });
      }
    });
  }
}

export interface Comment {
  displayName?: string;
  date?: string;
  srcImage?: any;
  comment?: string;
}