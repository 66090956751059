import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { CommonService } from './common.service';
import { ExtraFieldResponse } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FuelTypeResponse, MakeResponse, ModelResponse, PurchaseTypeResponse, VehicleTypesResponse, VersionResponse } from '../models/make/make-response';
import { FuelType, Make, Model, PurchaseType, VehicleType, VersionModel } from '../models/make/make';
import { CreateVersionRequest, MakeRequest, ModelRequest, PatchVersionRequest } from '../models/make/make-request';
import { FuelTypeFilter, MakesFilter, ModelsFilter, PurchaseTypeFilter, VehicleTypeFilter, VersionFieldFilter, VersionFilter, VersionsFilter } from '../models/make/make-filter';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class MakesService {

  private _tenantId: string;  

  constructor(
    private _http: HttpClient,
    private _tenantService: TenantService,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listVehicleTypes$(params?: VehicleTypeFilter): Observable<VehicleTypesResponse> {
    return this._http.get<VehicleTypesResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/vehicle-types`, { params: this._commonService.buildParams(params) });
  }

  getVehicleType$(vehicleTypeId: string): Observable<VehicleType> {
    return this._http.get<VehicleType>(`${VECTORE_API_BASE_URL}/${this._tenantId}/vehicle-types/${vehicleTypeId}`);
  }

  listFuelTypes$(tenantId: string, params?: FuelTypeFilter): Observable<FuelTypeResponse> {
    return this._http.get<FuelTypeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/fuel-types`, { params: this._commonService.buildParams(params) });
  }

  getFuelType$(fuelTypeId: string): Observable<FuelType> {
    return this._http.get<FuelType>(`${VECTORE_API_BASE_URL}/${this._tenantId}/fuel-types/${fuelTypeId}`);
  }

  listPurchaseTypes$(tenantId: string, params?: PurchaseTypeFilter): Observable<PurchaseTypeResponse> {
    return this._http.get<PurchaseTypeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/purchase-types`, { params: this._commonService.buildParams(params) });
  }

  getPurchaseType$(purchaseTypeId: string): Observable<PurchaseType> {
    return this._http.get<PurchaseType>(`${VECTORE_API_BASE_URL}/${this._tenantId}/purchase-types/${purchaseTypeId}`);
  }

  getVersionFields$(params?: VersionFieldFilter): Observable<ExtraFieldResponse> {
    return this._http.get<ExtraFieldResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/version-fields`, { params: this._commonService.buildParams(params) });
  }

  createMake$(make: MakeRequest): Observable<Make> {
    return this._http.post<Make>(`${VECTORE_API_BASE_URL}/${this._tenantId}/makes`, make)
      .pipe(
        map(make => {
          this._toasterService.success(this._translateService.instant('MAKES.MESSAGES.SUCCESS_CREATED'));
          return make;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listMakes$(tenantId: string, params?: MakesFilter): Observable<MakeResponse> {
    return this._http.get<MakeResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/makes`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listMakesNew$(params?: MakesFilter): Observable<MakeResponse> {
    return this._http.get<MakeResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/makes`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getMake$(makeId: string): Observable<Make> {
    return this._http.get<Make>(`${VECTORE_API_BASE_URL}/${this._tenantId}/makes/${makeId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateMake$(makeId: string, request: MakeRequest): Observable<Make> {
    return this._http.patch<Make>(`${VECTORE_API_BASE_URL}/${this._tenantId}/makes/${makeId}`, request)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteMake$(makeId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${this._tenantId}/makes/${makeId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createModel$(request: ModelRequest): Observable<Model> {
    return this._http.post<Model>(`${VECTORE_API_BASE_URL}/${this._tenantId}/models`, request)
      .pipe(
        map(model => {
          this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_CREATED'));
          return model;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listModels$(tenantId: string, params?: ModelsFilter): Observable<ModelResponse> {
    return this._http.get<ModelResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/models`, { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listModelsNew$(params?: ModelsFilter): Observable<ModelResponse> {
    return this._http.get<ModelResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/models`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getModel$(modelId: string): Observable<Model> {
    return this._http.get<Model>(`${VECTORE_API_BASE_URL}/${this._tenantId}/models/${modelId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateModel$(modelId: string, request: ModelRequest): Observable<Model> {
    return this._http.patch<Model>(`${VECTORE_API_BASE_URL}/${this._tenantId}/models/${modelId}`, request)
      .pipe(
        map(response => {
          this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_UPDATED'));
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteModel$(modelId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${this._tenantId}/models/${modelId}`, { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('MODELS.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('MODELS.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createVersion$(request: CreateVersionRequest): Observable<VersionModel> {
    return this._http.post<VersionModel>(`${VECTORE_API_BASE_URL}/${this._tenantId}/versions`, request)
      .pipe(
        map(version => {
          this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_CREATED'));
          return version;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listVersions$(tenantId: string, params?: VersionsFilter): Observable<VersionResponse> {
    return this._http.get<VersionResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/versions`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listVersionsNew$(params?: VersionsFilter): Observable<VersionResponse> {
    return this._http.get<VersionResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/versions`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getVersion$(versionId: string, params?: VersionFilter): Observable<VersionModel> {
    return this._http.get<VersionModel>(`${VECTORE_API_BASE_URL}/${this._tenantId}/versions/${versionId}`, { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateVersion$(versionId: string, request: PatchVersionRequest): Observable<VersionModel> {
    return this._http.patch<VersionModel>(`${VECTORE_API_BASE_URL}/${this._tenantId}/versions/${versionId}`, request)
      .pipe(
        map(version => {
          this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_UPDATED'));
          return version;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteVersion$(versionId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${this._tenantId}/versions/${versionId}`,
      { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toasterService.success(this._translateService.instant('VERSIONS.MESSAGES.SUCCESS_DELETED'));
          } else {
            this._toasterService.error(this._translateService.instant('VERSIONS.MESSAGES.ERROR_DELETED'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        }),
      );
  }

}
