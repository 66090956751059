import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AssetsService } from 'src/app/shared/api-services/assets.service';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Permission } from 'src/app/shared/models/permission/permission';
import { ListSwitch } from '../../lists/list-switches/list-switches.component';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { TagFilter } from 'src/app/shared/models/tag/tag-filter';
import { UserGroupsService } from 'src/app/shared/api-services/user-groups.service';
import * as Permissions from 'src/app/shared/constant/permissions';
import { TaskTypeFilter } from 'src/app/shared/models/task/task-filter';

@Injectable({
  providedIn: 'root'
})
export class PermissionModalUtilsService {
  tenantId: string;

  constructor(
    private _tenantService: TenantService,
    private _assetsService: AssetsService,
    private _tasksService: TasksService,
    private _bookingService: BookingService,
    private _makesService: MakesService,
    private _headquartersService: HeadquartersService,
    private _tagsService: TagsService,
    private _userGroupsService: UserGroupsService,
  ) {
    this.tenantId = this._tenantService.getTenantId();
  }

  createExpressionWithEq(field: string, ids: string[]): string {
    if (!ids || ids.length === 0) {
      return '';
    }
    let expr = `eq([${field}];"${ids[0]}"`;
    for (let i = 1; i < ids.length; i++) {
      expr += `;"${ids[i]}"`;
    }
    expr += ')';
    return expr;
  }

  createExpressionWithContains(field: string, ids: string[]): string {
    if (!ids || ids.length === 0) {
      return '';
    }

    if (ids.length === 1) {
      return `contains([${field}];"${ids[0]}")`;
    }

    let expr = `or(`;
    for (let i = 0; i < ids.length; i++) {
      expr += `contains([${field}];"${ids[i]}")`;
      if (i < ids.length - 1) {
        expr += `;`;
      }
    }
    expr += `)`;
    return expr;
  }

  private readIdsFromExpression(expr: string, field: string): string[] {
    if (!expr) {
      return [];
    }

    const prefix = `eq([${field}];`;
    const startIndex = expr.indexOf(prefix);
    if (startIndex === -1) {
      return [];
    }

    const endIndex = expr.indexOf(')', startIndex);
    if (endIndex === -1) {
      return [];
    }

    const idsStr = expr.substring(startIndex + prefix.length, endIndex);
    return idsStr.split(';')
      .filter(id => id)
      .map(id => id.replace(/"/g, ''));
  }

  private readContainsFromExpression(expr: string, field: string): string[] {
    if (!expr) {
      return [];
    }

    const regex = new RegExp(`contains\\(\\[${field}\\];"([^"]+)"\\)`, 'g');
    const ids: string[] = [];
    let match;
    while ((match = regex.exec(expr)) !== null) {
      ids.push(match[1]);
    }

    return ids;
  }

  getCustomExpressionFromEq(expr: string, field: string): string | null {
    if (!expr) {
      return null;
    }

    // Utilizza una regex globale per trovare tutte le occorrenze di eq() per il campo specificato
    const regex = new RegExp(`eq\\(\\[${field}\\];([^\\)]+)\\)`, 'g');
    let match;
    const results: string[] = [];

    while ((match = regex.exec(expr)) !== null) {
      // Estrae tutti gli ID separati da ;
      const idsStr = match[1];
      const ids = idsStr.split(';').map(id => id.replace(/"/g, '').trim()).filter(id => id);
      if (ids.length > 0) {
        results.push(...ids);
      }
    }

    if (results.length === 0) {
      return null;
    }

    // Rimuove duplicati
    const uniqueIds = Array.from(new Set(results));

    // Ricostruisce l'espressione eq() con tutti gli ID estratti
    return `eq([${field}];"${uniqueIds.join('";"')}")`;
  }

  getCustomExpressionFromContains(expr: string, field: string): string | null {
    if (!expr) {
      return null;
    }

    // Trova tutte le condizioni contains per il campo specificato
    const regex = new RegExp(`contains\\(\\[${field}\\];"([^"]+)"\\)`, 'g');
    const matches: string[] = [];
    let match;

    while ((match = regex.exec(expr)) !== null) {
      matches.push(`contains([${field}];"${match[1]}")`);
    }

    if (matches.length === 1) {
      return matches[0];
    } else if (matches.length > 1) {
      return `or(${matches.join(';')})`;
    }

    return null;
  }

  getAssetTypes$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._assetsService.listAssetTypes$(this.tenantId).pipe(
      map((response) => {
        let assetTypeIds = this.readIdsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: assetTypeIds.includes(item.id),
          }
        });
      })
    );
  }

  getTaskTypes$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    let taskTypeFilter: TaskTypeFilter = {};
    if (permission?.name === Permissions.CAN_READ_COSTS || permission?.name === Permissions.CAN_MANAGE_COSTS) {
      taskTypeFilter.hasTCO = true;
    }
    if (permission?.name === Permissions.CAN_READ_TASKS || permission?.name === Permissions.CAN_MANAGE_TASKS) {
      taskTypeFilter.hasTCO = false;
    }
    return this._tasksService.listTaskTypes$(this.tenantId, taskTypeFilter).pipe(
      map((response) => {
        let taskTypeIds = this.readIdsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: taskTypeIds.includes(item.id),
          }
        });
      })
    );
  }

  getBookingTypes$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._bookingService.listBookingTypes$(this.tenantId).pipe(
      map((response) => {
        let bookingTypeIds = this.readIdsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: bookingTypeIds.includes(item.id),
          }
        });
      })
    );
  }

  getVehicleTypes$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._makesService.listVehicleTypes$().pipe(
      map((response) => {
        let vehicleTypeIds = this.readIdsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: vehicleTypeIds.includes(item.id),
          }
        });
      })
    );
  }

  getHeadquarters$(permission: Permission, meHeadquarters: boolean = false, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._headquartersService.listHeadquarters$().pipe(
      map((response) => {
        let headquartersIds: string[] = this.readIdsFromExpression(permission?.expr, field);

        let switches: ListSwitch[] = [];
        if (meHeadquarters) {
          let switchItem: ListSwitch = {
            id: '#MEHEADQUARTERID#',
            text: ('USER_GROUPS.PERMISSIONS.MODAL.ME_HEADQUARTER'),
            checked: headquartersIds.includes('#MEHEADQUARTERID#'),
          }
          switches.push(switchItem);
        }

        switches = switches.concat(response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: headquartersIds.includes(item.id),
          }
        }));

        return switches;
      })
    );
  }

  getLocations$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._headquartersService.listLocations$().pipe(
      map((response) => {
        let locationsIds: string[] = this.readIdsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: locationsIds.includes(item.id),
          }
        });
      })
    );
  }

  getTags$(permission: Permission, field: string = 'Id'): Observable<ListSwitch[]> {
    return this._tagsService.listTags$({ includeDeleted: true } as TagFilter).pipe(
      map((response) => {
        let tagIds: string[] = this.readContainsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: tagIds.includes(item.id),
          }
        });
      })
    );
  }

  getUserGroups$(permission: Permission, field: string = 'GroupIds'): Observable<ListSwitch[]> {
    return this._userGroupsService.listUserGroups$(this.tenantId, {}).pipe(
      map((response) => {
        let userGroupIds: string[] = this.readContainsFromExpression(permission?.expr, field);
        return response.items.map(item => {
          return {
            id: item.id,
            text: item.name,
            checked: userGroupIds.includes(item.id),
          }
        });
      })
    );
  }
}
