import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { debounceTime, distinctUntilChanged, map, Observable, Observer, Subject, switchMap, takeUntil } from 'rxjs';
import { Cabinet, CabinetKey } from 'src/app/shared/models/cabinet/cabinet';
import { CabinetService } from 'src/app/shared/api-services/cabinet.service';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CabinetKeyRequest } from 'src/app/shared/models/cabinet/cabinet-request';

import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehiclesFilter } from 'src/app/shared/models/vehicle/vehicle-filter';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'mapping-cabinet-key-modal',
    imports: [
        CommonModule,
        TypeaheadModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    templateUrl: './mapping-cabinet-key-modal.component.html',
    styleUrl: './mapping-cabinet-key-modal.component.scss'

})
export class MappingCabinetKeyModalComponent implements OnInit, OnDestroy {

  tenantId: string = this._tenantService.getTenantId();
  @Input() cabinetKey: CabinetKey;
  @Input() cabinet: Cabinet;

  vehicleDisplayName$: Observable<string[]>;
  cabinetKeyRequest: CabinetKeyRequest;

  form: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _cabinetService: CabinetService,
    private _vehiclesService: VehiclesService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      vehicleDisplayName: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.autoComplete();
    this.cabinetKeyRequest = {
      cabinetTypeId: this.cabinetKey.cabinetTypeId,
      driverKeyId: this.cabinetKey.driverKeyId,
      vehicleId: this.cabinetKey.vehicleId,
    };
  }

  onSelect(event$: TypeaheadMatch) {
    let vehicleFilter: VehiclesFilter = {
      displayName: event$.value,
      limit: 1,
    };
    this._vehiclesService.listVehicles$(this.tenantId, vehicleFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((vehicleResponse) => {
        this.cabinetKeyRequest.vehicleId = vehicleResponse.items[0].id;
      });
  }

  update() {
    this._cabinetService.patchCabinetKey$(this.tenantId, this.cabinetKey.id, this.cabinetKeyRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this._communicationService.updateSignal.set(false);
          this._communicationService.updateSignal.set(true);
          this.bsModalRef.hide();
        }
      });
  }

  private autoComplete() {
    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { displayNameContains: token, limit: 5 })),
      map((vehicleResponse) => {
        let displayNames: string[] = [];
        vehicleResponse.items.forEach((vehicle) => {
          displayNames.push(vehicle.displayName);
        });
        return displayNames;
      }),
      takeUntil(this.destroy$),
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}