import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'task-description-edit',
    imports: [CommonModule, TranslateModule, ReactiveFormsModule],
    templateUrl: './task-description-edit.component.html',
    styleUrls: ['./task-description-edit.component.scss'],
})

export class TaskDescriptionEditComponent {
  isEditMode: boolean = false;
  @Input() description: string;

  @Output() descriptionChange = new EventEmitter<string | null>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      description: new FormControl(),
    });
  }

  ngOnInit() {
    this.form.get('description')!.setValue(this.description);
  }

  editMode() {
    this.isEditMode = true;
  }

  cancel() {
    this.isEditMode = false;
  }

  onSubmit() {
    if (this.form.value.description.trim()) {
      this.descriptionChange.emit(this.form.value.description);
    }
    else {
      this.descriptionChange.emit(null);
    }

    this.description = this.form.value.description;
    this.isEditMode = false;
  }

}
