import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'badge-booking',
    imports: [
        CommonModule,
        TranslateModule,
    ],
    templateUrl: './badge-booking.component.html',
    styleUrls: ['./badge-booking.component.scss']
})
export class BadgeBookingComponent {
  @Input() number : number;
  @Input() text : string;
  output : string;

  ngOnInit(): void {
    if (this.number === 1) {
      this.output = 'Best';      
    }
    this.number = Number(this.number.toFixed(2));
  }
}
