import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Component({
    selector: 'app-no-permissions-modal',
    imports: [
        CommonModule,
        TranslateModule
    ],
    templateUrl: './no-permissions-modal.component.html',
    styleUrl: './no-permissions-modal.component.scss'
})
export class NoPermissionsModalComponent {

  constructor(
    public bsModalRef: BsModalRef,
    private _authenticationService: AuthenticationService
  ) { }

  logout(): void {
    this._authenticationService.logout();
    this.bsModalRef.hide();
  }
}
