<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isCreate">{{'LOCATION.NEW' | translate}}</h4>
        <h4 class="modal-title fs-5 pull-left" *ngIf="modalStatus.isPatch">{{'LOCATION.EDIT' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body">
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <dropdown-primary-headquarter 
                    [headquarterId]="location?.headquarterId ?? null" 
                    [isDisabled]="modalStatus.isPatch"
                    (onSelectedEvent)="onHeadquarterSelected($event)">
                </dropdown-primary-headquarter>
            </div>

            <div class="form-group">
                <label for="name" class="form-label">{{'NAME' | translate}}</label>
                <input type="text" class="form-control" formControlName="name">
            </div>

            <div class="d-flex flex-column w-100">
                <label for="address" class="form-label">{{'ADDRESS' | translate}}</label>
                <p-autoComplete
                    class="flex-grow-1"
                    formControlName="address"
                    [showClear]="true"
                    [forceSelection]="true"
                    [suggestions]="listAddress"
                    (completeMethod)="filterAdress($event)"
                    (onClear)="onClearAddress()"
                    (onSelect)="onSelectAddress($event)"
                    optionLabel="name" />
            </div>
            
            <div style="height: 50vh; border-radius: 10px;" 
                leaflet
                [leafletOptions]="options"
                [leafletMarkerCluster]="markers"
                (leafletMapReady)="onMapReady($event)">
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>

        <button type="button" class="btn-gs-primary" *ngIf="modalStatus.isCreate" (click)="createLocation()">{{'ACTIONS.NEW' | translate}}</button>
        <button type="button" class="btn-gs-primary" *ngIf="modalStatus.isPatch" (click)="updateLocation()">{{'ACTIONS.EDIT' | translate}}</button>
    </footer>
</div>