import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Booking, BookingType } from 'src/app/shared/models/booking/booking';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { map, distinctUntilChanged, switchMap, debounceTime, Observable, Observer, takeUntil, Subject, of, tap } from 'rxjs';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { BookingRequest } from 'src/app/shared/models/booking/booking-request';
import { TypeExtraField } from 'src/app/shared/models/common';
import { ExtraFieldsUtilsService } from 'src/app/shared/utilities/extra-fields-utils.servic';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { BookingModalService } from '../booking-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UsersFilter } from 'src/app/shared/models/user/user-filter';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { MeService } from 'src/app/shared/api-services/me.service';
import { animate, style, trigger } from '@angular/animations';
import { state } from '@angular/animations';
import { transition } from '@angular/animations';

@Component({
    selector: 'booking-modal-step1',
    templateUrl: './booking-modal-step1.component.html',
    styleUrl: './booking-modal-step1.component.scss',
    animations: [
      trigger('gotop', [
        state('top', style({ transform: 'translateY(0)' })),
        state('bottom', style({ transform: 'translateY(100%)' })),
        transition('top <=> bottom', animate('00ms ease-in-out'))
      ])
    ],
    standalone: false
})
export class BookingModalStep1Component {

  booking: Booking;
  get canBookForOthers() {
    return this._bookingModalNewService.canDoBookingForOtherUsers;
  }
  bookingType: BookingType;
  endDateMaxDate: Date | null = null;
  startDateMaxDate: Date | null = null;

  form: FormGroup;
  fields: FormlyFieldConfig[];
  typeExtraFields: TypeExtraField[];

  driverNames$: Observable<string[]>;

  animationState = 'top';

  private _destroy = new Subject<void>();

  constructor(
    private _usersService: UsersService,
    private _meService: MeService,
    private _bookingService: BookingService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _dateUtilsService: DateUtilsService,
    private _bookingModalNewService: BookingModalService,
    private _translateService: TranslateService,
    private _toasterService: ToastrService,
    private _cdr: ChangeDetectorRef
  ) { 
    this.form = new FormGroup({
      titleBooking: new FormControl(null),
      headquarterId: new FormControl(null),
      locationId: new FormControl(null),
      bookingTypeId: new FormControl(null),
      displayNameDriver: new FormControl(null),
      userId: new FormControl(null),
      startDateBooking: new FormControl(null),
      startTimeBooking: new FormControl(null),
      endDateBooking: new FormControl(null),
      endTimeBooking: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.setForm();
    this.autoCompletes();
    this._bookingModalNewService.setStep1Component(this);
    this.setupDateValidation();
  }

  private setForm() {
    this.booking = this._bookingModalNewService.booking;
    let formValue = this._bookingModalNewService.getInformationStep(1);
    if (formValue) {
      this.form.patchValue(formValue);
      this._cdr.detectChanges();
    }
    else if (this.booking?.id) {
      let startDate = new Date(this.booking.startDate);
      let startTime = this._dateUtilsService.parseDateToTimeString(new Date(this.booking.startDate));
      let endDate = new Date(this.booking.endDate);
      let endTime = this._dateUtilsService.parseDateToTimeString(new Date(this.booking.endDate));

      this.form.patchValue({
        headquarterId: this.booking.headquarterId,
        locationId: this.booking.locationId,
        bookingTypeId: this.booking.bookingTypeId,
        displayNameDriver: this.booking.user?.displayName,
        userId: this.booking.userId,
        startDateBooking: startDate,
        startTimeBooking: startTime,
        endDateBooking: endDate,
        endTimeBooking: endTime
      });
    }
    else {
      this.getMe();
      this.setStartDateNow();
    }
  }

  private getMe() {
    this._meService.getMe$()
    .pipe(takeUntil(this._destroy))
    .subscribe({
      next: (response) => {
        this.form.patchValue({
          userId: response.id,
          displayNameDriver: response.displayName
        });
      }
    });
  }

  private autoCompletes() {
    this.driverNames$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['displayNameDriver'].value);
    }).pipe(
      takeUntil(this._destroy),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._usersService.listUsers$({ displayNameContains: token, limit: 5 })),
      map((users) => {
        let userNames: string[] = [];
        users.items.forEach((user) => {
          userNames.push(user.displayName);
        });
        return userNames;
      }),
    );
  }

  private getExtraFields() {
    this._bookingService.getBookingFields$({ includeBookingTypeId: this.form.get('bookingTypeId')?.value })
    .pipe(takeUntil(this._destroy))
    .subscribe({
      next: (response) => {
        this.typeExtraFields?.forEach(field => {
          this.form.removeControl(field.name);
        });

        this.typeExtraFields = response.items.map(x => x as TypeExtraField);

        this.booking = this.booking ? this.booking : {};
        this.booking.extraFields = this.booking.extraFields ? this.booking.extraFields : {};

        this.booking.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.booking.extraFields, false);

        this.fields = this.typeExtraFields.map(field =>
        this._extraFieldsUtilsService.createFieldConfig(field, this.booking.extraFields));
      }
    });
  }

  private setStartDateNow() {
    this.updateTime();
    //setInterval(() => {
      //this.updateTime();
    //}, 60000); // Aggiorna l'ora ogni minuto
  }

  private updateTime() {
    let now = new Date();
    now.setMinutes(now.getMinutes() + 1);
    
    // Formatta l'ora in formato HH:MM
    let startTime = now.toTimeString().split(' ')[0].substring(0,5);
    
    this.form.patchValue({
      startDateBooking: now,
      startTimeBooking: startTime,
      endDateBooking: now,
    });

    this._cdr.detectChanges();
  }

  updateUserId(event$: TypeaheadMatch) {
    let filter: UsersFilter = {
      displayName: event$.value
    }
    if (event$.value) {
      this._usersService.listUsers$(filter).subscribe({
        next: (response) => {
          this.form.get('userId')?.setValue(response.items[0]?.id);
        }
      });
    }
  }

  updateBookingRequest() {
    let startDate: string | null = null;
    let endDate: string | null = null;

    if (this.form.get('startDateBooking')?.value && this.form.get('startTimeBooking')?.value) {
      startDate = this._dateUtilsService
        .combineDateAndTimeToIsoString(
          this.form.get('startDateBooking')?.value,
          this.form.get('startTimeBooking')?.value
        );
    }

    if (this.form.get('endDateBooking')?.value && this.form.get('endTimeBooking')?.value) {
      endDate = this._dateUtilsService
        .combineDateAndTimeToIsoString(
          this.form.get('endDateBooking')?.value,
          this.form.get('endTimeBooking')?.value
        );
    }

    let bookingRequest: BookingRequest = {
      title: this.form.get('titleBooking')?.value,
      locationId: this.form.get('locationId')?.value,
      bookingTypeId: this.form.get('bookingTypeId')?.value,
      userId: this.form.get('userId')?.value,
      startDate: startDate,
      endDate: endDate,
      vehicleId: this.booking?.vehicleId ?? null
    }

    bookingRequest = this._extraFieldsUtilsService
      .updateRequestFromForm(this.typeExtraFields, this.form.value, bookingRequest);
    if (bookingRequest.extraFields) {
      bookingRequest.extraFields = this._extraFieldsUtilsService
        .parseBool(this.typeExtraFields, bookingRequest.extraFields);
    }

    this._bookingModalNewService.setBookingRequest(bookingRequest);
  }

  checkFormValidity() : boolean {
    if (!this.form.get('displayNameDriver')?.value) {
      this.form.patchValue({
        userId: null
      });
    }

    if (this.bookingType?.titleRequired && !this.form.get('titleBooking')?.value) {
      this.form.get('titleBooking')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_TITLE_BOOKING'));
      return false;
    }
    if (!this.form.get('locationId')?.value) {
      this.form.get('locationId')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_LOCATION'));
      return false;
    }
    if (!this.form.get('bookingTypeId')?.value) {
      this.form.get('bookingTypeId')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_BOOKING_TYPE'));
      return false;
    }
    if (!this.form.get('userId')?.value) {
      this.form.get('displayNameDriver')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_DRIVER_REQUIRED'));
      return false;
    }
    if (!this.form.get('startDateBooking')?.value || !this.form.get('startTimeBooking')?.value) {
      this.form.get('startDateBooking')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_REQUIRED_START_DATE'));
      return false;
    }
    if (!this.form.get('endDateBooking')?.value || !this.form.get('endTimeBooking')?.value) {
      this.form.get('endDateBooking')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_REQUIRED_END_DATE'));
      return false;
    }

    let startDate: Date | null = null;
    let endDate: Date | null = null;

    if (this.form.get('startDateBooking')?.value && this.form.get('startTimeBooking')?.value) {
      startDate = new Date(this._dateUtilsService
        .combineDateAndTimeToIsoString(
          this.form.get('startDateBooking')?.value,
          this.form.get('startTimeBooking')?.value
        )
      );
    }

    if (this.form.get('endDateBooking')?.value && this.form.get('endTimeBooking')?.value) {
      endDate = new Date(this._dateUtilsService
        .combineDateAndTimeToIsoString(
          this.form.get('endDateBooking')?.value,
          this.form.get('endTimeBooking')?.value
        )
      );
    }

    if (startDate && endDate && startDate > endDate) {
      this.form.get('endDateBooking')?.setErrors({ invalid: true });
      this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_STARTDATE_BOOKING'));
      return false;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.focusOnInvalidField();
      return false;
    }

    this._bookingModalNewService.saveInformationStep(1, this.form.value);
    return true;
  }

  focusOnInvalidField() {
    const invalidField = this.fields.find(field => field.formControl && field.formControl.invalid && field.formControl.touched);
    if (invalidField) {
      const element = document.querySelector(`[id="${invalidField.id}"]`) as HTMLElement;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  onSelectedEvent(event: DropdownOption, type: 'headquarterId' | 'locationId' | 'bookingTypeId') {
    this.form.get(type)?.setValue(event.value);

    if (type === 'bookingTypeId') {
      this.bookingType = event.extraField as BookingType;
      this._bookingModalNewService.setBookingType(this.bookingType);
      this._bookingModalNewService.hideStepMap();
      this.getExtraFields();
      
      // Calcolo della data massima per startDate e endDate se maxEndDateMinutes è definito
      if (this.bookingType?.maxEndDateMinutes) {
        this.updateStartDateMaxDate();
        this.updateEndDateMaxDate();
        
        // Ascolto i cambiamenti per aggiornare le date massime
        this.form.get('startDateBooking')?.valueChanges
          .pipe(takeUntil(this._destroy))
          .subscribe(() => {
            this.updateStartDateMaxDate();
            this.updateEndDateMaxDate();
          });
      } else {
        this.startDateMaxDate = null;
        this.endDateMaxDate = null;
      }
    }
  }

  private updateStartDateMaxDate() {
    if (!this.bookingType?.maxEndDateMinutes) {
      this.startDateMaxDate = null;
      return;
    }
    
    const now = new Date();
    this.startDateMaxDate = new Date(now);
    this.startDateMaxDate.setMinutes(now.getMinutes() + this.bookingType.maxEndDateMinutes);
  }

  private updateEndDateMaxDate() {
    if (!this.form.get('startDateBooking')?.value || !this.bookingType?.maxEndDateMinutes) {
      this.endDateMaxDate = null;
      return;
    }
    
    const startDate = new Date(this.form.get('startDateBooking')?.value);
    this.endDateMaxDate = new Date(startDate);
    this.endDateMaxDate.setMinutes(startDate.getMinutes() + this.bookingType.maxEndDateMinutes);
  }

  private setupDateValidation() {
    // Verifica la data di inizio quando cambia
    this.form.get('startDateBooking')?.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (value && this.startDateMaxDate) {
          const startDate = new Date(value);
          if (startDate > this.startDateMaxDate) {
            this.form.get('startDateBooking')?.setErrors({ invalid: true });
            let date = this.startDateMaxDate.toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
            this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_MAX_START_DATE', { maxDate: date }));
          }
          else {
            this.form.get('startDateBooking')?.setErrors(null);
          }
        }
      });

    // Verifica la data di fine quando cambia
    this.form.get('endDateBooking')?.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((value) => {
        if (value && this.endDateMaxDate) {
          const endDate = new Date(value);
          if (endDate > this.endDateMaxDate) {
            this.form.get('endDateBooking')?.setErrors({ invalid: true });
            let date = this.endDateMaxDate.toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
            this._toasterService.error(this._translateService.instant('BOOKINGS.MESSAGES.ERROR_MAX_END_DATE', { maxDate: date }));
          }
          else {
            this.form.get('endDateBooking')?.setErrors(null);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.animationState = 'top';
    this._destroy.next();
    this._destroy.complete();
  }
}
