import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Headquarter } from 'src/app/shared/models/headquarter/headquarter';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'headquarter-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    templateUrl: './headquarter-modal.component.html'
})
export class HeadquarterModalComponent {

  headquarter: Headquarter;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _headquarterService: HeadquartersService,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.headquarter) {
      this.getHeadquarter();
    }
  }

  getHeadquarter() {
    this._headquarterService.getHeadquarter$(this.headquarter.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.headquarter = response;
        this.modalStatus.isPatch = true;
        this.modalStatus.isCreate = false;
        this.form.patchValue({
          name: response.name,
        });
      });
  }

  createHeadquarter() {
    this.headquarter = {
      name: this.form.value.name,
    }
    this._headquarterService.createHeadquarter$(this.headquarter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this.bsModalRef.hide();
          this.form.reset();
          this._communicationService.sendEvent();
        }
      });
  }

  updateHeadquarter() {
    this.headquarter.name = this.form.value.name;
    this._headquarterService.updateHeadquarter$(this.headquarter.id, this.headquarter)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this.bsModalRef.hide();
          this.form.reset();
          this._communicationService.sendEvent();
        }
      });
  }

  deleteHeadquarter() {
    this.confirmModal = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'MESSAGES.CONFIRM_DELETE'
        }
      }
    });

    this.confirmModal.content.onConfirm.pipe(
      takeUntil(this.destroy$),
      switchMap(() => this._headquarterService.deleteHeadquarter$(this.headquarter.id)),
    ).subscribe({
      next: (response: HttpResponse<Object>) => {
        if (response.status === 204) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}