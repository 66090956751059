import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UserNotificationSettingsService } from 'src/app/shared/api-services/user-notification-settings.service';
import { Subject, takeUntil } from 'rxjs';
import { MeNotificationSettingsRequest, UserNotificationSettingsListRequest, UserNotificationSettingsRequest } from 'src/app/shared/models/user-notification-setting/user-notification-settings-request';
import { UserNotificationSettings } from 'src/app/shared/models/user-notification-setting/user-notification-setting';

@Component({
    selector: 'user-notification-settings-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    templateUrl: './user-notification-settings-modal.component.html'
})
export class UserNotificationSettingsModalComponent implements OnInit, OnDestroy {
  @Input() userId: string;
  private _notificationId: string;

  form: FormGroup = new FormGroup({
    isNew: new FormControl(false),
    disableUserDisabled: new FormControl(false),
    disableBookingUpdates: new FormControl(false),
    disableBookingApprover: new FormControl(false),
    disableBookingOkDelayed: new FormControl(false),
    disableTaskAssigned: new FormControl(false),
    disableTaskUpdated: new FormControl(false),
    disableTaskExpired: new FormControl(false),
    disableTaskExpiration: new FormControl(false),
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private _userNotificationSettingsService: UserNotificationSettingsService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    if (!this.userId) {
      this._userNotificationSettingsService.getMeNotificationSettings$()
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (userNotificationSettings) => {
            this._notificationId = userNotificationSettings.id;
            this.setFormValue(userNotificationSettings);
          },
          error: error => {
            if (error.status === 404) {
              this.form.patchValue({
                isNew: true,
              });
            }
          }
        });
    } else {
      const params: UserNotificationSettingsListRequest = {
        userId: this.userId,
      };
      this._userNotificationSettingsService.listUserNotificationSettings$(params)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (userNotificationSettings) => {
            if (userNotificationSettings.count > 0) {
              this._notificationId = userNotificationSettings.items[0].id;
              this.setFormValue(userNotificationSettings.items[0]);
            } else {
              this.form.patchValue({
                isNew: true,
              });
            }
          }
        });
    }
  }

  selectAll() {
    this.form.patchValue({
      disableUserDisabled: true,
      disableBookingUpdates: true,
      disableBookingApprover: true,
      disableBookingOkDelayed: true,
      disableTaskAssigned: true,
      disableTaskUpdated: true,
      disableTaskExpired: true,
      disableTaskExpiration: true,
    });
  }

  selectNone() {
    this.form.patchValue({
      disableUserDisabled: false,
      disableBookingUpdates: false,
      disableBookingApprover: false,
      disableBookingOkDelayed: false,
      disableTaskAssigned: false,
      disableTaskUpdated: false,
      disableTaskExpired: false,
      disableTaskExpiration: false,
    });
  }

  save() {
    if (this.userId) {
      const request: UserNotificationSettingsRequest = {
        userId: this.userId,
        disableUserDisabled: this.form.value.disableUserDisabled,
        disableBookingUpdates: this.form.value.disableBookingUpdates,
        disableBookingApprover: this.form.value.disableBookingApprover,
        disableBookingOkDelayed: this.form.value.disableBookingOkDelayed,
        disableTaskAssigned: this.form.value.disableTaskAssigned,
        disableTaskUpdated: this.form.value.disableTaskUpdated,
        disableTaskExpired: this.form.value.disableTaskExpired,
        disableTaskExpiration: this.form.value.disableTaskExpiration,
      };

      if (this.form.value.isNew) {
        this._userNotificationSettingsService.createUserNotificationSettings$(request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            complete: () => {
              this.bsModalRef.hide();
            }
          });
      } else {
        this._userNotificationSettingsService.updateUserNotificationSettings$(this._notificationId, request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            complete: () => {
              this.bsModalRef.hide();
            }
          });
      }
      return;
    }

    if (!this.userId) {
      const request: MeNotificationSettingsRequest = {
        disableUserDisabled: this.form.value.disableUserDisabled,
        disableBookingUpdates: this.form.value.disableBookingUpdates,
        disableBookingApprover: this.form.value.disableBookingApprover,
        disableBookingOkDelayed: this.form.value.disableBookingOkDelayed,
        disableTaskAssigned: this.form.value.disableTaskAssigned,
        disableTaskUpdated: this.form.value.disableTaskUpdated,
        disableTaskExpired: this.form.value.disableTaskExpired,
        disableTaskExpiration: this.form.value.disableTaskExpiration,
      };
      if (this.form.value.isNew) {
        this._userNotificationSettingsService.createMeNotificationSettings$(request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            complete: () => {
              this.bsModalRef.hide();
            }
          });
      } else {
        this._userNotificationSettingsService.updateMeNotificationSettings$(this._notificationId, request)
          .pipe(takeUntil(this._destroy$))
          .subscribe({
            complete: () => {
              this.bsModalRef.hide();
            }
          });
      }
    }
  }

  private setFormValue(userNotificationSettings: UserNotificationSettings) {
    this.form.patchValue({
      disableUserDisabled: userNotificationSettings.disableUserDisabled,
      disableBookingUpdates: userNotificationSettings.disableBookingUpdates,
      disableBookingApprover: userNotificationSettings.disableBookingApprover,
      disableBookingOkDelayed: userNotificationSettings.disableBookingOkDelayed,
      disableTaskAssigned: userNotificationSettings.disableTaskAssigned,
      disableTaskUpdated: userNotificationSettings.disableTaskUpdated,
      disableTaskExpired: userNotificationSettings.disableTaskExpired,
      disableTaskExpiration: userNotificationSettings.disableTaskExpiration,
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}