import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Task, TaskFiles, TaskComment, TaskCost, TaskInvoice, TaskType } from '../models/task/task';
import { TaskResponse, TaskFilesResponse, TaskCommentResponse, TaskDataResponse, TaskFieldsResponse, TaskTypeResponse } from '../models/task/task-response';
import { CreateTaskRequest, PatchTaskRequest, TaskCommentRequest, TaskFileRequest, TaskInvoiceRequest, UpdateTaskCostRequest } from '../models/task/task-request';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, Subject, map, shareReplay, tap } from 'rxjs';
import { MappingModel } from '../models/user/users';
import { DefaultQueryParams } from '../models/common';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TaskDataFilter, TaskFieldsFilter, TasksFilter, TaskInvoicesFilter, TaskTypeFilter, TaskFilter, TaskFilesFilter, TaskCommentFilter } from '../models/task/task-filter';
import { TaskInvoiceResponse } from '../models/task/task-response';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  private task$ = new Subject<Task>();
  private _tenantId: string;

  constructor(
    private _http: HttpClient,
    private _tenantService: TenantService,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { 
    this._tenantId = this._tenantService.getTenantId();
  } 

  sendEvent(data: Task) {
    this.task$.next(data);
  }

  getEvent() {
    return this.task$.asObservable();
  }

  getTaskType$(taskTypeId: string): Observable<TaskType> {
    return this._http.get<TaskType>(`${VECTORE_API_BASE_URL}/${this._tenantId}/task-types/${taskTypeId}`);
  }

  listTaskTypes$(tenant: string, params?: TaskTypeFilter): Observable<TaskTypeResponse> {
    return this._http.get<TaskTypeResponse>(`${VECTORE_API_BASE_URL}/${tenant}/task-types`,
      { params: this._commonService.buildParams(params) });
  }

  listTaskTypesNew$(params?: TaskTypeFilter): Observable<TaskTypeResponse> {
    return this._http.get<TaskTypeResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/task-types`,
      { params: this._commonService.buildParams(params) });
  }

  listTaskFields$(params?: TaskFieldsFilter): Observable<TaskFieldsResponse> {
    return this._http.get<TaskFieldsResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/task-fields`,
      { params: this._commonService.buildParams(params) });
  }

  createTask$(tenant: string, task: CreateTaskRequest, showConfirmation: boolean = true): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks`, task,)
      .pipe(
        tap({
          next: response => {
            if (response.id && showConfirmation) {
              this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_CREATE_TASK'))
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  };

  listTasks$(tenant: string, query?: TasksFilter): Observable<TaskResponse> {
    return this._http.get<TaskResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks`, { params: this._commonService.buildParams(query) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getTask$(tenant: string, taskId: string, query?: TaskFilter): Observable<Task> {
    return this._http.get<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`,
      { params: this._commonService.buildParams(query) })
      .pipe(
        shareReplay(),
        tap({
          error: error => this._commonService.handleErrorForGet(error)
        }),
      );
  }

  patchTask$(tenant: string, taskId: string, taskRequest: PatchTaskRequest): Observable<Task> {
    return this._http.patch<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`,
      taskRequest,
    ).pipe(
      tap({
        next: response => {
          if (response?.id) {
            this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_EDIT_TASK'));
          }
        },
        error: error => this._commonService.handleError(error)
      })
    );
  }

  deleteTask$(tenant: string, taskId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}`,
      { observe: 'response' })
      .pipe(
        tap({
          next: response => {
            if (response.status === 204) {
              this._toastrService.info(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DELETE_TASK'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  openTask$(tenant: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/open`, {})
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_REOPEN_TASK'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  closeTask$(tenant: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/close`, {})
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DONE_TASK'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateTaskCost$(tenant: string, taskId: string, request: UpdateTaskCostRequest): Observable<TaskCost> {
    return this._http.post<TaskCost>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/update-task-cost`, request)
      .pipe(
        tap({
          next: response => {
            if (response) {
              this._toastrService.success(this._translateService.instant('COSTS.MESSAGES.UPDATE_SUCCESS'));;
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  unDeleteTask$(tenantId: string, taskId: string): Observable<Task> {
    return this._http.post<Task>(`${VECTORE_API_BASE_URL}/${tenantId}/tasks/${taskId}/undelete`, {})
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.info(this._translateService.instant('TASKS.MESSAGE.SUCCESS_UNDELETE_TASK'))
            }
          },
          error: error => this._commonService.handleError(error)
        })
      )
  }

  exportTasks$(exportData: MappingModel, query?: TasksFilter, nameFile?: string): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this._tenantId}/export/tasks`, exportData, {
      observe: 'response',
      responseType: 'blob',
      params: this._commonService.buildParams(query)
    })
      .pipe(
        tap({
          next: response => {
            const now = new Date();
            const filename = `export_${nameFile}_${now.toLocaleDateString('it-IT')}.xlsx`;
            this._commonService.downloadFile(response, filename);
          },
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  createCommentTask$(tenant: string, taskId: string, comment: TaskCommentRequest): Observable<HttpResponse<TaskComment>> {
    return this._http.post<TaskComment>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments`,
      comment,
      { observe: 'response', })
      .pipe(
        map(response => {
          if (response.status === 201) {
            this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_ADD_COMMENT'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listCommentsTask$(tenant: string, taskId: string, query?: TaskCommentFilter): Observable<TaskCommentResponse> {
    return this._http.get<TaskCommentResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments`,
      { params: this._commonService.buildParams(query) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getCommentTaskId$(tenant: string, taskId: string, commentId: string): Observable<TaskCommentResponse> {
    return this._http.get<TaskCommentResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments/${commentId}`,);
  }

  deleteCommentTask(tenant: string, taskId: string, commentId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/comments/${commentId}`, { observe: 'response' })
      .pipe(
        map(response => {
          if (response.status === 204) {
            this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_DELETE_COMMENT'));
          }
          return response;
        })
      );
  }

  watchTask$(taskId: string): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this._tenantId}/tasks/${taskId}/watch`, {}, { observe: 'response' })
  }

  linkTaskFile$(tenant: string, taskId: string, taskFileRequest: TaskFileRequest): Observable<HttpResponse<TaskFiles>> {
    return this._http.post<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files`, taskFileRequest,
      { observe: 'response', })
      .pipe(
        shareReplay(1),
        map(response => {
          if (response.status === 200) {
            this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_LINK_FILE_TASK'));
          }
          return response;
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listTaskFiles$(tenant: string, taskId: string, query?: TaskFilesFilter): Observable<TaskFilesResponse> {
    return this._http.get<TaskFilesResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files`,
      { params: this._commonService.buildParams(query) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getTaskFile$(tenant: string, taskId: string, fileId: string): Observable<TaskFiles> {
    return this._http.get<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`,);
  }

  updateTaskFile$(tenant: string, taskId: string, fileId: string, nameFile: string): Observable<TaskFiles> {
    return this._http.patch<TaskFiles>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`, nameFile)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteTaskFile$(tenant: string, taskId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}`, { observe: 'response' })
      .pipe(
        tap({
          next: response => {
            if (response.status === 204) {
              this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_REMOVE_FILE_TASK'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  downloadTaskFile$(tenant: string, taskId: string, fileId: string): Observable<HttpResponse<any>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenant}/tasks/${taskId}/files/${fileId}/content`,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createTaskInvoice$(tenant: string, taskInvoice: TaskInvoiceRequest): Observable<TaskInvoice> {
    return this._http.post<TaskInvoice>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices`, taskInvoice)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_LINK_INVOICE_TASK'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listTaskInvoices$(tenant: string, query?: TaskInvoicesFilter): Observable<TaskInvoiceResponse> {
    return this._http.get<TaskInvoiceResponse>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices`, { params: this._commonService.buildParams(query) });
  }

  getTaskInvoice$(tenant: string, taskInvoiceId: string): Observable<TaskInvoice> {
    return this._http.get<TaskInvoice>(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices/${taskInvoiceId}`,);
  }

  deleteTaskInvoice$(tenant: string, taskInvoiceId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenant}/tasks/invoices/${taskInvoiceId}`, { observe: 'response' })
      .pipe(
        tap(response => {
          if (response.status === 204) {
            this._toastrService.success(this._translateService.instant('TASKS.MESSAGE.SUCCESS_UNLINK_INVOICE_TASK'));
          }
        })
      );
  }

  getTaskData$(tenant: string, params?: TaskDataFilter): Observable<TaskDataResponse> {
    return this._http.get<TaskDataResponse>(`${VECTORE_API_BASE_URL}/${tenant}/task-data`, { params: this._commonService.buildParams(params) });
  }

  getTaskDataNew$(params?: TaskDataFilter): Observable<TaskDataResponse> {
    return this._http.get<TaskDataResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/task-data`, { params: this._commonService.buildParams(params) });
  }

  mapTaskToTaskRequest(task: Task): CreateTaskRequest {
    return <CreateTaskRequest>{
      taskTypeId: task.taskTypeId ?? null,
      code: task.code ?? null,
      headquarterId: task.headquarterId ?? null,
      vehicleId: task.vehicleId ?? null,
      userId: task.userId ?? null,
      assetId: task.assetId ?? null,
      title: task.title ?? null,
      description: task.description ?? null,
      expirationMileage: task.expirationMileage ?? null,
      expirationDate: task.expirationDate ?? null,
      endMileage: task.endMileage ?? null,
      assignedUserId: task.assignedUserId ?? null,
      extraFields: task.extraFields ?? null,
      tagIds: task.tagIds ?? null,
      usageStartDate: task.usageStartDate ?? null,
      usageEndDate: task.usageEndDate ?? null,
      taskWatcherIds: task.taskWatcherIds ?? null,
    };
  }
}

