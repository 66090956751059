<div class="dropdown" *ngIf="!isMobile; else mobileSelect">
    <button type="button" 
        [class]="cssClass"
        [ngClass]="{'gap-2' : iconClass !== ''}" 
        [disabled]="isDisabled"
        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        
        <span [class]="cssTextClass">{{selectedOption?.text | translate}}</span>
        <i [class]="iconClass"></i>
    </button>
    <ul class="dropdown-menu max-content" [class.active-scrollbar]="activeScrollbar" [class]="dropdownCssClass" aria-labelledby="dropdownMenuButton1">
        <li *ngFor="let option of options;" role="menuitem" >
            <a class="dropdown-item" (click)="selectOption(option)">{{option.text | translate}}</a>
        </li>
    </ul>
</div>

<ng-template #mobileSelect>
    <select class="form-select" 
        *ngIf="options"
        [disabled]="isDisabled" 
        [(ngModel)]="selectedMobileOption" 
        (change)="onMobileSelectChange()">
      <option *ngFor="let option of options" [ngValue]="option.value">{{option.text | translate}}</option>
    </select>
</ng-template>