import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Tag } from 'src/app/shared/models/tag/tag';
import { TagRequest } from 'src/app/shared/models/tag/tag-request';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
@Component({
    selector: 'tag-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    templateUrl: './tag-modal.component.html'
})
export class TagModalComponent implements OnInit, OnDestroy {
  @Input() tag: Tag;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  private _destroy$ = new Subject<void>();

  constructor(
    private _tagsService: TagsService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.tag) {
      this.getHeadquarter();
    }
  }

  getHeadquarter() {
    this._tagsService.getTag$(this.tag.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.tag = response;
        this.modalStatus.isPatch = true;
        this.modalStatus.isCreate = false;
        this.form.patchValue({
          name: response.name,
        });
      });
  }

  createTag() {
    let tagRequest: TagRequest = {
      name: this.form.value.name,
    }
    this._tagsService.createTag$(tagRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        if (response.id) {
          this.bsModalRef.hide();
          this.form.reset();
          this._communicationService.sendEvent();
        }
      });
  }

  updateTag() {
    let tagRequest: TagRequest = {
      name: this.form.value.name
    }
    this._tagsService.updateTag$(this.tag.id, tagRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        if (response.id) {
          this.bsModalRef.hide();
          this.form.reset();
          this._communicationService.sendEvent();
        }
      });
  }

  deleteTag() {
    this._tagsService.deleteTag$(this.tag.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        if (response.status === 204) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
