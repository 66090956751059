import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Booking } from '../../models/booking/booking';
import { DatePipe } from '@angular/common';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'unvailable-vehicle-request-pdf',
  imports: [
    TranslateModule,
    DatePipe
  ],
  template: `
    <div class="d-flex justify-content-end p-4 pb-0">
      <button class="btn-gs-primary-icon" (click)="generatePDF()">
        <i class="bi bi-download"></i>
        {{'ACTIONS.DOWNLOAD_DOCUMENT' | translate}}
        @if (isLoading) {
          <div class="spinner-border spinner-border-sm text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        }
      </button>
    </div>
    <div class="container" #pdfContent>
      <div class="container-header">
        <div class="logo"></div>
        <h1>{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.TITLE' | translate}}</h1>
        <h5>{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.SUBTITLE' | translate}}</h5>
      </div>

      <div class="container-body">
        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.EMPLOYEE' | translate}}:</div>
          <div class="container-body-row-value">{{booking.user.displayName}}</div>
        </div>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.HEADQUARTERS' | translate}}:</div>
          <div class="container-body-row-value">{{booking.headquarter.name}}</div>
        </div>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.LOCATION' | translate}}:</div>
          <div class="container-body-row-value">{{booking.location.name}}</div>
        </div>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.ACTIVITY' | translate}}:</div>
          <div class="container-body-row-value">{{booking.title}}</div>
        </div>

        <p>{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.DESCRIPTION' | translate}}</p>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.FROM' | translate}}:</div>
          <div class="container-body-row-value">{{booking.startDate | date:'dd/MM/yyyy HH:mm'}}</div>
        </div>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.TO' | translate}}:</div>
          <div class="container-body-row-value">{{booking.endDate | date:'dd/MM/yyyy HH:mm'}}</div>
        </div>

        <div class="container-body-row">
          <div class="container-body-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.TRY_DATE' | translate}}:</div>
          <div class="container-body-row-value">{{booking.insertedDate | date:'dd/MM/yyyy HH:mm'}}</div>
        </div>
      </div>

      <div class="container-footer">
        <div class="container-footer-row">
          <div class="container-footer-row-label">{{'UNAVAILABLE_VEHICLE_REQUEST_PDF.SIGNATURE_OF_THE_RESPONSIBLE' | translate}}:</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  `,
  styleUrl: './unvailable-vehicle-request-pdf.component.scss',
})
export class UnvailableVehicleRequestPdfComponent {
  @Input() booking: Booking;

  isLoading: boolean = false;

  @ViewChild('pdfContent') pdfContent!: ElementRef;

  constructor(
    private _translateService: TranslateService,
    private _bsModalRef: BsModalRef
  ) {}

  generatePDF() {
    this.isLoading = true;

    const DATA = this.pdfContent.nativeElement;

    html2canvas(DATA, { scale: 2 }).then(canvas => {
      const imgWidth = 210; // Larghezza A4 in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgData = canvas.toDataURL('image/png');

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      pdf.save(`${this._translateService.instant('PDF_NAMES.UNAVAILABLE_VEHICLE_REQUEST_PDF')} - ${this.booking.user.displayName}.pdf`);
      this.isLoading = false;
      this._bsModalRef.hide();
    });
  }
}
