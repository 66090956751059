import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateUtilsService } from './date-utils.service';
import { Task, TaskClient, TaskType } from '../models/task/task';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TaskUtilsService {
  constructor(private datePipe: DatePipe,
    private dateUtilsService: DateUtilsService,
    private _translate: TranslateService
  ) { }

  setActionsTask(task: TaskClient): TaskClient {

    task.actions = [];
    if (task.deleted === false) {
      task.actions.push({ text: this._translate.instant("ACTIONS.EDIT"), value: 'edit' });
      task.actions.push({ text: this._translate.instant("ACTIONS.CANCEL"), value: 'cancel' });
    } else {
      task.actions.push({ text: this._translate.instant("ACTIONS.UNCANCEL"), value: 'uncancel' });
    }

    return task;
  }

  setStatusBadgeTask(task: TaskClient): TaskClient {
    task.statusClient = { text: '', tooltip: '', cssClass: '' };

    if (!task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.TO_DO');
    }

    if (task.endDate) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.DONE');
    }

    if (task.deleted) {
      task.statusClient.text = this._translate.instant('TASKS.TASK_ACTIONS_LIST.CANCEL');
    }

    if (!task.endDate) {
      if (task.assignedUserId) {
        task.statusClient.cssClass = 'bg-info';
      }
      else {
        task.statusClient.cssClass = 'bg-warning';
      }

      if (task.expirationDate) {

        let now = new Date();
        now.setHours(0, 0, 0, 0);
        let expirationDate = new Date(task.expirationDate);
        expirationDate.setHours(0, 0, 0, 0);
        if (expirationDate && expirationDate <= now) {
          task.statusClient.cssClass = 'bg-danger';
        }
      }

      if (task.vehicle?.mileage && task.expirationMileage && task.vehicle?.mileage >= task.expirationMileage) {
        task.statusClient.cssClass = 'bg-danger';
      }
    }

    if (task.endDate) {
      task.statusClient.cssClass = 'bg-success';
    }

    if (task.deleted) {
      task.statusClient.cssClass = 'bg-black';
    }

    task.statusClient.cssClass += ' custom-badge-bold';

    return task;
  }

  setShowProgressBarTask(task: Task): boolean {
    if (!task.endDate && (task.expirationDate || task.expirationMileage)) {
      return true;
    }
    else {
      return false;
    }
  }

  getRemainingDays(task: TaskClient): TaskClient {
    if (!task?.expirationDate) {
      return task;
    }

    const now = new Date();
    const expirationDate = new Date(task.expirationDate);
    const timeDiff = expirationDate.getTime() - now.getTime();

    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    task.remaingDays = {
      number: daysDiff,
      cssClass: 'color-black'
    };

    if (daysDiff <= 30) {
      task.remaingDays.cssClass = 'color-danger';
    }

    return task;
  }

  getRemainingMileage(task: TaskClient): TaskClient {
    if (!task?.expirationMileage) {
      return task;
    }

    task.remaingMileage = {
      number: task.remainingMileage,
      cssClass: 'color-black'
    };

    if (task.remainingMileage <= 1000) {
      task.remaingMileage.cssClass = 'color-danger';
    }

    return task;
  }
}