import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { Subject, takeUntil } from 'rxjs';
import { ImportFile } from 'src/app/shared/models/import/import';
import { ImportService } from 'src/app/shared/api-services/import.service';
import { PatchImportRequest } from 'src/app/shared/models/import/import-request';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'import-file-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
    ],
    templateUrl: './import-file-modal.component.html',
    styleUrl: './import-file-modal.component.scss'
})
export class ImportFileModalComponent implements OnInit, OnDestroy {

  importFile: ImportFile;
  form: FormGroup;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _importsService: ImportService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      name: this.importFile.name
    });
  }

  updateImportFile() {
    let request : PatchImportRequest = {
      name: this.form.value.name,
      mappings: this.importFile.mapping,
      confirmed: false
    };

    this._importsService.patchImport$(this.importFile.id, request)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
