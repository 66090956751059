<div class="card-filter">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="close()"></button>
    </header>

    <div class="content">
        <form class="d-flex flex-column gap-3" [formGroup]="form">
            <div class="card border px-2 py-3">
                <h5>{{'VEHICLE' | translate}}</h5>

                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-display-name" class="form-label">{{'NAME' | translate}}</label>
                        <vehicle-display-name-autocomplete (onSelectedItem)="onVehicleDisplayNameSelected($event)" />
                    </div>
        
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-license-plate" class="form-label">{{'LICENSE_PLATE' | translate}}</label>
                        <vehicle-license-plate-autocomplete (onSelectedItem)="onVehicleLicensePlateSelected($event)" />
                    </div>
                </div>
            </div>

            @if (showUser) {
                <div class="form-group">
                    <label for="user-name" class="form-label">{{'DRIVER' | translate}}</label>
                    <user-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onUserSelected($event)" />
                </div>
            }

            <div class="card border px-2 py-3">
                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label for="startUser" class="form-label">{{'BOOKINGS.DRIVER_START' | translate}}</label>
                        <user-autocomplete
                            class="input-group w-100"
                            (onSelectedItem)="onSelectStartUser($event)" />
                    </div>
        
                    <div class="form-group flex-grow-1">
                        <label for="endUser" class="form-label">{{'BOOKINGS.DRIVER_END' | translate}}</label>
                        <user-autocomplete
                            class="input-group w-100"
                            (onSelectedItem)="onSelectEndUser($event)" />
                    </div>
                </div>

                <div class="text-danger w-100 mt-1">
                    {{'BOOKING_FILTER_MODAL.ALERT_DRIVERS' | translate}}
                </div>
            </div>

            <div class="d-flex flex-wrap gap-2">
                <div class="form-group flex-grow-1">
                    <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                    <headquarter-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onSelectHeadquarter($event)" />
                </div>

                <div class="form-group flex-grow-1">
                    <label for="location" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                    <location-autocomplete
                        class="input-group w-100"
                        [headquarterId]="form.value.headquarterId"
                        (onSelectedItem)="onSelectLocation($event)" />
                </div>
            </div>

            <div class="form-group d-flex gap-2">
                <input type="checkbox" class="form-check-input" id="closeOnApply" formControlName="closeOnApply">
                <label for="closeOnApply" class="form-check-label">{{'CLOSE_FILTER_ON_APPLY' | translate}}</label>
            </div>
        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="close()">{{'ACTIONS.CLOSE' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
