import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { Tag } from 'src/app/shared/models/tag/tag';
import { BaseAutocompleteComponent } from '../base-autocomplete/base-autocomplete.component';

@Component({
  selector: 'tag-autocomplete',
  standalone: true,
  imports: [
    TranslateModule,
    AutoCompleteModule,
  ],
  template: `
    <p-autoComplete
      class="w-100"
      [placeholder]="placeholder | translate"
      [showClear]="true"
      [forceSelection]="true"
      [suggestions]="listItems"
      (completeMethod)="getItems($event.query)"
      (onClear)="onClearItem()"
      (onSelect)="onSelectItem($event)"
      optionLabel="name" />
  `,
  styleUrl: './tag-autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagAutocompleteComponent),
      multi: true
    }
  ]
})
export class TagAutocompleteComponent extends BaseAutocompleteComponent<Tag, void> {
  constructor(private _tagsService: TagsService) {
    super();
  }

  override getItems(query: string): void {
    this._tagsService.listTags$({ 
      nameContains: query, 
      limit: this.limit 
    })
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.listItems = response.items.map((tag) => ({ name: tag.name || '', item: tag }));
      });
  }
} 