import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { ToastrService } from 'ngx-toastr';
import * as VehicleUsageFilters from 'src/app/shared/constant/params/vehicle-usage-filters';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { UserModel } from 'src/app/shared/models/user/users';
import { AutocompleteModule } from '../../../inputs/autocomplete-primeng/autocomplete.module';
import { BaseFilterModalComponent } from '../base-filter-modal.component';

export const filterVehicleUsagesModal = 'vehicle-usages-filter-modal';

@Component({
    selector: 'vehicle-usages-filter-modal',
    templateUrl: './vehicle-usages-filter-modal.component.html',
    styleUrls: ['./vehicle-usages-filter-modal.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        DropdownModule,
        AutocompleteModule
    ]
})
export class VehicleUsagesFilterModalComponent extends BaseFilterModalComponent {
  @Input() showUser: boolean = true;

  constructor(
    protected override _toastrService: ToastrService,
    protected override _translateService: TranslateService,
    protected override _centralQueryParamsService: CentralQueryParamsService,
    public override bsModalRef: BsModalRef
  ) {
    super(_toastrService, _translateService, _centralQueryParamsService, bsModalRef);
    
    this.form = new FormGroup({
      vehicleId: new FormControl(null),

      userId: new FormControl(null),      
      startUserId: new FormControl(null),      
      endUserId: new FormControl(null),

      headquarterId: new FormControl(null),      
      locationId: new FormControl(null),
      
      closeOnApply: new FormControl(true),
    });
  }

  onSelectStartUser(user: UserModel) {
    this.form.patchValue({
      startUserId: user.id,
    });
  }

  onSelectEndUser(user: UserModel) {
    this.form.patchValue({
      endUserId: user.id,
    });
  }

  apply() {
    const vehicle = this.form.controls['vehicleId'].value;
    const user = this.form.controls['userId'].value;
    const startUser = this.form.controls['startUserId'].value;
    const endUser = this.form.controls['endUserId'].value;
    const headquarter = this.form.controls['headquarterId'].value;
    const location = this.form.controls['locationId'].value;

    if (vehicle) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.VEHICLE, vehicle);
    }
    if (user) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.USER, user);
    }
    if (startUser) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.START_USER, startUser);
    }
    if (endUser) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.END_USER, endUser);
    }
    if (headquarter) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.HEADQUARTER, headquarter);
    }
    if (location) {
      this._centralQueryParamsService.addQueryParam(VehicleUsageFilters.LOCATION, location);
    }

    this.handleSuccessAndClose();
  }
}