export const TASK_TYPE = "taskTypeId";
export const VEHICLE = "vehicleId";
export const USER = "userId";
export const ASSET = "assetId";
export const HEADQUARTER = "headquarterId";
export const TAG = "tagId";
export const START_USER = "startUserId";
export const ASSIGNED_USER = "assignedUserId";
export const HAS_EXPIRATION_DATE = "hasExpirationDate";
export const HAS_EXPIRATION_MILEAGE = "hasExpirationMileage";
export const HAS_COMMENTS = "hasComments";
export const HAS_ASSIGNED_USER = "hasAssignedUser";
export const HAS_VEHICLE = "hasVehicle";
export const HAS_USER = "hasUser";
export const HAS_ASSET = "hasAsset"; 