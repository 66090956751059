import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { TenantService } from './tenant.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, tap } from 'rxjs';
import { UserNotificationSettings } from '../models/user-notification-setting/user-notification-setting';
import { MeNotificationSettingsRequest, UserNotificationSettingsListRequest, UserNotificationSettingsRequest } from '../models/user-notification-setting/user-notification-settings-request';
import { UserNotificationSettingsResponse } from '../models/user-notification-setting/user-notification-setting-response';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationSettingsService {
  private _baseUrl = VECTORE_API_BASE_URL;
  private _tenantId: string;

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listUserNotificationSettings$(params: UserNotificationSettingsListRequest): Observable<UserNotificationSettingsResponse> {
    return this._http.get<UserNotificationSettingsResponse>(`${this._baseUrl}/${this._tenantId}/user-notification-settings`, { params: this._commonService.buildParams(params) });
  }

  getUserNotificationSettings$(userId: string): Observable<UserNotificationSettings> {
    return this._http.get<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/user-notification-settings/${userId}`);
  }

  createUserNotificationSettings$(userNotificationSettings: UserNotificationSettingsRequest): Observable<UserNotificationSettings> {
    return this._http.post<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/user-notification-settings`, userNotificationSettings)
      .pipe(
        tap({
          next: (response: UserNotificationSettings) => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.SUCCESS_CREATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.ERROR_CREATED'));
            this._commonService.handleError(error);
          }
        })
      );
  }

  updateUserNotificationSettings$(notificationId: string, userNotificationSettings: UserNotificationSettingsRequest): Observable<UserNotificationSettings> {
    return this._http.patch<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/user-notification-settings/${notificationId}`, userNotificationSettings)
      .pipe(
        tap({
          next: (response: UserNotificationSettings) => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.SUCCESS_UPDATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.ERROR_UPDATED'));
            this._commonService.handleError(error);
          }
        })
      );
  }

  getMeNotificationSettings$(): Observable<UserNotificationSettings> {
    return this._http.get<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/me-notification-settings`);
  }

  createMeNotificationSettings$(userNotificationSettings: MeNotificationSettingsRequest): Observable<UserNotificationSettings> {
    return this._http.post<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/me-notification-settings`, userNotificationSettings)
      .pipe(
        tap({
          next: (response: UserNotificationSettings) => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.SUCCESS_CREATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.ERROR_CREATED'));
            this._commonService.handleError(error);
          }
        })
      );
  }
  
  updateMeNotificationSettings$(notificationId: string, userNotificationSettings: MeNotificationSettingsRequest): Observable<UserNotificationSettings> {
    return this._http.patch<UserNotificationSettings>(`${this._baseUrl}/${this._tenantId}/me-notification-settings/${notificationId}`, userNotificationSettings)
      .pipe(
        tap({
          next: (response: UserNotificationSettings) => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.SUCCESS_UPDATED'));
            }
          },
          error: error => {
            this._toastrService.error(this._translateService.instant('USER_NOTIFICATION_SETTINGS.MESSAGES.ERROR_UPDATED'));
            this._commonService.handleError(error);
          }
        })
      );
  }

}
