<div class="card-booking-modal-step3 p-3">
  <div class="accordion mb-4">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{booking?.title}}
        </button>
      </h2>
    </div>
    <div id="collapseOne" class="accordion-collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="text-muted">{{'TYPE' | translate}}</td>
                        <td>{{booking?.bookingType?.name | translate}}</td>
                    </tr>
                    <tr>
                        <td class="text-muted">{{'START_DATE' | translate}}</td>
                        <td>{{booking?.startDate | date: 'dd MMM yyyy HH:mm'}}</td>
                    </tr>
                    <tr>
                        <td class="text-muted">{{'END_DATE' | translate}}</td>
                        <td>{{booking?.endDate | date: 'dd MMM yyyy HH:mm'}}</td>
                    </tr>
                    @if (booking?.expectedMileage) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.DISTANCE' | translate}}</td>
                            <td>{{booking?.expectedMileage | number: '1.0-0'}} km</td>
                        </tr>
                    }
                    @if (booking?.expectedTime) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.TIME_ESTIMATE' | translate}}</td>
                            <td>{{booking?.expectedTime}} {{'MINUTES' | translate | lowercase}}</td>
                        </tr>
                    }
                    @if (booking?.stages?.length > 0) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.DESTINATION' | translate}}</td>
                            <td>{{ booking?.stages?.[booking?.stages?.length - 1] }}</td>
                        </tr>
                    }
                    <tr>
                        <td class="text-muted">{{'BOOKINGS.DRIVER' | translate}}</td>
                        <td class="d-flex align-items-center gap-1">
                            <div class="avatar-placeholder-xs person-placeholder">
                                <img *ngIf="imgSrc| async as imageUrl" [src]="imageUrl" alt="User Image">
                            </div>
                            {{booking?.user?.displayName}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>

  @if (bookingType?.enableAlternatives) {
    <div class="d-flex text-center w-100 flex-1 mt-4 pt-4">        
        <div class="d-flex flex-column align-items-center justify-content-center flex-1">
            @if (booking?.vehicleId !== null) {
                <i class="bg-icon-primary-light bi-car-front-fill default-icon p-1"></i>
                <header class="fs-rem-125 fw-500 mb-2 mt-1" [innerHTML]="'BOOKINGS_MODAL.VEHICLE_SELECTED_BY_VECTORE' | translate | dynamicHighlight:'color-primary fs-rem-125 fw-500'"></header>
                <p class="text-muted fs-rem-085">{{'BOOKINGS_MODAL.VEHICLE_SELECTED_BY_VECTORE_INFO' | translate}}</p>
            }
            @else {
                <i class="bg-icon-danger-light bi-car-front-fill default-icon p-1"></i>
                <header class="fs-rem-125 fw-500 mb-2 mt-1">{{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_TITLE' | translate}}</header>
                <p class="text-muted fs-rem-085">{{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_CONTENT' | translate}}</p>
                
                <p class="text-danger fs-rem-085 mb-4">{{'BOOKINGS_MODAL.ALERT_CLOSE_BOOKING' | translate}}</p>
                <div class="d-flex gap-2 align-items-center justify-content-center">
                    <button class="btn-outline-gs-danger d-flex gap-2" (click)="closeBooking()">
                        <i class="bi bi-x-circle"></i>
                        {{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_BUTTON' | translate}}
                    </button>
                    <button class="btn-outline-gs-primary d-flex gap-2" (click)="viewBookingDocument()">
                        <i class="bi bi-download"></i>
                        {{'ACTIONS.VIEW_DOCUMENT' | translate}}
                    </button>
                </div>
            }
        </div>
    </div>
  } @else {
    @if (vehiclesToShow?.length > 0) {
        <booking-modal-vehicles-to-show 
            class="mt-4"
            [vehiclesToShow]="vehiclesToShow" 
            (onChangeEmitVehicleChecked)="onChangeVehicleChecked($event)">
        </booking-modal-vehicles-to-show>
    } @else {
      <div class="container-no-vehicles d-flex flex-column align-items-center justify-content-center flex-1">
        <i class="bg-icon-danger-light bi-car-front-fill default-icon p-1"></i>
        <header class="fs-rem-125 fw-500 mb-2 mt-1">{{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_TITLE' | translate}}</header>
        <p class="text-muted fs-rem-085">{{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_CONTENT' | translate}}</p>
        
        <p class="text-danger fs-rem-085 mb-4">{{'BOOKINGS_MODAL.ALERT_CLOSE_BOOKING' | translate}}</p>
        <div class="d-flex gap-2 align-items-center justify-content-center">
            <button class="btn-outline-gs-danger d-flex gap-2" (click)="closeBooking()">
                <i class="bi bi-x-circle"></i>
                {{'BOOKINGS_MODAL.NO_VEHICLES_FOUND_BUTTON' | translate}}
            </button>
            <button class="btn-outline-gs-primary d-flex gap-2" (click)="viewBookingDocument()">
                <i class="bi bi-download"></i>
                {{'ACTIONS.VIEW_DOCUMENT' | translate}}
            </button>
        </div>
      </div>
    }
  }
</div>