<div class="card-import-modal">
    <header>        
        <h4>{{'UPLAOD' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content class="d-flex flex-column gap-2">
        <div class="form-group">
            <label class="mb-2">{{'TYPE_IMPORT' | translate}}</label>
            <dropdown-primary
                [emitNgOnInit]="false"
                [activeScrollbar]="true"
                [selectedOption]="selectedTypeImporter"
                [options]="typeImportersOption"
                (selectedOptionChange)="onSelectedTypeImporter($event)">
            </dropdown-primary>
        </div>

        <div class="container-upload" *ngIf="statusModal.canUplaod">
            <input-upload-files 
                (fileInfo)="addPendingFile($event)"
                [bucketName]="bucketName">
            </input-upload-files>

            <div class="container-upload-files" *ngIf="file">
                <div class="file-title">
                    <i class="bi bi-file-earmark"></i>
                    {{file.name}}
                </div>
                <div class="file-status"></div>
            </div>

            <div class="container-form" *ngIf="file">
                <form [formGroup]="importForm">
                    <div class="form-group">
                        <label for="name" class="form-label">{{'NAME_TO_VIEW' | translate}}</label>
                        <input type="text" class="form-control" formControlName="name">
                    </div>
                </form>
            </div>

            <div class="container-download-trace">
                {{'IMPORT_PROBLEM' | translate}}
                <a class="download-trace" (click)="openModalDownloadTrace()">
                    {{'DOWNLOAD_MODEL_IMPORT' | translate}} 
                    <i class="bi bi-download"></i>
                </a>
            </div>

            <button class="btn-gs-primary-icon flex-row-reverse w-100"
                [disabled]="!statusModal.canImport || importForm.invalid"
                (click)="createImport()">
                <i class="bi bi-arrow-right"></i>
                {{'CHECK_DATA' | translate}}
            </button>
        </div>
    </content>
</div>