<div class="badge-gs" [ngClass]="{'badge-gs-best': number === 1}" *ngIf="number">
    @if (number > 1) {
        #{{number}}
    }
    @else {
        {{output}}
    }
</div>

<div class="badge-gs badge-gs-best" *ngIf="!number">

    {{text}}

</div>