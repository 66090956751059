import * as Sentry from "@sentry/angular";
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { Tag } from "src/app/shared/models/tag/tag";
import { TagsService } from "src/app/shared/api-services/tags.service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { TypeExtraField } from "src/app/shared/models/common";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { Supplier } from "src/app/shared/models/supplier/supplier";
import { SuppliersService } from "src/app/shared/api-services/suppliers.service";
import { SupplierRequest } from "src/app/shared/models/supplier/supplier-request";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { DropdownModule } from "../../dropdown/dropdown.module";

@Component({
    selector: 'suppliers-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
        TypeaheadModule,
        FormlyModule,
    ],
    templateUrl: './suppliers-modal.component.html',
    styleUrls: ['./suppliers-modal.component.scss']
})
export class SuppliersModalComponent {
  tenantId = this._tenantService.getTenantId();
  @Input() supplier?: Supplier;

  typeExtraFields: TypeExtraField[];

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  supplierForm: FormGroup;
  requestSupplier: SupplierRequest = {};

  tags$: Observable<string[]>;
  selectedTags: Tag[] = [];

  extraFields: TypeExtraField[] = [];
  fields: FormlyFieldConfig[];

  isMobile = false;

  viewMobileSubscription = this._breakpointObserver
    .observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    });

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _suppliersService: SuppliersService,
    private _tagsService: TagsService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _communicationService: CommunicationService,
    private _breakpointObserver: BreakpointObserver,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
  ) {
    this.supplierForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      vatNumber: new FormControl(null),
      code: new FormControl(null),
      tag: new FormControl(null),
    });
  }

  ngOnInit() {
    this.autoComplete();
    if (this.supplier) {
      this.setValueFields();
    } else {
      this.supplier = {};
    }
  }

  setValueFields() {
    if (this.supplier.id) {
      this.modalStatus.isCreate = false;
      this.modalStatus.isPatch = true;

      this.supplierForm.patchValue({
        name: this.supplier.name,
        vatNumber: this.supplier.vatNumber,
        code: this.supplier.code,
      });

      if (this.supplier.tags && this.supplier.tags.length > 0) {
        this.selectedTags = this.supplier.tags;
      }

      if (this.supplier.name) {
        this.supplierForm.patchValue({
          name: this.supplier.name
        });
      }

      if (this.supplier.vatNumber) {
        this.supplierForm.patchValue({
          vatNumber: this.supplier.vatNumber
        });
      }

      if (this.supplier.code) {
        this.supplierForm.patchValue({
          code: this.supplier.code
        });
      }
      
      this.getExtraFields();
    }
  }

  autoComplete() {
    this.tags$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.supplierForm.controls['tag'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTags$({nameContains: token, limit: 5})),
      map((response) => {
        let tagNames : string[] = [];
        response.items.forEach((tag) => {
          tagNames.push(tag.name);
        });
        return tagNames;
      }),
    );
  }

  onTagSelected(match: TypeaheadMatch) {
    const tagName = match.value;
    
    let tagExists = false;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagExists = this.selectedTags.some(tag => tag.name === tagName);
    }
    
    if (!tagExists) {
      this._tagsService.listTags$({ name: tagName }).subscribe({
        next: (tags) => {
          if (tags.items.length > 0) {
            const tag = tags.items[0];
            this.selectedTags.push(tag);
          }
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

  removeTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter(tag => tag.id!== tagId);
  }

  getExtraFields() {
    this._suppliersService.listSupplierFields$(this.tenantId, )
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField); 
          this.supplier = this.supplier? this.supplier : {};
          this.supplier.extraFields = this.supplier.extraFields ? this.supplier.extraFields : {};
          this.supplier.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.supplier.extraFields, false);
          this.fields = this.typeExtraFields.map(field => 
            this._extraFieldsUtilsService.createFieldConfig(field, this.supplier.extraFields));
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  cleanExtraFields() {
    Object.keys(this.supplierForm.controls).forEach(key => {
      if (this.fields && this.fields.length > 0) {
        this.fields.forEach(field => {
          if (field.key == key) {
            this.supplierForm.removeControl(key);
          }
        });
      }
    });
  }

  onSubmit() {
    if (this.supplier.id) {
      this.onPatchSupplier();
    } else {
      this.onCreateSupplier();
    }
  }

  onCreateSupplier() {    
    this.createRequestFromForm();
    if (this.supplierForm.invalid) {
      return;
    }

    this._suppliersService.createSupplier$(this.tenantId, this.requestSupplier).subscribe({
      next : response => {
        if (response) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      }
    });
  }

  onPatchSupplier() {    
    this.createRequestFromForm();
    if (this.supplierForm.invalid) {
      return;
    }

    this._suppliersService.updateSupplier$(this.tenantId, this.supplier.id, this.requestSupplier).subscribe({
      next: response => {
        if (response) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      }
    });
  }

  createRequestFromForm() {
    if (this.typeExtraFields?.length > 0) {
      this.requestSupplier = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.supplierForm.value, this.requestSupplier);
      this.requestSupplier.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.requestSupplier.extraFields);
    }

    if (!this.requestSupplier?.extraFields) {
      this.requestSupplier.extraFields = {};
    }

    this.requestSupplier.code = this.supplierForm.controls['code'].value ?? null;
    this.requestSupplier.name = this.supplierForm.controls['name'].value ?? null;
    this.requestSupplier.vatNumber = this.supplierForm.controls['vatNumber'].value ?? null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this.requestSupplier.tagIds = this.selectedTags.map(tag => tag.id);
    }
  }
}
