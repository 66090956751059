import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, debounceTime, Observable, Observer, switchMap, map, Subject, takeUntil } from 'rxjs';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { FilterModalService } from '../filter-modal.service';
import { HeadquarterResponse } from 'src/app/shared/models/headquarter/headquarter-response';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { TagResponse } from 'src/app/shared/models/tag/tag-response';
import { UserGroupsService } from 'src/app/shared/api-services/user-groups.service';
import { UserGroupResponse } from 'src/app/shared/models/user-group/user-group-response';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeadquarterFilter } from 'src/app/shared/models/headquarter/headquarter-filter';
import { ToastrService } from 'ngx-toastr';
import { GenericFilter } from '../generic-filter';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';

export const filterPersonModal = 'filter-person-modal';

@Component({
    selector: 'filter-person-modal',
    templateUrl: './filter-person-modal.component.html',
    styleUrl: './filter-person-modal.component.scss',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TypeaheadModule,
        FormsModule,
        TranslateModule,
        DropdownModule,
    ]
})
export class FilterPersonModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  genericFilter: GenericFilter = {};

  headquarterName$: Observable<string[]>;
  tagName$: Observable<string[]>;
  groupName$: Observable<string[]>;

  private _destroy$ = new Subject<void>();

  constructor(
    private _headquartersService: HeadquartersService,
    private _userGroupsService: UserGroupsService,
    private _tagsService: TagsService,
    private _filterModalService: FilterModalService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleTypeId: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      tagId: new FormControl(null),
      tagName: new FormControl(),

      groupId: new FormControl(null),
      groupName: new FormControl(),

      closeOnApply: new FormControl(true),
    });
  }

  ngOnInit() {
    this.autoComplete();
  }

  private autoComplete() {
    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      takeUntil(this._destroy$),  
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listHeadquarters$({ nameContains: token, limit: 5 })),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );

    this.tagName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['tagName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTags$({ nameContains: token, limit: 5 })),
      map((response: TagResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((tag) => {
          displayNames.push(tag.name);
        });
        return displayNames;
      }),
    );

    this.groupName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['groupName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._userGroupsService.listUserGroupsNew$({ nameContains: token, limit: 5 })),
      map((response: UserGroupResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((group) => {
          displayNames.push(group.name);
        });
        return displayNames;
      }),
    );
  }

  onSelectHeadquarter(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listHeadquarters$({ name } as HeadquarterFilter)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          if (response.items.length > 0) {
            this.form.patchValue({
              headquarterName: response.items[0].name
            });
            this.genericFilter.headquarter = response.items[0];
          }
        }
      });
  }

  onSelectTag(match: TypeaheadMatch) {
    const name = match.value;
    this._tagsService.listTags$({ name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ tagId: response.items[0].id, tagName: response.items[0].name });
          this.genericFilter.tag = response.items[0];
        }
      }
    });
  }

  onSelectGroup(match: TypeaheadMatch) {
    const name = match.value;
    this._userGroupsService.listUserGroupsNew$({ name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({ groupId: response.items[0].id, groupName: response.items[0].name });
          this.genericFilter.group = response.items[0];
        }
      }
    });
  }

  onSelectedHasGroup(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.genericFilter.hasGroups = true;
    } else if (optionSelected.value === 'inactive') {
      this.genericFilter.hasGroups = false;
    } else {
      this.genericFilter.hasGroups = null;
    }
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    this._filterModalService.applyFilter(filterPersonModal, this.genericFilter);
    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.genericFilter = {};
      this.form.reset();
    }
  }

  close() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}