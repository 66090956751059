import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PermissionSwitch } from '../permission-modal.component';
import * as PERMISSIONS from 'src/app/shared/constant/permissions';
import { PermissionModalUtilsService } from '../permission-modal-utils.service';
import { Permission } from 'src/app/shared/models/permission/permission';
import { ListSwitch, ListSwitchesComponent } from '../../../lists/list-switches/list-switches.component';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'permission-asset-section-modal',
    imports: [
        ListSwitchesComponent,
        TranslateModule
    ],
    templateUrl: './permission-asset-section-modal.component.html',
    styleUrl: './permission-asset-section-modal.component.scss'

})
export class PermissionAssetSectionModalComponent implements OnInit, OnDestroy {

  @Input() permissionName: string;
  @Input() permission: Permission;

  assetTypeSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_ASSET_TYPES',
    listSwitch: [],
  }

  headquartersSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_HEADQUARTERS_OUTSIDE',
    listSwitch: [],
  }

  tagsSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_TAGS',
    listSwitch: [],
  }

  expr: string;

  @Output() exprChange = new EventEmitter<string>();
  permissionsConst = PERMISSIONS;

  private _destroy = new Subject<void>();

  constructor(
    private _permissionModalUtilsService: PermissionModalUtilsService
  ) { }

  ngOnInit(): void {
    this.assetTypeSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'AssetTypeId') : '';
    this._permissionModalUtilsService.getAssetTypes$({ expr: this.assetTypeSwitch.expr } as Permission, 'AssetTypeId')
      .pipe(takeUntil(this._destroy))
      .subscribe(assetTypes => {
        this.assetTypeSwitch.listSwitch = assetTypes;
      });

    this.headquartersSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'HeadquarterId') : '';
    this._permissionModalUtilsService.getHeadquarters$({ expr: this.headquartersSwitch.expr } as Permission, true, 'HeadquarterId')
      .pipe(takeUntil(this._destroy))
      .subscribe(headquarters => {
        this.headquartersSwitch.listSwitch = headquarters;
      });

    this.tagsSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromContains(this.permission.expr, 'TagIds') : '';
    this._permissionModalUtilsService.getTags$({ expr: this.tagsSwitch.expr } as Permission, 'TagIds')
      .pipe(takeUntil(this._destroy))
      .subscribe(tags => {
        this.tagsSwitch.listSwitch = tags;
      });
  }

  onSwitchAssetTypesSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.assetTypeSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.assetTypeSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('AssetTypeId', ids);
    this.onExprChange();
  }

  onSwitchHeadquartersSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.headquartersSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.headquartersSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('HeadquarterId', ids);
    this.onExprChange();
  }

  onSwitchTagsSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.tagsSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.tagsSwitch.expr = this._permissionModalUtilsService.createExpressionWithContains('TagIds', ids);
    this.onExprChange();
  }

  onExprChange() {
    const expressions: string[] = [];

    if (this.assetTypeSwitch.expr) {
      expressions.push(this.assetTypeSwitch.expr);
    }

    if (this.headquartersSwitch.expr) {
      expressions.push(this.headquartersSwitch.expr);
    }

    if (this.tagsSwitch.expr) {
      expressions.push(this.tagsSwitch.expr);
    }

    if (expressions.length === 0) {
      this.exprChange.emit('');
    } else if (expressions.length === 1) {
      this.exprChange.emit(expressions[0]);
    } else {
      const joinedExpr = expressions.join(';');
      const expr = `or(${joinedExpr})`;
      this.exprChange.emit(expr);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

}