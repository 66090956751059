import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImportDataStatus } from 'src/app/shared/constant/status-import';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '../../dropdown/dropdown.module';


@Component({
    selector: 'import-management-data-filter-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
    ],
    templateUrl: './import-management-data-filter-modal.component.html',
    styleUrl: './import-management-data-filter-modal.component.scss'
})
export class ImportManagementDataFilterModalComponent implements OnInit, OnDestroy {
  form: FormGroup;

  statusOption: DropdownOption[];
  selectedStatus: DropdownOption = { value: '', text: this._translateService.instant('SELECT_OPTION') };

  private _destroy = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    private _centralQueryParamsService: CentralQueryParamsService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      status: new FormControl(null)
    });
  }

  ngOnInit(): void {
    this.getStatus();
  }

  private getStatus() {
    this.statusOption = Object.values(ImportDataStatus).map(status => ({ value: status, text: this._translateService.instant(status) }));
  }

  onSelectedStatus($event: DropdownOption) {
    this.form.patchValue({ status: $event.value });
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    this._centralQueryParamsService.addQueryParam('status-import-file', this.form.value.status);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this._centralQueryParamsService.removeQueryParam('status-import-file');
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
