import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocationModel } from 'src/app/shared/models/location/location';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { map, Subject, takeUntil, tap } from 'rxjs';
import { CreateLocationRequest, UpdateLocationRequest } from 'src/app/shared/models/location/location-request';
import { Map, Marker, tileLayer } from 'leaflet';
import { MapOptions } from 'leaflet';
import { latLng } from 'leaflet';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { NominatimResponse } from 'src/app/shared/services/nominatim.service';
import { NominatimService } from 'src/app/shared/services/nominatim.service';
import { OsrmMapService } from 'src/app/shared/services/osrm-map.service';
import { CommonModule } from '@angular/common';
import { DropdownModule } from '../../dropdown/dropdown.module';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'location-modal',
    templateUrl: './location-modal.component.html',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AutoCompleteModule,
        FormsModule,
        DropdownModule,
        LeafletModule,
        LeafletMarkerClusterModule,
        TranslateModule
    ],
    styleUrl: './location-modal.component.scss'
})
export class LocationModalComponent implements OnInit, OnDestroy {

  location: LocationModel;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  map: Map;
  options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 10,
    center: latLng(44.1745385, 12.0388596)
  };
  markers: Marker[] = [];
  listAddress: { name: string }[] = [];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _headquarterService: HeadquartersService,
    private _communicationService: CommunicationService,
    private _nominatimService: NominatimService,
    private _osrmService: OsrmMapService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      headquarterId: new FormControl(null, [Validators.required]),
      address: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
    });
  }

  ngOnInit() {
    this.form.get('name')?.disable();

    if (this.location?.id) {
      this.getLocation();
    }
  }

  getLocation() {
    this._headquarterService.getLocation$(this.location.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.location = response;
        this.form.patchValue({
          name: response.name,
          address: response.address,
          latitude: response.latitude,
          longitude: response.longitude
        });

        if (response.latitude && response.longitude) {
          this.markers = [this._osrmService.setMarker(this.map, Number(response.latitude), Number(response.longitude))];
          this._osrmService.centerViewMap(this.map, Number(response.latitude), Number(response.longitude), 18);
        }

        this.modalStatus.isPatch = true;
        this.modalStatus.isCreate = false;
      });
  }

  onHeadquarterSelected($event: DropdownOption) {
    this.form.get('name')?.enable();
    this.form.patchValue({
      headquarterId: $event.value
    });
  }

  onMapReady(map: Map) {
    this.map = map;
    setTimeout(() => {
      this.map.invalidateSize();
    }, 0);
  }

  createLocation() {
    let request: CreateLocationRequest = {
      name: this.form.value.name,
      headquarterId: this.form.value.headquarterId,
      address: this.form.value.address,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    }

    this._headquarterService.createLocation$(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }

  updateLocation() {
    let request: UpdateLocationRequest = {
      name: this.form.value.name,
      address: this.form.value.address,
      latitude: this.form.value.latitude,
      longitude: this.form.value.longitude
    }
    this._headquarterService.updateLocation$(this.location.id, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }

  onClearAddress() {
    if (this.markers) {
      this.markers.forEach(marker => {
        this.map.removeLayer(marker);
      });
    }
    this.markers = null;
  }

  filterAdress(event: { query: string }) {
    this._nominatimService.searchLocation(event.query, 5).pipe(
      takeUntil(this.destroy$),
      map((locations: NominatimResponse[]) => locations.map(location => ({ name: location.display_name })))
    ).subscribe((locations: { name: string }[]) => {
      this.listAddress = locations;
    });
  }

  onSelectAddress($event: AutoCompleteSelectEvent) {
    this._nominatimService.searchLocation($event.value.name).pipe(
      takeUntil(this.destroy$),
      tap((locations: NominatimResponse[]) => {
        this.markers = [this._osrmService.setMarker(this.map, Number(locations[0].lat), Number(locations[0].lon))];
        this.form.get('address')?.setValue(locations[0].display_name);
        this._osrmService.centerViewMap(this.map, Number(locations[0].lat), Number(locations[0].lon), 18);
        this.form.get('latitude')?.setValue(Number(locations[0].lat));
        this.form.get('longitude')?.setValue(Number(locations[0].lon));
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
