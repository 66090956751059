import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { TaskClient } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TaskUtilsService } from 'src/app/shared/utilities/task-utils.service';
import { DropdownModule } from 'src/app/shared/components/dropdown/dropdown.module';

@Component({
    selector: 'dropdown-status-task',
    imports: [DropdownModule],
    template: `
      <dropdown-primary 
        [(selectedOption)]="taskAction"
        [emitNgOnInit]="false"
        [options]="listTaskActions"
        (selectedOptionChange)="onSelectStatusTask($event)"
        [iconClass]="'bi bi-chevron-down'"
        [cssClass]="'btn-status-task ' + task.statusClient?.cssClass">
      </dropdown-primary>
    `,
})
export class DropdownStatusTaskComponent implements OnInit, OnChanges, OnDestroy {

  tenantId = this._tenantService.getTenantId();
  @Input() task: TaskClient;
  taskEditForm: FormGroup;

  taskAction: DropdownOption;
  listTaskActions: DropdownOption[];

  private _destroy$ = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _taskUtilsService: TaskUtilsService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.listTaskActions = this._translateService.instant('TASKS.TASK_ACTIONS');
    this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
    this.setStateTaskAction();
  }

  ngOnChanges() {
    this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
  }

  setStateTaskAction() {
    //Task to do
    if (!this.task.endDate) {
      this.taskAction = JSON.parse(JSON.stringify(this.listTaskActions[0]));
    }

    //Task done - closed
    if (this.task.endDate) {
      this.taskAction = JSON.parse(JSON.stringify(this.listTaskActions[1]));
    }

    //Task canceled
    if (this.task.deleted) {
      this.taskAction = JSON.parse(JSON.stringify(this.listTaskActions[2]));
    }
  }

  onSelectStatusTask($event: DropdownOption) {

    if ($event.value === 'done' && !this.task.endDate) {
      this._taskService.closeTask$(this.tenantId, this.task.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (task) => {
            if (task.endDate) {
              this.task.endUserId = task.endUserId;
              this.task.endDate = task.endDate;
              this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
            }
          }
        });
    }

    if ($event.value === 'cancel' && !this.task.deleted) {
      this._taskService.deleteTask$(this.tenantId, this.task.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (response) => {
            if (response.status === 204) {
              this.task.deleted = true;
              this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
            }
          }
        });
    }

    if ($event.value === 'to_do' && this.task.endDate && !this.task.deleted) {
      this._taskService.openTask$(this.tenantId, this.task.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (response) => {
            if (!response.endDate) {
              this.task.endDate = response.endDate;
              this.task.endUserId = response.endUserId;
              this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
            }
          }
        });
    }

    if ($event.value === 'to_do' && !this.task.endDate && this.task.deleted) {
      this._taskService.unDeleteTask$(this.tenantId, this.task.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: (response) => {
            if (!response.deleted) {
              this.task.deleted = response.deleted;
              this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
            }
          }
        });
    }

    if ($event.value === 'to_do' && this.task.endDate && this.task.deleted) {
      this._taskService.unDeleteTask$(this.tenantId, this.task.id).pipe(
        takeUntil(this._destroy$),
        switchMap((response) => {
          if (!response.deleted) {
            this.task.deleted = response.deleted;
            this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
          }
          return this._taskService.openTask$(this.tenantId, this.task.id);
        })
      ).subscribe({
        next: (response) => {
          if (!response.endDate) {
            this.task.endDate = response.endDate;
            this.task.endUserId = response.endUserId;
            this.task = this._taskUtilsService.setStatusBadgeTask(this.task);
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
