import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { UserModel } from 'src/app/shared/models/user/users';
import { Headquarter } from 'src/app/shared/models/headquarter/headquarter';
import { LocationModel } from 'src/app/shared/models/location/location';

@Injectable()
export abstract class BaseFilterModalComponent implements OnDestroy {
  form: FormGroup;
  protected _destroy$ = new Subject<void>();

  constructor(
    protected _toastrService: ToastrService,
    protected _translateService: TranslateService,
    protected _centralQueryParamsService: CentralQueryParamsService,
    public bsModalRef: BsModalRef
  ) {}

  onVehicleDisplayNameSelected(vehicle: Vehicle) {
    if (vehicle) {
      this.form.patchValue({
        vehicleId: vehicle.id
      });
    }
  }

  onVehicleLicensePlateSelected(vehicle: Vehicle) {
    if (vehicle) {
      this.form.patchValue({
        vehicleId: vehicle.id
      });
    }
  }

  onUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        userId: user.id
      });
    }
  }

  onSelectHeadquarter(headquarter: Headquarter) {
    this.form.patchValue({
      headquarterId: headquarter.id,
      locationId: null
    });
  }

  onSelectLocation(location: LocationModel) {
    this.form.patchValue({
      locationId: location.id
    });
  }

  close() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  protected handleSuccessAndClose() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));
    
    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.form.reset();
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
} 