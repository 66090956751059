import * as Sentry from "@sentry/angular";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VehiclesService } from "src/app/shared/api-services/vehicles.service";
import { TracksTableFilterComponent } from './tracks-table-filter/tracks-table-filter.component';
import { TrackExportFilter, TrackFilter } from "src/app/shared/models/track/track-filter";
import { Track } from "src/app/shared/models/track/track";
import { FleetlocationService } from "src/app/pages/fleetlocation/fleetlocation.service";
import { CommonModule, DatePipe } from "@angular/common";
import { MappingModel } from "src/app/shared/models/user/users";
import { DynamicTableComponent } from "../dynamic-table/dynamic-table-desktop/dynamic-table.component";

@Component({
    selector: 'tracks-table',
    imports: [
        CommonModule,
        TranslateModule,
        DynamicTableComponent,
        TracksTableFilterComponent
    ],
    templateUrl: './tracks-table.component.html',
    styleUrl: './tracks-table.component.scss'
})
export class TracksTableComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  @Input() vehicleId: string;
  tracks: Track[] = [];

  trackForm: FormGroup = new FormGroup({
    isAdvanced: new FormControl(false),
    date: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  tableColumns: TableColumn[];
  tableRows: TableRow[];
  rowIds: string[] = [];

  filterHeader: string;
  filterTask: DropdownOption;

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 100,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  isMobile = false;
  isLoading = false;

  @Output() showHoverMapEvent = new EventEmitter<void>();

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _fleetlocationService: FleetlocationService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    private _datePipe: DatePipe,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;

    this.setHeaderTable();
  }

  setHeaderTable() {
    this.tableColumns = [];
    if (!this.isMobile) {
      this.tableColumns = [
        {
          field: 'vehicleId',
          header: "TRACK_TABLE.NAME",
          value: (track: Track) => track.vehicle?.displayName || ''
        },
        {
          field: 'address',
          header: "TRACK_TABLE.ADDRESS",
          value: (track: Track) => track.address || '',
          tooltip: (track: Track) => `${track.latitude}, ${track.longitude}`
        },
        {
          field: 'date',
          isSortable: true,
          header: "TRACK_TABLE.DATE",
          value: (track: Track) => track.dateFormatted || ''
        },
        {
          field: 'mileage',
          isSortable: true,
          header: "TRACK_TABLE.MILEAGE",
        },
        {
          field: 'ignitionOn',
          header: "TRACK_TABLE.IGNITION_ON",
          value: (track: Track) => track.ignitionOn ? this._translateService.instant('ACTIONS.YES') : this._translateService.instant('ACTIONS.NO')
        },
        {
          field: 'lastDateIgnitionOn',
          header: "TRACK_TABLE.LAST_DATE_IGNITION_ON",
          value: (track: Track) => track.lastDateIgnitionFormatted || ''
        }
      ];
    } else {
      this.tableColumns = [
        {
          field: 'address',
          header: "TRACK_TABLE.ADDRESS",
          value: (track: Track) => track.address || '',
          tooltip: (track: Track) => `${track.latitude}, ${track.longitude}`
        },
        {
          field: 'date',
          isSortable: true,
          header: "TRACK_TABLE.DATE",
          value: (track: Track) => track.dateFormatted || ''
        },
        {
          field: 'mileage',
          isSortable: true,
          header: "TRACK_TABLE.MILEAGE",
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getTracks() {
    let params: TrackFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
      includeVehicle: true,
    }

    this.getRangeDate(params);

    this.isLoading = true;
    this._vehiclesService.listVehicleTracks$(this.tenantId, this.vehicleId, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.tracks = response.items;
          this.rowIds = [];

          response.items.forEach(vehicle => {
            this.rowIds.push(vehicle.id);
            vehicle.dateFormatted = this._datePipe.transform(vehicle.date, 'dd-MM-yyyy HH:mm')?.toString();
            vehicle.lastDateIgnitionFormatted = this._datePipe.transform(vehicle.lastDateIgnitionOn, 'dd-MM-yyyy HH:mm')?.toString();
          });

          this.tableRows = [];
          this.tableRows = response.items;

          this.filtersPage.totalRows = response.count;
          this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
          this.isLoading = false;
        },
        error: error => {
          Sentry.captureEvent(error);
          this.isLoading = false;
        }
      });
  }

  private getRangeDate(params: TrackFilter) {
    if (this.trackForm.value.isAdvanced) {
      params.rangeStartDate = this.trackForm.value.startDate ? new Date(new Date(this.trackForm.value.startDate).setHours(0, 0, 0, 0)).toISOString() : null;
      params.rangeEndDate = this.trackForm.value.endDate ? new Date(new Date(this.trackForm.value.endDate).setHours(23, 59, 59, 999)).toISOString() : null;
    } else {
      let startDate = this.trackForm.value.date ? new Date(new Date(this.trackForm.value.date).setHours(0, 0, 0, 0)).toISOString() : null;
      let endDate = this.trackForm.value.date ? new Date(new Date(this.trackForm.value.date).setHours(23, 59, 59, 999)).toISOString() : null;
      params.rangeStartDate = startDate;
      params.rangeEndDate = endDate;
    }
  }

  getDataForm($event: FormGroup) {
    this.trackForm = $event;
    this.getTracks();
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getTracks();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getTracks();
  }

  onPageSizeChange(newSize: number) {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getTracks();
  }

  showHoverMap() {
    this._fleetlocationService.sendTracks(this.tracks);
    this.showHoverMapEvent.emit();
  }

  getExport() {
    let mappings: MappingModel = {
      mappings: [
        { sourceName: 'Vehicle.LicensePlate', destName: this._translateService.instant('LicensePlate') },
        { sourceName: 'Address', destName: this._translateService.instant('TRACK_TABLE.ADDRESS') },
        { sourceName: 'Date', destName: this._translateService.instant('TRACK_TABLE.DATE') },
        { sourceName: 'Mileage', destName: this._translateService.instant('TRACK_TABLE.MILEAGE') },
        { sourceName: 'IgnitionOn', destName: this._translateService.instant('TRACK_TABLE.IGNITION_ON') },
        { sourceName: 'LastDateIgnitionOn', destName: this._translateService.instant('TRACK_TABLE.LAST_DATE_IGNITION_ON') },
        { sourceName: 'Latitude', destName: this._translateService.instant('TRACK_TABLE.LATITUDE') },
        { sourceName: 'Longitude', destName: this._translateService.instant('TRACK_TABLE.LONGITUDE') }
      ]
    };

    let params: TrackExportFilter = {
      includeVehicle: true,
    }

    this.getRangeDate(params);

    this._vehiclesService.exportVehiclesTracks$(this.tenantId, this.vehicleId, mappings, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          let now = new Date();
          const filename = `export_vehicle_tracks_${now.toLocaleDateString('it-IT')}_${now.toLocaleTimeString('it-IT')}.xlsx`;
          const blob = new Blob([response.body], { type: response.body.type });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.click();
          window.URL.revokeObjectURL(url);
        },
        error: error => {
          Sentry.captureEvent(error);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}