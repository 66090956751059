import { Injectable } from '@angular/core';
import { TokenResp } from '../models/token-response';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenKey = 'token';
  private vectoreTokenKey = 'vectoreToken';
  private refreshTokenKey = 'refreshToken';
  private expirationToken = 'expirationToken';
  private tokenType = 'tokenType';
  private scope = 'scope';

  setTokenModel(tokenResp: TokenResp) {
    this.setToken(tokenResp.accessToken);
    this.setRefreshToken(tokenResp.refreshToken);
    this.setDateExpirationToken(tokenResp);
    this.setTokenType(tokenResp.tokenType);
    this.setScope(tokenResp.scope);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  getVectoreToken(): string | null {
    return localStorage.getItem(this.vectoreTokenKey);
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  setVectoreToken(vectoreToken: string): void {
    localStorage.setItem(this.vectoreTokenKey, vectoreToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.refreshTokenKey);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(this.refreshTokenKey, refreshToken);
  }

  getExpirationToken(): string | null {
    return localStorage.getItem(this.expirationToken);
  }

  setExpirationToken(expirationToken: string): void {
    localStorage.setItem(this.expirationToken, expirationToken);
  }

  getTokenType(): string | null {
    return localStorage.getItem(this.tokenType);
  }

  setTokenType(tokenType: string): void {
    localStorage.setItem(this.tokenType, tokenType);
  }

  getScope(): string | null {
    return localStorage.getItem(this.scope);
  }

  setScope(scope: string): void {
    localStorage.setItem(this.scope, scope);
  }

  clearAll(): void {
    window.localStorage.removeItem(this.tokenKey);
    window.localStorage.removeItem(this.vectoreTokenKey);
    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.expirationToken);
    window.localStorage.removeItem(this.tokenType);
    window.localStorage.removeItem(this.scope);
  }

  private setDateExpirationToken(response: TokenResp) {
    let tokenExpirationDate = new Date();
    tokenExpirationDate.setSeconds(tokenExpirationDate.getSeconds() + response.expiresIn);
    this.setExpirationToken(tokenExpirationDate.toString());
  }
}
