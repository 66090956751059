<div class="card-filter">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="content">
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="task-type" class="form-label">{{'TYPOLOGY' | translate}}</label>
                <dropdown-primary-booking-type 
                    [id]="form.value.bookingTypeId ?? null"
                    (onSelectedEvent)="onBookingTypeSelected($event)">                    
                </dropdown-primary-booking-type>
            </div>

            <div class="card border px-2 py-3">
                <h5>{{'VEHICLE' | translate}}</h5>

                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-display-name" class="form-label">{{'NAME' | translate}}</label>
                        <vehicle-display-name-autocomplete
                            (onSelectedItem)="onVehicleDisplayNameSelected($event)">
                        </vehicle-display-name-autocomplete>
                    </div>
        
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-license-plate" class="form-label">{{'LICENSE_PLATE' | translate}}</label>
                        <vehicle-license-plate-autocomplete
                            (onSelectedItem)="onVehicleLicensePlateSelected($event)">
                        </vehicle-license-plate-autocomplete>
                    </div>
                </div>
            </div>

            @if (showUser) {
                <div class="form-group">
                    <label for="user-name" class="form-label">{{'DRIVER' | translate}}</label>
                    <user-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onUserSelected($event)">
                    </user-autocomplete>
                </div>
            }

            <div class="d-flex flex-wrap gap-2">
                <div class="form-group flex-grow-1">
                    <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                    <headquarter-autocomplete 
                        class="input-group w-100"
                        (onSelectedItem)="onSelectHeadquarter($event)">
                    </headquarter-autocomplete>
                </div>
    
                <div class="form-group flex-grow-1">
                    <label for="location" class="form-label">{{'LOCATION.NAME' | translate}}</label>
                    <location-autocomplete 
                        class="input-group w-100"
                        [headquarterId]="form.value.headquarterId"
                        (onSelectedItem)="onSelectLocation($event)">
                    </location-autocomplete>
                </div>
            </div>

            <div class="d-flex flex-wrap gap-2">
                <div class="form-group flex-grow-1">
                    <label for="inserted-by-user" class="form-label">{{'BOOKINGS.INSERTED_BY_USER' | translate}}</label>
                    <user-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onInsertedByUserSelected($event)">
                    </user-autocomplete>
                </div>
    
                <div class="form-group flex-grow-1">
                    <label for="approved-by-user" class="form-label">{{'APPROVED_BY_USER' | translate}}</label>
                    <user-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onApprovedByUserSelected($event)">
                    </user-autocomplete>
                </div>

                <div class="form-group flex-grow-1">
                    <label for="rejected-by-user" class="form-label">{{'REJECTED_BY_USER' | translate}}</label>
                    <user-autocomplete
                        class="input-group w-100"
                        (onSelectedItem)="onRejectedByUserSelected($event)">
                    </user-autocomplete>
                </div>
            </div>



            <div class="form-group">
                <label class="form-label">{{'IS_FRINGE' | translate}}</label>
                <dropdown-primary-active (onSelectedEvent)="onSelectedIsFringe($event)"></dropdown-primary-active>
            </div>

            <div class="form-group">
                <label class="form-label">{{'STATUS' | translate}}</label>
                <dropdown-primary-booking-status (optionSelectedChange)="onSelectedStatus($event)"></dropdown-primary-booking-status>
            </div>

            <div class="form-group d-flex gap-2">
                <input type="checkbox" class="form-check-input" id="closeOnApply" formControlName="closeOnApply">
                <label for="closeOnApply" class="form-check-label">{{'CLOSE_FILTER_ON_APPLY' | translate}}</label>
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="close()">{{'ACTIONS.CLOSE' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
