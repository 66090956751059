import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { Asset, AssetFiles } from '../models/assets/asset';
import { AssetResponse, AssetTypesResponse, AssetFieldsResponse, AssetFilesResponse, AssetDataResponse } from '../models/assets/asset-response';
import { MappingModel } from '../models/user/users';
import { CommonService } from './common.service';
import { FileModel } from '../models/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CreateAssetRequest, PatchAssetRequest } from '../models/assets/asset-request';
import { AssetDataFilter, AssetFieldsFilter, AssetFilesFilter, AssetFilter, AssetsFilter } from '../models/assets/asset-filter';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {
  private _tenantId: string;
  private assetImageCache = new Map<string, Observable<any>>();

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
    private _tenantService: TenantService
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listAssetTypes$(tenantId: string, params?: AssetsFilter): Observable<AssetTypesResponse> {
    return this._http.get<AssetTypesResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-types`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listAssetFields$(tenantId: string, params?: AssetFieldsFilter): Observable<AssetFieldsResponse> {
    return this._http.get<AssetFieldsResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-fields`, { params: this._commonService.buildParams(params) });
  }

  createAsset$(tenantId: string, assetRequest: CreateAssetRequest): Observable<Asset> {
    return this._http.post<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets`, assetRequest);
  }

  listAssets$(tenantId: string, params?: AssetsFilter): Observable<AssetResponse> {
    return this._http.get<AssetResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/assets`, { params: this._commonService.buildParams(params) });
  }

  getAsset$(tenantId: string, assetId: string, params?: AssetFilter): Observable<Asset> {
    return this._http.get<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, { params: this._commonService.buildParams(params) });
  }

  getAssetById$(assetId: string): Observable<Asset> {
    return this._http.get<Asset>(`${VECTORE_API_BASE_URL}/${this._tenantId}/assets/${assetId}`)
      .pipe(
        tap({
          next: response => console.log(response),
          error: error => console.log(error)
        })
      );
  }

  updateAsset$(tenantId: string, assetId: string, assetRequest: PatchAssetRequest): Observable<Asset> {
    return this._http.patch<Asset>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, assetRequest);
  }

  deleteAsset$(tenantId: string, assetId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getAssetImage$(tenantId: string, assetId: string, width?: number, height?: number): Observable<SafeUrl> {
    const cacheKey = `${tenantId}-${assetId}-${width}-${height}`;
    if (!this.assetImageCache.has(cacheKey)) {
      const params = {
        width: width,
        height: height
      };
      const requestObservable = this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/image`, {
        params: this._commonService.buildParams(params),
        responseType: 'blob' as 'json'
      })
        .pipe(
          shareReplay(1),
          tap({
            error: error => this._commonService.hanleErrorImage(error)
          })
        );
      this.assetImageCache.set(cacheKey, requestObservable);
    }
    return this.assetImageCache.get(cacheKey)
      .pipe(
        map(response => {
          if (response) {
            let urlCreator = window.URL || window.webkitURL;
            return this._domSanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(response));
          }
          return null;
        })
      );
  }

  exportAssets$(tenantId: string, exportData: MappingModel, params?: AssetsFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/assets`, exportData, {
      observe: 'response',
      responseType: 'blob',
      params: this._commonService.buildParams(params)
    })
      .pipe(
        tap({
          next: response => {
            const now = new Date();
            const filename = `export_assets_${now.toLocaleDateString('it-IT')}.xlsx`;
            this._commonService.downloadFile(response, filename);
          },
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  linkAssetFile$(tenantId: string, assetId: string, fileModel: FileModel): Observable<HttpResponse<AssetFiles>> {
    return this._http.post<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files`, fileModel, { observe: 'response' });
  }

  listAssetFiles$(tenantId: string, assetId: string, params?: AssetFilesFilter): Observable<AssetFilesResponse> {
    return this._http.get<AssetFilesResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files`, { params: this._commonService.buildParams(params) });
  }

  getAssetFileById$(tenantId: string, assetId: string, fileId: string): Observable<AssetFiles> {
    return this._http.get<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`);
  }

  updateAssetFile$(tenantId: string, assetId: string, fileId: string, fileModel: FileModel): Observable<AssetFiles> {
    return this._http.patch<AssetFiles>(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`, fileModel);
  }

  deleteAssetFile$(tenantId: string, assetId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}`, { observe: 'response' });
  }

  downloadAssetFile$(tenantId: string, assetId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this._http.get(`${VECTORE_API_BASE_URL}/${tenantId}/assets/${assetId}/files/${fileId}/content`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  getAssetData$(tenantId: string, params?: AssetDataFilter): Observable<AssetDataResponse> {
    return this._http.get<AssetDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/asset-data`, { params: this._commonService.buildParams(params) });
  }
}



