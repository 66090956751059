<div class="container-information  d-flex flex-column gap-2">
    <div class="title">{{'TASKS.MODAL_EDIT_TASK.TITLE_INFORMATION' | translate}}</div>
    <div *ngIf="!isEditMode">
        <table>
            <tr *ngFor="let key of objectKeys(taskViewModel.extraFields)">
                <td class="span pe-4">{{key | translate}}</td>
                <td>{{taskViewModel.extraFields[key]}}</td>
              </tr>
        </table>
    </div>
    <formly-form [form]="form" [fields]="fields" *ngIf="isEditMode"></formly-form>
    <div class="card-light-footer d-flex gap-2">
        <button class="btn-gs-primary-noborder mt-4" 
            *ngIf="!isEditMode" (click)="editMode()">{{'ACTIONS.EDIT' | translate}}</button>

        <button class="btn-gs-secondary" (click)="cancel()" *ngIf="isEditMode">{{'ACTIONS.CANCEL' | translate}}</button>
        <button class="btn-gs-primary" (click)="onSubmit()" *ngIf="isEditMode" [disabled]="form.invalid">{{'ACTIONS.SAVE' | translate}}</button>
    </div>
</div>