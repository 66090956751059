import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PermissionSwitch } from '../permission-modal.component';
import * as PERMISSIONS from 'src/app/shared/constant/permissions';
import { PermissionModalUtilsService } from '../permission-modal-utils.service';
import { Permission } from 'src/app/shared/models/permission/permission';
import { ListSwitch, ListSwitchesComponent } from '../../../lists/list-switches/list-switches.component';
import { Subject, takeUntil } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'permission-booking-section-modal',
    imports: [
        ListSwitchesComponent,
        TranslateModule
    ],
    templateUrl: './permission-booking-section-modal.component.html',
    styleUrl: './permission-booking-section-modal.component.scss'

})
export class PermissionBookingSectionModalComponent implements OnInit, OnDestroy {

  @Input() permissionName: string;
  @Input() permission: Permission;

  bookingTypeSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_BOOKING_TYPES',
    listSwitch: [],
  }

  headquartersSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_HEADQUARTERS_OUTSIDE',
    listSwitch: [],
  }

  locationsSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_LOCATIONS_OUTSIDE',
    listSwitch: [],
  }

  userGroupsSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_USER_GROUPS',
    listSwitch: [],
  }

  bookingForMe: {
    isForMe: boolean;
    expr: string;
  } = {
      isForMe: false,
      expr: '',
    }

  expr: string;

  @Output() exprChange = new EventEmitter<string>();
  permissionsConst = PERMISSIONS;

  private _destroy = new Subject<void>();

  constructor(
    private _permissionModalUtilsService: PermissionModalUtilsService
  ) { }

  ngOnInit(): void {
    if (this.permissionName !== this.permissionsConst.CAN_READ_VEHICLE_USAGES && this.permissionName !== this.permissionsConst.CAN_MANAGE_VEHICLE_USAGES) {
      this.bookingTypeSwitch.showSwitch = true;
      this.bookingTypeSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'BookingTypeId') : '';
      this._permissionModalUtilsService.getBookingTypes$({ expr: this.bookingTypeSwitch.expr } as Permission, 'BookingTypeId')
        .pipe(takeUntil(this._destroy))
        .subscribe(bookingTypes => {
          this.bookingTypeSwitch.listSwitch = bookingTypes;
        });
    }

    this.headquartersSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'HeadquarterId') : '';
    this._permissionModalUtilsService.getHeadquarters$({ expr: this.headquartersSwitch.expr } as Permission, true, 'HeadquarterId')
      .pipe(takeUntil(this._destroy))
      .subscribe(headquarters => {
        this.headquartersSwitch.listSwitch = headquarters;
      });

    this.locationsSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'LocationId') : '';
    this._permissionModalUtilsService.getLocations$({ expr: this.locationsSwitch.expr } as Permission, 'LocationId')
      .pipe(takeUntil(this._destroy))
      .subscribe(locations => {
        this.locationsSwitch.listSwitch = locations;
      });

    this.userGroupsSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromContains(this.permission.expr, 'User.GroupIds') : '';
    this._permissionModalUtilsService.getUserGroups$({ expr: this.userGroupsSwitch.expr } as Permission, 'User.GroupIds')
      .pipe(takeUntil(this._destroy))
      .subscribe(userGroups => {
        this.userGroupsSwitch.listSwitch = userGroups;
      });

    this.bookingForMe.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'UserId') : '';
    if (this.bookingForMe.expr !== null && this.bookingForMe.expr !== '') {
      this.bookingForMe.isForMe = true;
      this.userGroupsSwitch.showSwitch = false;
    } else {
      this.userGroupsSwitch.showSwitch = this.permissionName !== this.permissionsConst.CAN_READ_VEHICLE_USAGES && this.permissionName !== this.permissionsConst.CAN_MANAGE_VEHICLE_USAGES ? true : false;
    }

  }

  onSwitchBookingTypesSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.bookingTypeSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.bookingTypeSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('BookingTypeId', ids);
    this.onExprChange();
  }

  onSwitchHeadquartersSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.headquartersSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.headquartersSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('HeadquarterId', ids);
    this.onExprChange();
  }

  onSwitchLocationsSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.locationsSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.locationsSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('LocationId', ids);
    this.onExprChange();
  }

  onSwitchUserGroupsSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.userGroupsSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.userGroupsSwitch.expr = this._permissionModalUtilsService.createExpressionWithContains('User.GroupIds', ids);
    this.onExprChange();
  }

  onClickBookingForMe() {
    this.bookingForMe.isForMe = !this.bookingForMe.isForMe;
    this.bookingForMe.expr = 'eq([UserId];"#MEID#")';
    if (this.bookingForMe.isForMe) {
      this.userGroupsSwitch.expr = '';
      this.userGroupsSwitch.showSwitch = false;
    } else {
      this.userGroupsSwitch.showSwitch = this.permissionName !== this.permissionsConst.CAN_READ_VEHICLE_USAGES && this.permissionName !== this.permissionsConst.CAN_MANAGE_VEHICLE_USAGES ? true : false;
    }
    this.onExprChange();
  }

  onExprChange() {
    const andExpressions: string[] = [];
    const orExpressions: string[] = [];

    if (this.bookingForMe.isForMe) {
      andExpressions.push(this.bookingForMe.expr);

      if (this.bookingTypeSwitch.expr) {
        andExpressions.push(this.bookingTypeSwitch.expr);
      }

      if (this.headquartersSwitch.expr) {
        andExpressions.push(this.headquartersSwitch.expr);
      }

      if (this.locationsSwitch.expr) {
        andExpressions.push(this.locationsSwitch.expr);
      }
    }
    else {
      if (this.bookingTypeSwitch.expr) {
        orExpressions.push(this.bookingTypeSwitch.expr);
      }
  
      if (this.headquartersSwitch.expr) {
        orExpressions.push(this.headquartersSwitch.expr);
      }
  
      if (this.locationsSwitch.expr) {
        orExpressions.push(this.locationsSwitch.expr);
      }
  
      if (this.userGroupsSwitch.expr) {
        orExpressions.push(this.userGroupsSwitch.expr);
      }
  
      if (this.bookingForMe.isForMe) {
        orExpressions.push(this.bookingForMe.expr);
      }
    }


    // Costruisci l'espressione finale con controllo minimo due espressioni per AND/OR
    let finalExpr = '';

    const andExpr = andExpressions.length >= 2 ? `and(${andExpressions.join(';')})` : andExpressions[0] || '';
    const orExpr = orExpressions.length >= 2 ? `or(${orExpressions.join(';')})` : orExpressions[0] || '';

    if (andExpr && orExpr) {
      finalExpr = `or(${orExpr};${andExpr})`;
    } else if (andExpr) {
      finalExpr = andExpr;
    } else if (orExpr) {
      finalExpr = orExpr;
    }

    this.exprChange.emit(finalExpr);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}