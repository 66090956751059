import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs';
import { AssetsService } from 'src/app/shared/api-services/assets.service';
import { Asset } from 'src/app/shared/models/assets/asset';
import { BaseAutocompleteComponent } from '../base-autocomplete/base-autocomplete.component';
import { TenantService } from 'src/app/shared/api-services/tenant.service';

@Component({
  selector: 'asset-autocomplete',
  standalone: true,
  imports: [
    TranslateModule,
    AutoCompleteModule,
  ],
  template: `
    <p-autoComplete
      class="w-100"
      [placeholder]="placeholder | translate"
      [showClear]="true"
      [forceSelection]="true"
      [suggestions]="listItems"
      (completeMethod)="getItems($event.query)"
      (onClear)="onClearItem()"
      (onSelect)="onSelectItem($event)"
      optionLabel="name" />
  `,
  styleUrl: './asset-autocomplete.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssetAutocompleteComponent),
      multi: true
    }
  ]
})
export class AssetAutocompleteComponent extends BaseAutocompleteComponent<Asset, void> {
  @Input() assetTypeId?: string;
  
  private _tenantId: string;

  constructor(
    private _assetsService: AssetsService,
    private _tenantService: TenantService
  ) {
    super();
    this._tenantId = this._tenantService.getTenantId();
  }

  override getItems(query: string): void {
    this._assetsService.listAssets$(this._tenantId, { 
      displayNameContains: query,
      assetTypeId: this.assetTypeId,
      limit: this.limit 
    })
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        this.listItems = response.items.map((asset) => ({ name: asset.displayName || '', item: asset }));
      });
  }
} 