import * as Sentry from "@sentry/angular";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { Injectable } from '@angular/core';
import { AuthOptions, LoginResponse, LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Observable, map, of, switchMap, take, tap } from 'rxjs';
import { TenantService } from '../../shared/api-services/tenant.service';
import { TokenResp } from '../../shared/models/token-response';
import { TokenService } from '../../shared/api-services/token.service';
import { AuthService } from '../../shared/api-services/auth.service';
import { Router } from "@angular/router";
import { IdpResponse } from "src/app/shared/models/idb-response";
import { ToastrService } from "ngx-toastr";
import { MeService } from "src/app/shared/api-services/me.service"; import { TranslateService } from "@ngx-translate/core";
import { LoginForm } from "src/app/pages/login/login.component";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  tenantId: string = this._tenantService.getTenantId();
  private tokenSubject = new BehaviorSubject<TokenResp | null>(null);
  token$ = this.tokenSubject.asObservable();

  countSameLink = 0;
  previousUrl: string | undefined;
  saveUrl: {
    url: string;
    queryParams: any;
  } | null;

  private readonly OIDC_LOGIN_INITIATED_KEY = 'oidcLoginInitiated'; // Chiave per sessionStorage per tenere traccia del primo login OIDC

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private _authService: AuthService,
    private _tenantService: TenantService,
    private _translateService: TranslateService,
    private _meService: MeService,
    private _tokenService: TokenService,
    private _loginService: AuthService,
    private _toastrService: ToastrService,
    public router: Router,
  ) { }

  private checkAuthenticationOidc(): Observable<LoginResponse> {
    return this.oidcSecurityService.checkAuth()
      .pipe(
        tap((response) => {
          const oidcLoginInitiated = sessionStorage.getItem(this.OIDC_LOGIN_INITIATED_KEY) === 'true';

          // Logga solo se autenticato e se il login OIDC era stato iniziato
          if (response.isAuthenticated && oidcLoginInitiated) {
            sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);
            this._meService.logAccess$({ isSuccessful: true }).pipe(take(1)).subscribe();
          }
        }),
        tap({
          error: (err) => {
            sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);
            Sentry.captureEvent(err);
            this.router.navigate([RoutesUrl.LOGIN]);

            if (err.message?.includes('SSL connection') || 
                err.message?.includes('certificate') || 
                err.message?.includes('IDX20803') ||
                err.message?.includes('IDX20804')) {
              this._toastrService.error(
                this._translateService.instant('AUTH.ERRORS.IDENTITY_SERVER_ERROR')
              );
              return;
            }

            let errorMessage = '';
            
            switch(err.status) {
              case 302:
                errorMessage = this._translateService.instant('AUTH.ERRORS.REDIRECT_ERROR');
                break;
              case 401:
                errorMessage = this._translateService.instant('AUTH.ERRORS.UNAUTHORIZED');
                break;
              case 404:
                errorMessage = this._translateService.instant('AUTH.ERRORS.IDP_NOT_FOUND');
                break;
              case 0:
                errorMessage = this._translateService.instant('AUTH.ERRORS.NETWORK_ERROR');
                break;
              default:
                errorMessage = this._translateService.instant('AUTH.ERRORS.GENERIC_ERROR');
            }

            this._toastrService.error(errorMessage, 'Authentication Error');
          }
        })
      );
  }

  private checkAuthenticationVectoreAuth(): Observable<boolean> {
    return this._authService.getIdp$(this.tenantId).pipe(
      switchMap((idp: IdpResponse) => {
        if (idp.vectoreAuth) {
          let token = this._tokenService.getToken();
          let refreshToken = this._tokenService.getRefreshToken();
          if (!token || !refreshToken) {
            return of(false);
          }

          let expirationToken = this._tokenService.getExpirationToken();
          let expirationTokenDate = new Date(expirationToken);
          let now = new Date();
          if (expirationTokenDate > now) {
            return of(true);
          } else {
            return this.getRefreshTokenVectoreAuth(refreshToken);
          }
        } else {
          return of(false);
        }
      }),
      tap({
        error: (error) => {
          if (error.status === 0 || error.status === 500) {
            sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);
            this.router.navigate([RoutesUrl.LOGIN]);
          }
          this._toastrService.error(error.error.detail, error.error.title);
          Sentry.captureEvent(error);
        }
      })
    );
  }

  checkAuthentication(): Observable<boolean> {
    if (this.getVectoreTokenDirect()) {
      this._meService.sendMe(this.tenantId);
      return of(true);
    }

    return this._authService.getIdp$(this.tenantId).pipe(
      switchMap((idpResponse) => {
        if (idpResponse.vectoreAuth) {
          return this.checkAuthenticationVectoreAuth().pipe(
            switchMap((vectoreAuthenticated) => {
              return of(vectoreAuthenticated);
            })
          );
        } else {
          return this.checkAuthenticationOidc().pipe(
            switchMap((oidcAuthResult) => {
              return of(oidcAuthResult?.isAuthenticated ?? false);
            }),
            tap({
              error: (error) => {
                if (error.status === 400 || error.status === 401) {
                  this._toastrService.error(this._translateService.instant('MESSAGES.ERROR_AUTH'));
                }
                if (error.status === 0 || error.status === 500) {
                  this.router.navigate([RoutesUrl.LOGIN]);
                }
                if (error.status === 404) {
                  return of(true);
                }
                sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);
                this._toastrService.error(error.error.detail, error.error.title);
                this.router.navigate([RoutesUrl.LOGIN]);
                Sentry.captureEvent(error);
                return of(true);
              }
            })
          );
        }
      }),
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this._meService.sendMe(this.tenantId);
        }
      }),
    );
  }

  checkLoop() {
    let getLink = this.router.url;
    if (getLink === this.previousUrl) {
      this.countSameLink++;
    } else {
      this.countSameLink = 0;
      this.previousUrl = getLink;
    }

    if (this.countSameLink > 10) {
      this.logout();
      this.router.navigate([RoutesUrl.LOGIN]);
      this._toastrService.error('Too many redirects. Please, try again later.', 'Error');
    }
  }

  loginOidc() {
    sessionStorage.setItem(this.OIDC_LOGIN_INITIATED_KEY, 'true');
    this._authService.getIdp$(this.tenantId).subscribe(
      (response: IdpResponse) => {
        if (response.loginParameters) {
          // add custom parameters to the logout request
          // format string is 'key1=value1&key2=value2'
          const authOptions: AuthOptions = { customParams: this.parseCustomParams(response.loginParameters) };
          this.oidcSecurityService.authorize(null, authOptions);
        }
        else {
          this.oidcSecurityService.authorize();
        }
      }
    );
  }

  logout() {
    sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);

    if (this.getVectoreTokenDirect()) {
      this.logoutVectoreAuth();
    }

    this._authService.getIdp$(this.tenantId).subscribe(
      (response: IdpResponse) => {
        if (response.vectoreAuth) {
          this.logoutVectoreAuth();
        }
        else {
          this.logoutOidc(response);
        }
      }
    );
  }

  private logoutOidc(idpResponse: IdpResponse) {
    sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);

    if (idpResponse.logoutParameters) {
      // add custom parameters to the logout request
      // format string is 'key1=value1&key2=value2'
      const logoutAuthOptions: LogoutAuthOptions = { customParams: this.parseCustomParams(idpResponse.logoutParameters) };
      this.oidcSecurityService.logoff(null, logoutAuthOptions).pipe(
        take(1),
        tap({
          error: (error) => {
            console.error(error);
            Sentry.withScope(scope => {
              scope.setExtra("tenantId", this.tenantId);
              scope.setLevel("warning");
              Sentry.captureEvent(error);
            });
          }
        })
      ).subscribe();

    } else {

      this.oidcSecurityService.logoffAndRevokeTokens().pipe(
        take(1),
        tap({
          error: (error) => {
            this.logoutVectoreAuth();
            console.error(error);
            Sentry.withScope(scope => {
              scope.setExtra("tenantId", this.tenantId);
              scope.setLevel("warning");
              Sentry.captureEvent(error);
            });
          }
        })
      ).subscribe();
    }
  }

  loginVectoreAuth(loginForm: LoginForm): Observable<TokenResp> {
    return this._loginService.getToken$(loginForm, this.tenantId).pipe(
      tap((response: TokenResp) => {
        this.tokenSubject.next(response);
        this._tokenService.setTokenModel(response);
        this.router.navigate([RoutesUrl.HOME]);
        if (response.accessToken) {
           this._meService.logAccess$({ isSuccessful: true }).pipe(take(1)).subscribe();
        }
      }),
      tap({
        error: (error) => {
          if (error.status !== 401) {
            Sentry.captureEvent(error);
          }
          this._toastrService.error(error.error.detail, error.error.title);
        }
      })
    );
  }

  loginDirectToken(token: string) {
    this._tokenService.setVectoreToken(token);
    this.router.navigate([RoutesUrl.HOME]);
    this._meService.sendMe(this.tenantId);
  }

  private logoutVectoreAuth() {
    sessionStorage.removeItem(this.OIDC_LOGIN_INITIATED_KEY);
    this._tokenService.clearAll();
    this.router.navigate([RoutesUrl.LOGIN]);
  }

  getVectoreTokenDirect(): string {
    return this._tokenService.getVectoreToken();
  }

  getAccessTokenVectoreAuth(): string {
    return this._tokenService.getToken();
  }

  getAccessTokenOidc(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  private getRefreshTokenVectoreAuth(refreshToken: string): Observable<boolean> {
    return this._loginService.getRefreshToken$(refreshToken, this.tenantId).pipe(
      tap((response: TokenResp) => {
        this.tokenSubject.next(response);
        this._tokenService.setTokenModel(response);
      }),
      map(() => true),
      tap({
        error: (error) => {
          this._toastrService.error(error.error.detail, error.error.title);
          Sentry.captureEvent(error);
          this.router.navigate([RoutesUrl.LOGIN]);
        }
      })
    );
  }

  private parseCustomParams(paramsString: string): { [key: string]: string } {
    const customParams: { [key: string]: string } = {};
    const paramsArray = paramsString.split('&');
    paramsArray.forEach(param => {
      const [key, value] = param.split('=');
      customParams[key] = value;
    });
    return customParams;
  }
}
