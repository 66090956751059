import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { TypeaheadMatch, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, Subscription, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehicleResponse } from 'src/app/shared/models/vehicle/vehicle-response';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';


@Component({
    selector: 'button-add-vehicle',
    imports: [
        CommonModule,
        TranslateModule,
        TypeaheadModule,
        ReactiveFormsModule,
    ],
    templateUrl: './button-add-vehicle.component.html',
    styleUrls: ['./button-add-vehicle.component.scss']
})
export class ButtonAddVehicleComponent {
  tenantId: string = this._tenantService.getTenantId();

  @Input() text: string;

  @Input() vehicleId: string;
  vehicle: Observable<Vehicle>;
  vehicleSrcImage: Observable<SafeUrl>;

  @Input() autoCompleteParams: any = {};

  isEditMode = false;

  form: FormGroup = new FormGroup({
    vehicleDisplayName: new FormControl(),
  });
  vehicleDisplayName$: Observable<string[]>;

  @Output() vehicleIdChange = new EventEmitter<string>();

  subscription: Subscription;

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.autoComplete();

    if (this.vehicleId) {
      this.getVehicle();
    }
  }

  private getVehicle() {
    this.vehicle = this._vehiclesService.getVehicle$(this.tenantId, this.vehicleId);
    this.vehicleSrcImage = this._vehiclesService.getVehicleImage$(this.vehicleId, 48, 48);
  }

  onAddVehicle() {
    this.isEditMode = true;
  }

  private autoComplete() {
    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { displayNameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((vehicle: VehicleResponse) => {
        let displayNames: string[] = [];
        vehicle.items.forEach((vehicle) => {
          displayNames.push(vehicle.displayName);
        });
        return displayNames;
      }),
    );
  }

  onSelectVehicle(match: TypeaheadMatch) {
    const displayName = match.value;

    this._vehiclesService.listVehicles$(this.tenantId, { displayName: displayName }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.vehicle = of(response.items[0]);
          this.vehicleSrcImage = this._vehiclesService.getVehicleImage$(response.items[0].id, 48, 48);
          this.form.controls['vehicleDisplayName'].setValue(null);
          this.isEditMode = false;
          this.vehicleIdChange.emit(response.items[0].id);
        }
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this._elementRef.nativeElement.contains(event.target);
    const clickedOnInput = (event.target as HTMLElement).id === 'vehicleId';
    const clickedOnButton = (event.target as HTMLElement).closest('#btn-add-vehicle');
    if (!clickedInside && !clickedOnInput && !clickedOnButton && this.isEditMode) {
      this.isEditMode = false;
    } else if (clickedOnButton && !this.isEditMode) {
      this.isEditMode = true;
    }
  }

  onDelete() {
    this.vehicle = of(null);
    this.isEditMode = true;
    this.vehicleIdChange.emit(null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
