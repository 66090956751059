import * as Sentry from "@sentry/angular";
import * as Permissions from 'src/app/shared/constant/permissions';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { TooltipModule as PrimeNgTooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { UserGroupsService } from "src/app/shared/api-services/user-groups.service";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { Permission } from "src/app/shared/models/permission/permission";
import { PermissionCreateRequest, PermissionUpdateRequest } from "src/app/shared/models/permission/permission-request";
import { ListSwitch } from "../../lists/list-switches/list-switches.component";
import { PermissionTypeSectionModalComponent } from "./permission-type-section-modal/permission-type-section-modal.component";
import { PermissionTaskSectionModalComponent } from "./permission-task-section-modal/permission-task-section-modal.component";
import { PermissionAssetSectionModalComponent } from "./permission-asset-section-modal/permission-asset-section-modal.component";
import { PermissionBookingSectionModalComponent } from "./permission-booking-section-modal/permission-booking-section-modal.component";
import { PermissionVehicleSectionModalComponent } from "./permission-vehicle-section-modal/permission-vehicle-section-modal.component";
import { PermissionUserSectionModalComponent } from "./permission-user-section-modal/permission-user-section-modal.component";
import { DropdownModule } from "../../dropdown/dropdown.module";

export interface PermissionSwitch {
  showSwitch: boolean;
  titleSwitch?: string;
  listSwitch: ListSwitch[];
  expr?: string;
}


@Component({
    selector: 'permission-modal',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        PrimeNgTooltipModule,
        InputSwitchModule,
        PermissionTypeSectionModalComponent,
        PermissionTaskSectionModalComponent,
        PermissionAssetSectionModalComponent,
        PermissionBookingSectionModalComponent,
        PermissionVehicleSectionModalComponent,
        PermissionUserSectionModalComponent,
        DropdownModule
    ],
    templateUrl: './permission-modal.component.html',
    styleUrl: './permission-modal.component.scss'
})
export class PermissionModalComponent implements OnInit, OnDestroy {

  tenantId = this._tenantService.getTenantId();
  @Input() permission?: Permission;
  @Input() userGroupId: string;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  showFilterPermission = true;
  optionFilterPermissionSelected: DropdownOption;
  listFilterPermissionOptions: DropdownOption[] = [
    {
      value: 'all',
      text: 'ALL'
    },
    {
      value: 'asset',
      text: 'ASSET'
    },
    {
      value: 'booking',
      text: 'BOOKINGS.NAME'
    },
    {
      value: 'task',
      text: 'TASK'
    },
    {
      value: 'cost',
      text: 'COSTS.NAME'
    },
    {
      value: 'vehicle',
      text: 'VEHICLESS'
    },
    {
      value: 'user',
      text: 'USER'
    },
    {
      value: 'invoice',
      text: 'INVOICES.NAME'
    },
    {
      value: 'supplier',
      text: 'SUPPLIERS.NAME'
    },
    {
      value: 'headquarter',
      text: 'HEADQUARTER.NAME'
    },
    {
      value: 'location',
      text: 'LOCATION.NAME'
    }
  ]

  showTypeSection = false;
  showTaskSection = false;
  showAssetSection = false;
  showBookingSection = false;
  showVehicleSection = false;
  showUserSection = false;
  showInvoiceSection = false;
  showSupplierSection = false;

  permissionsConst = Permissions;
  permissionNames: DropdownOption[] = [];
  permissionSelected: DropdownOption;

  permissionForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    description: new FormControl(null),
    advanced: new FormControl(false),
    expr: new FormControl(null),
  });

  private _detroy$: Subject<void> = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _userGroupsService: UserGroupsService,
    private _toastrService: ToastrService,
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit(): void {
    if (this.permission) {
      this.modalStatus.isPatch = true;
      this.modalStatus.isCreate = false;
      this.showFilterPermission = false;
      this.setValueFields();
    }
    this.optionFilterPermissionSelected = JSON.parse(JSON.stringify(this.listFilterPermissionOptions[0]));

    this.changeView();
  }

  private changeView() {
    this.permissionForm.get('name').valueChanges.subscribe(name => {
      switch (name) {
        case this.permissionsConst.CAN_READ_ASSET_TYPES:
        case this.permissionsConst.CAN_READ_TASK_TYPES:
        case this.permissionsConst.CAN_READ_BOOKING_TYPES:
        case this.permissionsConst.CAN_READ_VEHICLE_TYPES:
        case this.permissionsConst.CAN_READ_HEADQUARTERS:
        case this.permissionsConst.CAN_MANAGE_HEADQUARTERS:
        case this.permissionsConst.CAN_READ_LOCATIONS:
        case this.permissionsConst.CAN_MANAGE_LOCATIONS:
        case this.permissionsConst.CAN_READ_TAGS:
        case this.permissionsConst.CAN_MANAGE_TAGS:
          this.showTypeSection = true;
          this.showTaskSection = false;
          this.showAssetSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_TASKS:
        case this.permissionsConst.CAN_MANAGE_TASKS:
        case this.permissionsConst.CAN_READ_COSTS:
        case this.permissionsConst.CAN_MANAGE_COSTS:
          this.showTaskSection = true;
          this.showAssetSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_ASSETS:
        case this.permissionsConst.CAN_MANAGE_ASSETS:
          this.showAssetSection = true;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_BOOKINGS:
        case this.permissionsConst.CAN_MANAGE_BOOKINGS:
        case this.permissionsConst.CAN_READ_VEHICLE_USAGES:
        case this.permissionsConst.CAN_MANAGE_VEHICLE_USAGES:
        case this.permissionsConst.CAN_APPROVE_BOOKINGS:
        case this.permissionsConst.CAN_ADMIN_BOOKINGS:
          this.showBookingSection = true;
          this.showAssetSection = false;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_VEHICLES:
        case this.permissionsConst.CAN_MANAGE_VEHICLES:
          this.showVehicleSection = true;
          this.showAssetSection = false;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_USERS:
        case this.permissionsConst.CAN_MANAGE_USERS:
          this.showUserSection = true;
          this.showAssetSection = false;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showInvoiceSection = false;
          this.showSupplierSection = false;
          break;
        case this.permissionsConst.CAN_READ_INVOICES:
        case this.permissionsConst.CAN_MANAGE_INVOICES:
          this.showInvoiceSection = true;
          this.showAssetSection = false;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          break;
        case this.permissionsConst.CAN_READ_SUPPLIERS:
        case this.permissionsConst.CAN_MANAGE_SUPPLIERS:
          this.showSupplierSection = true;
          this.showAssetSection = false;
          this.showTaskSection = false;
          this.showTypeSection = false;
          this.showBookingSection = false;
          this.showVehicleSection = false;
          this.showUserSection = false;
          this.showInvoiceSection = false;
          break;
      }
    });
  }

  setValueFields() {
    this.permissionForm.patchValue({
      name: this.permission.name,
      description: this.permission.description,
      expr: this.permission.expr,
    });
  }

  onSelectedFilter(event: DropdownOption) {
    this.optionFilterPermissionSelected = event;
  }

  onPermissionSelected(event: DropdownOption) {
    this.permissionForm.patchValue({
      name: event.value,
    });
  }

  onMainSectionExprChange(expr$: string) {
    this.permissionForm.patchValue({
      expr: expr$,
    });
  }

  onSubmit(): void {
    if (this.modalStatus.isCreate) {
      let request: PermissionCreateRequest = {
        userGroupId: this.userGroupId,
        name: this.permissionForm.value.name,
        description: this.permissionForm.value.description,
        expr: this.permissionForm.value.expr,
      }

      this._userGroupsService.createPermission$(this.tenantId, request)
        .pipe(takeUntil(this._detroy$))
        .subscribe({
          next: (response) => {
            if (response.id) {
              this._communicationService.sendEvent();
              this.bsModalRef.hide();
            }
          }
        });
    }

    if (this.modalStatus.isPatch) {
      let request: PermissionUpdateRequest = {
        description: this.permissionForm.value.description,
        expr: this.permissionForm.value.expr,
      }

      this._userGroupsService.updatePermission$(this.tenantId, this.permission.id, request)
        .pipe(takeUntil(this._detroy$))
        .subscribe({
          next: (response) => {
            if (response.id) {
              this._communicationService.sendEvent();
              this.bsModalRef.hide();
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._detroy$.next();
    this._detroy$.complete();
  }

}
