import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

export interface ErrorModalData {
  title: string;
  description: string;
  exclamationIcon?: boolean;
}

@Component({
    selector: 'app-error-modal',
    imports: [
        CommonModule,
        TranslateModule
    ],
    templateUrl: './error-modal.component.html',
    styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent {
  @Input() data: ErrorModalData;
}
