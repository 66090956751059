<div class="card card-modal-manage-follower p-4 d-flex flex-column gap-4">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="modal-title fs-5 pull-left">{{ 'ADD_FOLLOWER' | translate }}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body">
        <form class="d-flex flex-column gap-1" [formGroup]="form">
            <div class="form-group mb-2">
                <label for="userName" class="form-label">{{ 'SEARCH' | translate }}</label>
                <input type="text" class="form-control" [placeholder]="'TYPE_TO_SEARCH' | translate"
                    formControlName="userName"
                    [isAnimated]="true"
                    [typeahead]="userName$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="onSelectUser($event)">
            </div>

            <div formGroupName="usersForm">
                <div class="form-check" *ngFor="let user of selectedUsers; let i = index" [formGroupName]="user.id.toString()">
                    <input class="form-check-input" type="checkbox" formControlName="isFollower">
                    <label class="form-check-label">
                        {{ user.displayName }}
                    </label>
                </div>
            </div>
        
        

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>

        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
