import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CommonService } from './common.service';
import { Tag } from '../models/tag/tag';
import { TagResponse } from '../models/tag/tag-response';
import { TagRequest } from '../models/tag/tag-request';
import { Observable, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TagFilter } from '../models/tag/tag-filter';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {

  private baseUrl = VECTORE_API_BASE_URL;
  private _tenantId: string;

  constructor(
    private http: HttpClient,
    private _tenantService: TenantService,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
  ) { 
    this._tenantId = this._tenantService.getTenantId();
  }

  createTag$(tag: TagRequest): Observable<Tag> {
    return this.http.post<Tag>(`${this.baseUrl}/${this._tenantId}/tags`, tag)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_CREATED'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listTags$(params: TagFilter): Observable<TagResponse> {
    return this.http.get<TagResponse>(`${this.baseUrl}/${this._tenantId}/tags`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getTag$(tagId: string): Observable<Tag> {
    return this.http.get<Tag>(`${this.baseUrl}/${this._tenantId}/tags/${tagId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateTag$(tagId: string, tag: TagRequest): Observable<Tag> {
    return this.http.patch<Tag>(`${this.baseUrl}/${this._tenantId}/tags/${tagId}`, tag)
      .pipe(
        tap({
          next: response => {
            if (response.id) {
              this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_UPDATED'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteTag$(tagId: string): Observable<HttpResponse<Object>> {
    return this.http.delete(`${this.baseUrl}/${this._tenantId}/tags/${tagId}`, {
      observe: 'response'
    })
      .pipe(
        tap({
          next: response => {
            if (response.status === 204) {
              this._toastrService.success(this._translateService.instant('TAGS.MESSAGES.SUCCESS_DELETED'));
            } else {
              this._toastrService.error(this._translateService.instant('TAGS.MESSAGES.ERROR_DELETED'));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }
}
