import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { Subject } from 'rxjs';

@Component({
  template: ''
})
export abstract class BaseAutocompleteComponent<T, R> implements OnDestroy, ControlValueAccessor {
  listItems: { name: string, item: T }[] = [];
  value: string = '';

  onChange = (value: string) => {};
  onTouched = () => {};

  @Input() placeholder: string = 'TYPE_TO_SEARCH';
  @Input() limit: number = 5;
  @Output() onSelectedItem = new EventEmitter<T>();

  protected _destroy$ = new Subject<void>();

  abstract getItems(query: string): void;

  onClearItem() {
    this.listItems = [];
  }

  onSelectItem(event: AutoCompleteSelectEvent) {
    let item = event.value.item as T;
    this.onSelectedItem.emit(item);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInput(event: Event): void {
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
} 