import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { DropdownOption } from '../dropdown.interface';

@Component({
    selector: 'dropdown-primary-booking-status',
    template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisabled"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown ' + cssClass">
    </dropdown-primary>
  `,
    standalone: false
})
export class DropdownPrimaryBookingStatusComponent implements OnInit, OnDestroy {
  @Input() optionSelected: DropdownOption;
  @Output() optionSelectedChange = new EventEmitter<DropdownOption>();
  @Input() isDisabled: boolean = false;
  @Input() cssClass: string = '';

  listOptions: DropdownOption[] = [];
  private _destroy$ = new Subject<void>();

  constructor(
    private _bookingService: BookingService,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loadBookingStatuses();
  }

  loadBookingStatuses(): void {
    this._bookingService.listBookingStatuses$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(statuses => {
        this.listOptions = statuses.map(status => ({
          value: status.name,
          text: `BOOKING_STATUS.${status.name}`
        }));

        if (!this.optionSelected && this.listOptions.length > 0) {
          this.optionSelected = {
            value: '',
            text: this._translateService.instant('SELECT_OPTION')
          };
        }
      });
  }

  onSelected(option: DropdownOption): void {
    this.optionSelected = option;
    this.optionSelectedChange.emit(option);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
} 