import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Subject, takeUntil } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { TooltipModule as PrimeNgTooltipModule } from 'primeng/tooltip';
import { UserGroupRequest } from "src/app/shared/models/user-group/user-group-request";
import { UserGroupsService } from "src/app/shared/api-services/user-groups.service";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { UserGroup } from "src/app/shared/models/user-group/user-group";

@Component({
    selector: 'user-group-modal',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        PrimeNgTooltipModule,
    ],
    templateUrl: './user-group-modal.component.html',
    styleUrl: './user-group-modal.component.scss'
})
export class UserGroupModalComponent implements OnInit, OnDestroy {

  tenantId = this._tenantService.getTenantId();
  @Input() userGroup?: UserGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  userGroupForm: FormGroup = new FormGroup({
    name: new FormControl(null),
    description: new FormControl(null),
    rank: new FormControl(null),
  });

  private _detroy$: Subject<void> = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _userGroupsService: UserGroupsService,
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit(): void {
    if (this.userGroup) {
      this.modalStatus.isPatch = true;
      this.modalStatus.isCreate = false;
      this.setValueFields();
    }
  }

  setValueFields() {
    this.userGroupForm.patchValue({
      name: this.userGroup.name,
      description: this.userGroup.description,
      rank: this.userGroup.rank,
    });
  }

  onSubmit(): void {
    if (this.userGroupForm.value.name === '' || this.userGroupForm.value.name === null) {
      this.userGroupForm.get('name')!.setErrors({ invalid: true });
      this.userGroupForm.get('name')!.markAsTouched();
      return;
    }


    let request: UserGroupRequest = {
      name: this.userGroupForm.value.name,
      description: this.userGroupForm.value.description,
      rank: this.userGroupForm.value.rank,
    }

    if (this.modalStatus.isCreate) {
      this._userGroupsService.createUserGroup$(this.tenantId, request)
        .pipe(takeUntil(this._detroy$))
        .subscribe({
          next: (response) => {
            if (response.id) {
              this._communicationService.sendEvent();
              this.bsModalRef.hide();
            }
          }
        });
    }

    if (this.modalStatus.isPatch) {
      this._userGroupsService.updateUserGroup$(this.tenantId, this.userGroup.id, request)
        .pipe(takeUntil(this._detroy$))
        .subscribe({
          next: (response) => {
            if (response.id) {
              this._communicationService.sendEvent();
              this.bsModalRef.hide();
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this._detroy$.next();
    this._detroy$.complete();
  }
}
