import { Component, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { ToastrService } from 'ngx-toastr';
import * as VehicleFilters from 'src/app/shared/constant/params/vehicle-filters';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { Make, Model, VersionModel } from 'src/app/shared/models/make/make';
import { Headquarter } from 'src/app/shared/models/headquarter/headquarter';
import { LocationModel } from 'src/app/shared/models/location/location';
import { Tag } from 'src/app/shared/models/tag/tag';
import { AutocompleteModule } from '../../../inputs/autocomplete-primeng/autocomplete.module';
import { BaseFilterModalComponent } from '../base-filter-modal.component';

@Component({
    selector: 'vehicle-filter-modal',
    templateUrl: './vehicle-filter-modal.component.html',
    styleUrls: ['./vehicle-filter-modal.component.scss'],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      TranslateModule,
      DropdownModule,
      AutocompleteModule
    ]
})
export class VehicleFilterModalComponent extends BaseFilterModalComponent {

  constructor(
    protected override _toastrService: ToastrService,
    protected override _translateService: TranslateService,
    protected override _centralQueryParamsService: CentralQueryParamsService,
    public override bsModalRef: BsModalRef
  ) {
    super(_toastrService, _translateService, _centralQueryParamsService, bsModalRef);
    
    this.form = new FormGroup({
      vehicleTypeId: new FormControl(null),
      purchaseTypeId: new FormControl(null),
      fuelTypeId: new FormControl(null),

      makeId: new FormControl(null),
      modelId: new FormControl(null),
      versionId: new FormControl(null),

      headquarterId: new FormControl(null),
      locationId: new FormControl(null),

      tagId: new FormControl(null),

      isExit: new FormControl(null),
      isSuspended: new FormControl(null),
      closeOnApply: new FormControl(true),
    });
  }

  onSelectMake(make: Make) {
    this.form.patchValue({ 
      makeId: make.id, 
      makeName: make.name,
      modelId: null,
      modelName: null,
      versionId: null,
      versionName: null,
    });
  }

  onSelectModel(model: Model) {
    this.form.patchValue({ 
      modelId: model.id, 
      modelName: model.name,
      versionId: null,
      versionName: null,
    });
  }

  onSelectVersion(version: VersionModel) {
    this.form.patchValue({ 
      versionId: version.id, 
      versionName: version.name,
    });
  }

  onVehicleTypeSelected($event: DropdownOption) {
    this.form.patchValue({ vehicleTypeId: $event.value });
  }

  onPurchaseTypeSelected($event: DropdownOption) {
    this.form.patchValue({ purchaseTypeId: $event.value });
  }

  onFuelTypeSelected($event: DropdownOption) {
    this.form.patchValue({ fuelTypeId: $event.value });
  }

  onSelectTag(tag: Tag) {
    this.form.patchValue({
      tagId: tag.id,
      tagName: tag.name,
    });
  }

  onSelectedIsExit(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ isExit: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ isExit: false });
    } else {
      this.form.patchValue({ isExit: null });
    }
  }

  onSelectedIsSuspended(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ isSuspended: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ isSuspended: false });
    } else {
      this.form.patchValue({ isSuspended: null });
    }
  }

  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));

    const vehicleType = this.form.controls['vehicleTypeId'].value;
    const purchaseType = this.form.controls['purchaseTypeId'].value;
    const fuelType = this.form.controls['fuelTypeId'].value;
    const make = this.form.controls['makeId'].value;
    const model = this.form.controls['modelId'].value;
    const version = this.form.controls['versionId'].value;
    const headquarter = this.form.controls['headquarterId'].value;
    const location = this.form.controls['locationId'].value;
    const tag = this.form.controls['tagId'].value;
    const isExit = this.form.controls['isExit'].value;
    const isSuspended = this.form.controls['isSuspended'].value;

    if (vehicleType) { 
      this._centralQueryParamsService.addQueryParam(VehicleFilters.VEHICLE_TYPE, vehicleType);
    }
    if (purchaseType) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.PURCHASE_TYPE, purchaseType);
    }
    if (fuelType) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.FUEL_TYPE, fuelType);
    }
    if (make) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.VEHICLE_MAKE, make);
    }
    if (model) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.VEHICLE_MODEL, model);
    }
    if (version) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.VEHICLE_VERSION, version);
    }
    if (headquarter) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.HEADQUARTER, headquarter);
    }
    if (location) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.LOCATION, location);
    }
    if (tag) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.TAG, tag);
    }
    if (isExit != null) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.IS_EXIT, isExit.toString());
    }
    if (isSuspended != null) {
      this._centralQueryParamsService.addQueryParam(VehicleFilters.IS_SUSPENDED, isSuspended.toString());
    }

    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.form.reset();
    }

    this.handleSuccessAndClose();
  }
}