import * as Sentry from "@sentry/angular";
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetsService } from 'src/app/shared/api-services/assets.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { Asset, AssetType } from 'src/app/shared/models/assets/asset';
import { CreateAssetRequest } from 'src/app/shared/models/assets/asset-request';
import { AssetTypesResponse } from 'src/app/shared/models/assets/asset-response';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, switchMap } from "rxjs";
import { Tag } from "src/app/shared/models/tag/tag";
import { TypeaheadMatch, TypeaheadModule } from "ngx-bootstrap/typeahead";
import { TagsService } from "src/app/shared/api-services/tags.service";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { ToastrService } from "ngx-toastr";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { StorageFile } from "src/app/shared/models/storage";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { StorageUtilsService } from "src/app/shared/utilities/storage-utils.servic";
import { SafeUrl } from "@angular/platform-browser";
import { AssetFieldsFilter, AssetFilter } from "src/app/shared/models/assets/asset-filter";
import { CommonModule } from "@angular/common";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { DropdownModule } from "../../dropdown/dropdown.module";
import { OutputFilesComponent } from "src/app/shared/components/file-manage/output-files/output-files.component";
import { InputUploadFilesComponent } from "src/app/shared/components/file-manage/input-upload-files/input-upload-files.component";

@Component({
    selector: 'assets-modal',
    imports: [
        CommonModule,
        TranslateModule,
        DropdownModule,
        FormlyModule,
        FormlyBootstrapModule,
        InputUploadFilesComponent,
        OutputFilesComponent,
        TypeaheadModule,
        ReactiveFormsModule,
    ],
    templateUrl: './assets-modal.component.html',
    styleUrls: ['./assets-modal.component.scss']
})
export class AssetsModalComponent {

  tenantId: string = this._tenantService.getTenantId();

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  @Input() asset: Asset;
  assetRequest: CreateAssetRequest = {};
  typeExtraFields: TypeExtraField[];

  assetForm: FormGroup;
  assetTypesResp: AssetTypesResponse;
  assetType: AssetType;

  tags$: Observable<string[]>;
  selectedTags: Tag[] = [];

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  }

  assetOptionSelected: DropdownOption;
  assetTypesOptions: DropdownOption[] = [];

  fields: FormlyFieldConfig[] = [];

  listDocuments: StorageFile[] = [];
  bucketName: string = 'asset-documents';

  selectedFile: File | null = null;
  previewAvatar!: Observable<SafeUrl>;
  bucketNameImage = 'vehicles-profile-images';

  isDisableAssetType: boolean = false;

  constructor(
    private _tenantService: TenantService,
    private _assetsService: AssetsService,
    private _tagsService: TagsService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _storageUtilsService: StorageUtilsService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) {
    this.assetForm = new FormGroup({
      displayName: new FormControl(null, Validators.required),
      assetTypeId: new FormControl(null, Validators.required),
      headquarterId: new FormControl(),
      code: new FormControl(null, Validators.required),
      tag: new FormControl()
    });
  }

  ngOnInit(): void {

    this.assetOptionSelected = this.defaultOption;

    this.getTaskTypes();
    this.autoComplete();

    this.getAsset();
  }

  getAsset() {
    if (this.asset?.id) {
      let params: AssetFilter = {
        includeHeadquarter: true,
        includeAssetType: true,
        includeTags: true,
      }

      this._assetsService.getAsset$(this.tenantId, this.asset.id, params).subscribe({
        next: response => {
          this.asset = response;
          this.setValueFields();
        },
        error: error => {
          Sentry.captureEvent(error);
        }
      });

      this.getAssetImage();
      this.getAssetFiles();
    }
  }

  getAssetImage() {
    this.previewAvatar = this._assetsService.getAssetImage$(this.tenantId, this.asset.id, 140, 140);
  }

  getTaskTypes() {
    this._assetsService.listAssetTypes$(this.tenantId).subscribe({
      next: (response) => {
        this.assetTypesResp = response;
        const translatedAndMapped = response.items.map(assetType => ({
          value: assetType.id,
          text: this._translateService.instant(assetType.name)
        }));

        this.assetTypesOptions = translatedAndMapped.sort((a, b) => a.text.localeCompare(b.text));
      }
    });
  }

  autoComplete() {
    this.tags$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.assetForm.controls['tag'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTags$({ nameContains: token, limit: 5 })),
      map((response) => {
        let tagNames: string[] = [];
        response.items.forEach((tag) => {
          tagNames.push(tag.name);
        });
        return tagNames;
      }),
    );
  }

  onTagSelected(match: TypeaheadMatch) {
    const tagName = match.value;

    let tagExists = false;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagExists = this.selectedTags.some(tag => tag.name === tagName);
    }

    if (!tagExists) {
      this._tagsService.listTags$({ name: tagName }).subscribe({
        next: (tags) => {
          if (tags.items.length > 0) {
            const tag = tags.items[0];
            this.selectedTags.push(tag);
          }
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

  onHeadquarterSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.assetForm.controls['headquarterId'].setValue($newSelectedOption.value);
    }
  }

  onTaskTypeSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.assetForm.controls['assetTypeId'].setValue($newSelectedOption.value);

      this.cleanExtraFields();
      this.getExtraFields($newSelectedOption);

      this.assetType = this.assetTypesResp.items.find(assetType => assetType.id == $newSelectedOption.value);
    }
  }

  getExtraFields(newSelectedOption: DropdownOption) {
    this._assetsService.listAssetFields$(this.tenantId, { assetTypeId: newSelectedOption.value })
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);
          this.asset = this.asset ? this.asset : {};
          this.asset.extraFields = this.asset.extraFields ? this.asset.extraFields : {};
          this.asset.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.asset.extraFields, false);
          this.fields = this.typeExtraFields.map(field =>
            this._extraFieldsUtilsService.createFieldConfig(field, this.asset.extraFields));
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  setValueFields() {
    if (this.asset?.id) {
      this.modalStatus.isPatch = true;
      this.modalStatus.isCreate = false;

      this.assetForm.patchValue({
        displayName: this.asset.displayName,
        code: this.asset.code,
        assetTypeId: this.asset.assetTypeId,
        headquarterId: this.asset.headquarterId
      });

      if (this.asset.assetType) {
        this.assetOptionSelected = {
          value: this.asset.assetType.id,
          text: this._translateService.instant(this.asset.assetType.name)
        }
        this.isDisableAssetType = true;
        this.getExtraFields(this.assetOptionSelected);
      }

      if (this.asset.tags) {
        this.selectedTags = this.asset.tags;
      }
    }
  }

  getAssetFiles() {
    this._assetsService.listAssetFiles$(this.tenantId, this.asset.id).subscribe({
      next: response => {
        this.listDocuments = response.items.map(assetFile => {
          return {
            id: assetFile.id,
            name: assetFile.name,
            isNew: false,
          };
        });
      },
      error: error => {
        Sentry.captureEvent(error);
      }
    });
  }

  cleanExtraFields() {
    Object.keys(this.assetForm.controls).forEach(key => {
      if (this.fields && this.fields.length > 0) {
        this.fields.forEach(field => {
          if (field.key == key) {
            this.assetForm.removeControl(key);
          }
        });
      }
    });
  }

  removeTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter(tag => tag.id !== tagId);
  }

  addPendingFile($event: StorageFile) {
    let file = $event;
    file.isNew = true;
    this.listDocuments.push(file);
  }

  linkFiles() {
    if (this.listDocuments) {
      const newFiles = this.listDocuments.filter(file => file.isNew);
      newFiles.forEach(file => {
        let request = {
          fileId: file.id,
          name: file.name,
        };

        this._assetsService.linkAssetFile$(this.tenantId, this.asset.id, request).subscribe({
          next: (response) => {
            if (response.status === 200) {
              let message = this._translateService.instant('ASSETS.MESSAGES.SUCCESS_LINK_FILE_USER');
              this._toastrService.info(message);
            }
          },
          error: (error) => {
            this._toastrService.error(error.error.detail, error.error.title);
            Sentry.captureEvent(error);
          },
        });
      });
    }
  }

  downloadFile($event: StorageFile) {
    let file = $event;

    if (this.modalStatus.isPatch) {
      this._assetsService.downloadAssetFile$(this.tenantId, this.asset.id, file.id).subscribe({
        next: (response) => {
          const url = window.URL.createObjectURL(response.body);

          const a = document.createElement('a');
          a.href = url;
          a.download = file.name;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
        error: (error) => {
          if (error.status === 404) {
            this._toastrService.error(error.statusText);
            return;
          }
          this._toastrService.error(error.error.detail, error.error.title);
          Sentry.captureEvent(error);
        },
      });
    }
  }

  removeFile($event: StorageFile) {
    let isNew = this.listDocuments.filter(file => file.id == $event.id && file.isNew);
    this.listDocuments = this.listDocuments.filter(file => file.id !== $event.id); //To remove client side

    if (this.modalStatus.isPatch && isNew.length === 0) {
      this._assetsService.deleteAssetFile$(this.tenantId, this.asset.id, $event.id).subscribe({
        next: (response) => {
          if (response.status === 204) {
            let message = this._translateService.instant('ASSETS.MESSAGES.SUCCESS_DELETE_FILE_USER');
            this._toastrService.info(message);
          }
        },
        error: (error) => {
          this._toastrService.error(error.error.detail, error.error.title);
          Sentry.captureEvent(error);
        },
      });
    }
  }

  onFileSelected($event: Event): void {
    this.selectedFile = this._storageUtilsService.getFileSelect($event);
    this.previewAvatar = this._storageUtilsService.onFileSelected(this.selectedFile);
  }

  onCreateAsset() {
    this.createRequestFromForm();
    let allFieldsFromForm = this.assetForm.value;

    let filter: AssetFieldsFilter = { assetTypeId: this.assetRequest.assetTypeId };
    this._assetsService.listAssetFields$(this.tenantId, filter).pipe(
      switchMap((response) => {
        const assetFields = response.items;

        if (!this.assetRequest.extraFields && assetFields.length > 0) {
          this.assetRequest.extraFields = {};

          assetFields.forEach(field => {
            if (field.name in allFieldsFromForm) {
              this.assetRequest.extraFields[field.name] = allFieldsFromForm[field.name];
            }
          });
        }

        return this._assetsService.createAsset$(this.tenantId, this.assetRequest);
      })
    ).subscribe({
      next: response => {
        this.asset = response;
        if (response.id) {
          this._toastrService.success(this._translateService.instant('ASSETS.MESSAGES.SUCCESS_CREATED'));
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
        else {
          this._toastrService.error(this._translateService.instant('ASSETS.MESSAGES.ERROR_CREATING'));
        }
      },
      error: (error) => {
        Sentry.captureEvent(error);
        this._toastrService.error(error.error.detail, error.error.title);
      }
    });
  }

  onPatchAsset() {
    this.createRequestFromForm();
    let allFieldsFromForm = this.assetForm.value;

    this._assetsService.listAssetFields$(this.tenantId, { assetTypeId: this.assetRequest.assetTypeId }).pipe(
      switchMap((response) => {
        const assetFields = response.items;

        if (!this.assetRequest.extraFields && assetFields.length > 0) {
          this.assetRequest.extraFields = {};

          assetFields.forEach(field => {
            if (field.name in allFieldsFromForm) {
              this.assetRequest.extraFields[field.name] = allFieldsFromForm[field.name];
            }
          });
        }

        return this._assetsService.updateAsset$(this.tenantId, this.asset.id, this.assetRequest);
      })
    ).subscribe({
      next: response => {
        this.asset = response;
        this.linkFiles();
        if (response.id) {
          this._toastrService.success(this._translateService.instant('ASSETS.MESSAGES.SUCCESS_UPDATED'));
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
        else {
          this._toastrService.error(this._translateService.instant('ASSETS.MESSAGES.ERROR_UPDATING'));
        }
      },
      error: (error) => {
        Sentry.captureEvent(error);
        this._toastrService.error(error.error.detail, error.error.title);
      }
    });
  }

  onSubmit() {
    this._storageUtilsService.uploadFile$(this.selectedFile, this.bucketNameImage).subscribe({
      next: (response) => {
        this.assetRequest.imageFileId = response?.body?.id !== undefined ? response.body.id : (this.asset.imageFileId ?? null);

        if (this.asset.id) {
          this.onPatchAsset();
        } else {
          this.onCreateAsset();
        }

      },
      error: error => {
        this._toastrService.error(error.error.detail, error.error.title);
        Sentry.captureEvent(error);
      }
    });
  }

  createRequestFromForm() {
    if (this.assetForm.invalid) {
      return;
    }

    if (this.typeExtraFields.length > 0) {
      this.assetRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.assetForm.value, this.assetRequest);
      this.assetRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.assetRequest.extraFields);
    }

    if (!this.assetRequest.extraFields) {
      this.assetRequest.extraFields = {};
    }

    this.assetRequest.displayName = this.assetForm.controls['displayName'].value ?? null;
    this.assetRequest.assetTypeId = this.assetForm.controls['assetTypeId'].value ?? null;
    this.assetRequest.headquarterId = this.assetForm.controls['headquarterId'].value ?? null;
    this.assetRequest.code = this.assetForm.controls['code'].value ?? null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this.assetRequest.tagIds = this.selectedTags.map(tag => tag.id);
    }

  }

  onCancel() {
    this.bsModalRef.hide();
  }
}
