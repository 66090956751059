<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex justify-content-between align-items-center">
        <div class="d-flex gap-2 align-items-center">
            <i class="bg-icon-orange-light bi bi-bell default-icon p-1"></i>
            <h4 class="modal-title fs-5 pull-left">{{'USER_NOTIFICATION_SETTINGS.TITLE' | translate}}</h4>
        </div>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <div class="modal-gs-body">
        <p>{{'USER_NOTIFICATION_SETTINGS.DESCRIPTION' | translate}}</p>
        <form class="d-flex flex-column gap-2" [formGroup]="form">

            <div class="d-flex gap-4 mb-3">
                <button type="button" class="btn-gs-primary-noborder" (click)="selectAll()">{{'ACTIONS.SELECT_ALL' | translate}}</button>
                <button type="button" class="btn-gs-primary-noborder" (click)="selectNone()">{{'ACTIONS.SELECT_NONE' | translate}}</button>
            </div>

            <div class="d-flex gap-2 align-items-center">
                <i class="bg-icon-primary-light bi bi-people default-icon p-1"></i>
                <h5 class="fs-6 m-0">{{'USER_NOTIFICATION_SETTINGS.USER_NOTIFICATIONS' | translate}}</h5>
            </div>
            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableUserDisabled" formControlName="disableUserDisabled">
                <label for="disableUserDisabled" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_USER_DISABLED' | translate}}</label>
            </div>

            <div class="d-flex gap-2 align-items-center mt-3">
                <i class="bg-icon-primary-light bi bi-bookmarks default-icon p-1"></i>
                <h5 class="fs-6 m-0">{{'USER_NOTIFICATION_SETTINGS.BOOKING_NOTIFICATIONS' | translate}}</h5>                
            </div>
            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableBookingUpdates" formControlName="disableBookingUpdates">
                <label for="disableBookingUpdates" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_BOOKING_UPDATES' | translate}}</label>
            </div>

            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableBookingApprover" formControlName="disableBookingApprover">
                <label for="disableBookingApprover" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_BOOKING_APPROVER' | translate}}</label>
            </div>

            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableBookingOkDelayed" formControlName="disableBookingOkDelayed">
                <label for="disableBookingOkDelayed" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_BOOKING_OK_DELAYED' | translate}}</label>
            </div>

            <div class="d-flex gap-2 align-items-center mt-3">
                <i class="bg-icon-primary-light default-icon icon-task-vectore p-1"></i>
                <h5 class="fs-6 m-0">{{'USER_NOTIFICATION_SETTINGS.TASK_NOTIFICATIONS' | translate}}</h5>
            </div>
            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableTaskAssigned" formControlName="disableTaskAssigned">
                <label for="disableTaskAssigned" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_TASK_ASSIGNED' | translate}}</label>
            </div>

            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableTaskUpdated" formControlName="disableTaskUpdated">
                <label for="disableTaskUpdated" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_TASK_UPDATED' | translate}}</label>
            </div>

            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableTaskExpired" formControlName="disableTaskExpired">
                <label for="disableTaskExpired" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_TASK_EXPIRED' | translate}}</label>
            </div>

            <div class="form-group d-flex gap-2 ms-1">
                <input type="checkbox" class="form-check-input" id="disableTaskExpiration" formControlName="disableTaskExpiration">
                <label for="disableTaskExpiration" class="form-check-label">{{'USER_NOTIFICATION_SETTINGS.DISABLE_TASK_EXPIRATION' | translate}}</label>
            </div>

        </form>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="save()">{{'ACTIONS.SAVE' | translate}}</button>
    </footer>
</div>