import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Layer, Map, MapOptions, Marker, polyline, tileLayer, latLng } from 'leaflet';
import { Subject, takeUntil, tap, forkJoin, map, switchMap, Observable } from 'rxjs';
import { NominatimResponse, NominatimService } from 'src/app/shared/services/nominatim.service';
import { OsrmMapService } from 'src/app/shared/services/osrm-map.service';
import { BookingModalService } from '../booking-modal.service';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { LocationModel as LocationModel, Stage } from 'src/app/shared/models/location/location';
import { BookingRequest } from 'src/app/shared/models/booking/booking-request';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
interface Address {
  name: string;
  latitude: number;
  longitude: number;
}

@Component({
    selector: 'booking-modal-step2',
    templateUrl: './booking-modal-step2.component.html',
    styleUrl: './booking-modal-step2.component.scss',
    standalone: false
})
export class BookingModalStep2Component implements OnInit, OnDestroy {
  form: FormGroup = new FormGroup({});
  storageInformation: BookingRequest = {
    stages: []
  };
  listStage: Stage[] = [];

  map: Map;
  options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 10,
    center: latLng(46.4988821, 11.308806),
    dragging: false,
    touchZoom: false,
    scrollWheelZoom: false,
    doubleClickZoom: false,
    boxZoom: false,
    keyboard: false
  };

  routerLayer: Layer;
  markers: Marker[] = [];

  listAddress: { index: number, address: Address[] }[] = [];

  isMobile: boolean = false;

  private _destroy = new Subject<void>();

  constructor(
    private _bookingModalNewService: BookingModalService,
    private _headquartersService: HeadquartersService,
    private _osrmService: OsrmMapService,
    private _nominatimService: NominatimService,
    private _breakpointObserver: BreakpointObserver
  ) { 
    this._breakpointObserver.observe([Breakpoints.Handset])
      .pipe(takeUntil(this._destroy))
      .subscribe((state: BreakpointState) => {
        this.isMobile = state.matches;
      });
  }

  ngOnInit(): void {
    this._bookingModalNewService.setStep2Component(this);
    this.addStartEndAddress();
    this.setData();

    setTimeout(() => {
      const modalContent = document.querySelector('.card-booking-modal');
      if (modalContent) {
        modalContent.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0); // Fallback
      }
    }, 1000);
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  /**
   * Aggiunge i primi due step (start e end) o tutti gli stage presenti nel bookingRequest
   */
  private addStartEndAddress() {
    if (this._bookingModalNewService.booking?.stages?.length > 1) {
      for (let i = 0; i < this._bookingModalNewService.booking?.stages?.length; i++) {
        this.addAddress();
      }
    }
    else {
      this.addAddress();
      this.addAddress();
    }
  }

  addAddress() {
    let index = this.listAddress.length;
    this.listAddress.push({ index: index, address: [] });
    this.form.addControl(`address${index}`, new FormControl(null, Validators.required));
  }

  removeAddress(index: number) {
    this.listAddress.splice(index, 1);
    this.form.removeControl(`address${index}`);
    this.listStage.splice(index, 1);

    if (this.markers[index]) {
      this.map.removeLayer(this.markers[index]);
    }    
    this.markers.splice(index, 1);
    this.storageInformation.stages.splice(index, 1);
    this.setRoute();
  }

  onClearAddress(index: number) {
    this.listStage[index] = null;
    if (this.markers[index]) {
      this.map.removeLayer(this.markers[index]);
    }
    this.markers[index] = null;
    this.storageInformation.stages[index] = null;
  }

  filterAddress(event: { query: string }, index: number) {
    this._nominatimService.searchLocation(event.query, 5)
      .pipe(takeUntil(this._destroy))
      .subscribe((locations: NominatimResponse[]) => {
        this.listAddress[index].address = locations.map(location => ({
          name: location.display_name,
          latitude: Number(location.lat),
          longitude: Number(location.lon)
        }));
      });
  }

  onSelectAddress($event: AutoCompleteSelectEvent, index: number) {
    let location : Address = $event.value;
    if (this.listStage[index] === undefined || this.listStage[index] === null) {
      this.listStage[index] = {
        latitude: Number(location.latitude),
        longitude: Number(location.longitude)
      };
    } else {
      this.listStage[index].latitude = Number(location.latitude);
      this.listStage[index].longitude = Number(location.longitude);
    }

    if (this.markers[index] === undefined || this.markers[index] === null) {
      this.markers[index] = this._osrmService.setMarker(this.map, Number(location.latitude), Number(location.longitude));
    } else {
      this.markers[index].setLatLng([Number(location.latitude), Number(location.longitude)]);
    }

    if (this.storageInformation.stages[index] === undefined || this.storageInformation.stages[index] === null) {
      this.storageInformation.stages[index] = $event.value.name;
    } else {
      this.storageInformation.stages[index] = $event.value.name;
    }

    this._osrmService.centerViewMap(this.map, Number(location.latitude), Number(location.longitude), 18);

    let lastIndex = this.listAddress.length - 1;
    if (this.listStage[0]?.latitude && this.listStage[0]?.longitude && this.listStage[lastIndex]?.latitude && this.listStage[lastIndex]?.longitude) {
      this.setRoute();
    }
  }

  private setData() {
    let formValue = this._bookingModalNewService.getInformationStep(2);
    if (formValue) {
      this.form.patchValue(formValue);
      this.setFirstStepByLocation();
    } 
    else if (this._bookingModalNewService.booking?.stages?.length > 0) {
      const stages$ = this.getStages$();
      forkJoin(stages$)
      .pipe(
        takeUntil(this._destroy),
        tap(() => {
          this.setRoute();
        })
      )
      .subscribe();
    } 
    else if (this._bookingModalNewService.bookingRequest?.locationId) {
      this.setFirstStepByLocation();
    }
  }

  /**
   * Restituisce un array di observable per ottenere i dati delle località degli stage
   */
  private getStages$() : Observable<NominatimResponse[]>[] {
    this.storageInformation = JSON.parse(JSON.stringify(this._bookingModalNewService.booking));
    return this._bookingModalNewService.booking.stages.map((stage: string, index: number) => {
      this.form.patchValue({
        [`address${index}`]: stage
      });
      return this._nominatimService.searchLocation(stage).pipe(
        takeUntil(this._destroy),
        tap((locations: NominatimResponse[]) => {
          this.listStage.push({
            latitude: Number(locations[0].lat),
            longitude: Number(locations[0].lon)
          });
          this.markers.push(this._osrmService.setMarker(this.map, Number(locations[0].lat), Number(locations[0].lon)));
        })
      );
    });
  }

  /**
   * Imposta il primo step della lista degli stage in base alla locationId passata dal bookingRequest
   */
  private setFirstStepByLocation() {
    this._headquartersService.getLocation$(this._bookingModalNewService.bookingRequest.locationId)
      .pipe(
        takeUntil(this._destroy),
        switchMap((location: LocationModel) => {
          this.form.patchValue({
            address0: location.address
          });

          return this._nominatimService.searchLocation(location.address).pipe(
            takeUntil(this._destroy),
            tap((locations: NominatimResponse[]) => {
              this.listStage[0] = {
                latitude: Number(locations[0].lat),
                longitude: Number(locations[0].lon)
              };
              this.markers[0] = this._osrmService.setMarker(this.map, Number(locations[0].lat), Number(locations[0].lon), location.name);
              this.storageInformation.stages[0] = location.address;
              this._osrmService.centerViewMap(this.map, Number(locations[0].lat), Number(locations[0].lon), 18);
            })
          );
        })
      ).subscribe();
  }


  private setRoute() {
    if (!this.listStage || this.listStage.length < 2) {
      return;
    }
    let getFirstStage = JSON.parse(JSON.stringify(this.listStage[0]));
    let tempListStage = JSON.parse(JSON.stringify(this.listStage)) as Stage[];
    tempListStage.push(getFirstStage);

    const coordinates = tempListStage.filter(stage => stage?.latitude && stage?.longitude).map(stage => {
      return `${stage.longitude},${stage.latitude}`;
    }).join(';');

    if (this.routerLayer) {
      this.map.removeLayer(this.routerLayer);
    }

    this._osrmService.loadRoute$(coordinates)
      .pipe(
        takeUntil(this._destroy),
        tap((response) => {
          if (response.routes.length > 0) {
            this.routerLayer = polyline(response.routes[0].geometry.coordinates.map((coord: [number, number]) => latLng(coord[1], coord[0])), { color: '#00a2bd', weight: 10 });
            this.map.addLayer(this.routerLayer);
            this._osrmService.fitRouteBounds(this.map, response.routes[0].geometry.coordinates);

            const totalDistance = response.routes.reduce((acc, route) => acc + route.distance, 0) / 1000;
            const totalDurationSeconds = response.routes[0].duration;
            const minutes = Math.floor(totalDurationSeconds / 60);
            
            this.storageInformation.expectedMileage = totalDistance;
            this.storageInformation.expectedTime = minutes;
          }
        })
      )
      .subscribe();
  }

  checkFormValidity() : boolean {
    this.form.markAllAsTouched();

    if (!this.storageInformation.expectedMileage) {
      return false;
    }

    if (this.form.valid) {
      this._bookingModalNewService.saveInformationStep(2, this.form.value);
    }
    return this.form.valid;
  }

  updateBookingRequestStep2() {
    this._bookingModalNewService.setBookingRequestStep2(this.storageInformation);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
