import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { HeadquarterAutocompleteComponent } from './headquarter-autocomplete/headquarter-autocomplete.component';
import { LocationAutocompleteComponent } from './location-autocomplete/location-autocomplete.component';
import { MakeAutocompleteComponent } from './make-autocomplete/make-autocomplete.component';
import { ModelAutocompleteComponent } from './model-autocomplete/model-autocomplete.component';
import { VersionAutocompleteComponent } from './version-autocomplete/version-autocomplete.component';
import { TagAutocompleteComponent } from './tag-autocomplete/tag-autocomplete.component';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { AssetAutocompleteComponent } from './asset-autocomplete/asset-autocomplete.component';
import { VehicleLicensePlateAutocompleteComponent } from './vehicle-license-plate-autocomplete/vehicle-license-plate-autocomplete.component';
import { VehicleDisplayNameAutocompleteComponent } from './vehicle-display-name-autocomplete/vehicle-display-name-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AutoCompleteModule,
    HeadquarterAutocompleteComponent,
    LocationAutocompleteComponent,
    MakeAutocompleteComponent,
    ModelAutocompleteComponent,
    VersionAutocompleteComponent,
    TagAutocompleteComponent,
    UserAutocompleteComponent,
    AssetAutocompleteComponent,
    VehicleLicensePlateAutocompleteComponent,
    VehicleDisplayNameAutocompleteComponent
  ],
  exports: [
    HeadquarterAutocompleteComponent,
    LocationAutocompleteComponent,
    MakeAutocompleteComponent,
    ModelAutocompleteComponent,
    VersionAutocompleteComponent,
    TagAutocompleteComponent,
    UserAutocompleteComponent,
    AssetAutocompleteComponent,
    VehicleLicensePlateAutocompleteComponent,
    VehicleDisplayNameAutocompleteComponent
  ]
})
export class AutocompleteModule { } 