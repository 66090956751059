<div class="card-filter-task p-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="task-type" class="form-label">{{'TYPOLOGY' | translate}}</label>
                <dropdown-primary-task-type 
                    [taskTypeId]="form.value.taskTypeId ?? null"
                    [filterTaskType]="{ hasTCO: true }"
                    (onSelectedEvent)="onTaskTypeSelected($event)">                    
                </dropdown-primary-task-type>
            </div>

            <div class="card border px-2 py-3">
                <h5>{{'VEHICLE' | translate}}</h5>

                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-display-name" class="form-label">{{'NAME' | translate}}</label>
                        <vehicle-display-name-autocomplete
                            (onSelectedItem)="onVehicleDisplayNameSelected($event)">
                        </vehicle-display-name-autocomplete>
                    </div>
        
                    <div class="form-group flex-grow-1">
                        <label for="vehicle-license-plate" class="form-label">{{'LICENSE_PLATE' | translate}}</label>
                        <vehicle-license-plate-autocomplete
                            (onSelectedItem)="onVehicleLicensePlateSelected($event)">
                        </vehicle-license-plate-autocomplete>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-wrap gap-2">
                <div class="form-group flex-grow-1">
                    <label for="user" class="form-label">{{'USER' | translate}}</label>
                    <user-autocomplete
                        (onSelectedItem)="onUserSelected($event)">
                    </user-autocomplete>
                </div>
    
                <div class="form-group flex-grow-1">
                    <label for="asset" class="form-label">{{'ASSET' | translate}}</label>
                    <asset-autocomplete
                        (onSelectedItem)="onAssetSelected($event)">
                    </asset-autocomplete>
                </div>
            </div>


            <div class="form-group">
                <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}}</label>
                <headquarter-autocomplete
                    (onSelectedItem)="onHeadquarterSelected($event)">
                </headquarter-autocomplete>
            </div>

            <div class="form-group">
                <label for="tag" class="form-label">{{'TAG' | translate}}</label>
                <tag-autocomplete
                    (onSelectedItem)="onTagSelected($event)">
                </tag-autocomplete>
            </div>

            <div class="d-flex flex-wrap gap-2">
                <div class="form-group flex-grow-1">
                    <label for="start-user-task" class="form-label">{{'START_USER_TASK' | translate}}</label>
                    <user-autocomplete
                        (onSelectedItem)="onStartUserSelected($event)">
                    </user-autocomplete>
                </div>
    
                <div class="form-group flex-grow-1">
                    <label for="assigned-user" class="form-label">{{'ASSIGNED_USER' | translate}}</label>
                    <user-autocomplete
                        (onSelectedItem)="onAssignedUserSelected($event)">
                    </user-autocomplete>
                </div>
            </div>


            <div class="card border px-2 py-3 d-flex flex-column gap-2">
                <h5>{{'FILTERS_HAS' | translate}}</h5>
                
                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-1">
                        <label class="form-label">{{'HAS_EXPIRATION_DATE' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasExpirationDate($event)"></dropdown-primary-active>
                    </div>
    
                    <div class="form-group flex-1">
                        <label class="form-label">{{'HAS_EXPIRATION_MILEAGE' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasExpirationMileage($event)"></dropdown-primary-active>
                    </div>
                </div>

                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label class="form-label">{{'HAS_COMMENTS' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasComments($event)"></dropdown-primary-active>
                    </div>
    
                    <div class="form-group flex-grow-1">
                        <label class="form-label">{{'HAS_ASSIGNED_USER' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasAssignedUser($event)"></dropdown-primary-active>
                    </div>
                </div>

                <div class="d-flex flex-wrap gap-2">
                    <div class="form-group flex-grow-1">
                        <label class="form-label">{{'HAS_VEHICLE' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasVehicle($event)"></dropdown-primary-active>
                    </div>
    
                    <div class="form-group flex-grow-1">
                        <label class="form-label">{{'HAS_USER' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasUser($event)"></dropdown-primary-active>
                    </div>
    
                    <div class="form-group flex-grow-1">
                        <label class="form-label">{{'HAS_ASSET' | translate}}</label>
                        <dropdown-primary-active (onSelectedEvent)="onSelectedHasAsset($event)"></dropdown-primary-active>
                    </div>
                </div>
            </div>

            <div class="form-group d-flex gap-2">
                <input type="checkbox" class="form-check-input" id="closeOnApply" formControlName="closeOnApply">
                <label for="closeOnApply" class="form-check-label">{{'CLOSE_FILTER_ON_APPLY' | translate}}</label>
            </div>

        </form>
    </content>

    <footer class="d-flex gap-2 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="cancel()">{{'ACTIONS.CANCEL' | translate}}</button>
        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
