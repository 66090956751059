export const VEHICLE_TYPE = "vehicleTypeId";
export const PURCHASE_TYPE = "purchaseTypeId";
export const FUEL_TYPE = "fuelTypeId";
export const VEHICLE_MODEL = "modelId";
export const VEHICLE_MAKE = "makeId";
export const VEHICLE_VERSION = "versionId";
export const HEADQUARTER = "headquarterId";
export const LOCATION = "locationId";
export const TAG = "tagId";
export const IS_EXIT = "isExit";
export const IS_SUSPENDED = "isSuspended";