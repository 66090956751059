import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { AutocompleteModule } from '../../../inputs/autocomplete-primeng/autocomplete.module';
import { UserModel } from 'src/app/shared/models/user/users';
import { ToastrService } from 'ngx-toastr';
import * as BookingFilters from 'src/app/shared/constant/params/booking-filters';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { BaseFilterModalComponent } from '../base-filter-modal.component';

@Component({
    selector: 'booking-filter-modal',
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
        AutocompleteModule
    ],
    templateUrl: './booking-filter-modal.component.html',
    styleUrls: ['./booking-filter-modal.component.scss']
})
export class BookingFilterModalComponent extends BaseFilterModalComponent {
  @Input() showUser: boolean = true;

  constructor(
    protected override _toastrService: ToastrService,
    protected override _translateService: TranslateService,
    protected override _centralQueryParamsService: CentralQueryParamsService,
    public override bsModalRef: BsModalRef
  ) {
    super(_toastrService, _translateService, _centralQueryParamsService, bsModalRef);
    
    this.form = new FormGroup({
      bookingTypeId: new FormControl(null),
      vehicleId: new FormControl(null),
      userId: new FormControl(null),

      headquarterId: new FormControl(null),
      locationId: new FormControl(null),

      insertedByUserId: new FormControl(null),
      approvedByUserId: new FormControl(null),
      rejectedByUserId: new FormControl(null),
      
      isFringe: new FormControl(null),
      status: new FormControl(null),
      closeOnApply: new FormControl(true),
    });
  }

  onBookingTypeSelected($event: DropdownOption) {
    this.form.patchValue({ bookingTypeId: $event.value });
  }

  onInsertedByUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        insertedByUserId: user.id
      });
    }
  }

  onApprovedByUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        approvedByUserId: user.id
      });
    }
  }

  onRejectedByUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        rejectedByUserId: user.id
      });
    }
  }

  onSelectedIsFringe(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ isFringe: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ isFringe: false });
    } else {
      this.form.patchValue({ isFringe: null });
    }
  }

  onSelectedStatus(optionSelected: DropdownOption) {
    this.form.patchValue({ status: optionSelected.value });
  }

  apply() {
    const bookingTypeId = this.form.controls['bookingTypeId'].value;
    const vehicleId = this.form.controls['vehicleId'].value;
    const userId = this.form.controls['userId'].value;
    const headquarterId = this.form.controls['headquarterId'].value;
    const locationId = this.form.controls['locationId'].value;
    const insertedByUserId = this.form.controls['insertedByUserId'].value;
    const approvedByUserId = this.form.controls['approvedByUserId'].value;
    const rejectedByUserId = this.form.controls['rejectedByUserId'].value;
    const isFringe = this.form.controls['isFringe'].value;
    const status = this.form.controls['status'].value;

    if (bookingTypeId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.BOOKING_TYPE, bookingTypeId);
    }
    if (vehicleId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.VEHICLE, vehicleId);
    }
    if (userId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.USER, userId);
    }
    if (headquarterId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.HEADQUARTER, headquarterId);
    }
    if (locationId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.LOCATION, locationId);
    }
    if (insertedByUserId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.INSERTED_BY_USER, insertedByUserId);
    }
    if (approvedByUserId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.APPROVED_BY_USER, approvedByUserId);
    }
    if (rejectedByUserId) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.REJECTED_BY_USER, rejectedByUserId);
    }
    if (isFringe != null) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.IS_FRINGE, isFringe.toString());
    }
    if (status) {
      this._centralQueryParamsService.addQueryParam(BookingFilters.STATUS, status);
    }

    this.handleSuccessAndClose();
  }
}
