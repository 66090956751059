import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CreateTaskRequest } from 'src/app/shared/models/task/task-request';

@Component({
    selector: 'task-end-mileage',
    imports: [
      TranslateModule,
      ReactiveFormsModule,
    ],
    template: `
      <form [formGroup]="form">
        <div class="form-group">
            <label class="form-label" for="mileage">{{'TASKS.MODAL_EDIT_TASK.INSERT_MILEAGE' | translate}}</label>
            <div class="input-group">
                <input type="number" class="form-control" formControlName="mileage" />
                <span class="input-group-text">{{'TASKS.MODAL_EDIT_TASK.MILEAGE' | translate}}</span>
            </div>
            <span class="form-text">{{'TASKS.MODAL_EDIT_TASK.MILEAGE_INFO' | translate}}</span>
        </div>
      </form>
    `,
})
export class TaskEndMileageComponent implements OnInit, OnDestroy {  
  @Input() taskRequest: CreateTaskRequest;
  @Output() taskRequestChange = new EventEmitter<CreateTaskRequest>();
  
  form: FormGroup;
  private _destroy$: Subject<void> = new Subject<void>();
  
  constructor() {
    this.form = new FormGroup({
      mileage: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.form.patchValue({
      mileage: this.taskRequest.endMileage,
    });

    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.taskRequestChange.emit({
        ...this.taskRequest,
        endMileage: value.mileage,
      });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
