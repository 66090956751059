<div class="dynamic-modal">
    <div class="dynamic-modal-header">
        <h4 class="modal-title fs-5 pull-left">{{'TASKS.MODAL_NEW_TASK.TITLE' | translate}}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </div>
    <div class="dynamic-modal-body mb-4">
        <form class="dynamic-form" [formGroup]="taskForm">

            <div class="form-group">
                <label for="taskTitle" class="form-label">{{'TASKS.MODAL_NEW_TASK.NAME_TO_VIEW' | translate}}</label>
                <input type="text" class="form-control" id="taskTitle" formControlName="taskTitle">
            </div>

            <div class="form-group">
                <label for="taskType" class="form-label">{{'TASKS.MODAL_NEW_TASK.TYPE' | translate}} <span>*</span></label>
                <dropdown-primary-task-type 
                    [filterTaskType]="{ hasTCO: hasTCO }"
                    (onSelectedEvent)="onTaskTypeSelected($event)">                    
                </dropdown-primary-task-type>
            </div>

            <div class="form-group">
                <label for="taskType" class="form-label">{{'HEADQUARTER.NAME' | translate}} <span>*</span></label>
                <dropdown-primary-headquarter
                    [headquarterId]="headquarterId ?? null"
                    (onSelectedEvent)="onHeadquarterSelected($event)">                    
                </dropdown-primary-headquarter>
            </div>

            <div class="container-interest d-flex flex-column gap-2" *ngIf="taskForm.get('headquarterId')?.value && taskForm.get('taskTypeId')?.value">
                <h4 class="fs-5 mt-3">{{'TASKS.MODAL_NEW_TASK.INTEREST' | translate}}</h4>
                <div class="form-group">
                    <label for="asset" class="form-label">
                        {{'TASKS.MODAL_NEW_TASK.ASSET' | translate}}
                        <span class="text-danger" *ngIf="taskType?.assetRequired">*</span>
                    </label>
                    <input 
                        type="search"
                        formControlName="assetName"
                        [isAnimated]="true"
                        [typeahead]="assetNames$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        class="form-control">                        
                </div>
                <div class="form-group">
                    <label for="user" class="form-label">
                        {{'TASKS.MODAL_NEW_TASK.USER' | translate}}
                        <span class="text-danger" *ngIf="taskType?.userRequired">*</span>
                    </label>
                    <input 
                        type="search"
                        formControlName="userName"
                        [isAnimated]="true"
                        [typeahead]="userNames$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        class="form-control">
                </div>

                <div class="form-group">
                    <label for="vehicle" class="form-label">
                        {{'TASKS.MODAL_NEW_TASK.VEHICLE' | translate}}
                        <span class="text-danger" *ngIf="taskType?.vehicleRequired">*</span>
                    </label>
                    <input 
                        type="search"
                        formControlName="vehicleDisplayName"
                        [isAnimated]="true"
                        [typeahead]="vehicleDisplayName$"
                        [typeaheadAsync]="true"
                        [typeaheadMinLength]="0"
                        class="form-control">
                </div>

            </div>

            <div class="container-date" *ngIf="taskType?.hasUsage">
                <h4 class="fs-5 mt-3">{{'TASKS.MODAL_NEW_TASK.VALIDITY_PERIOD' | translate}}</h4>

                <div class="row">
                    <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                        <label for="usageStartDate" class="form-label">{{'START_DATE' | translate}}</label>
    
                        <div class="input-group d-flex align-items-center border rounded">
                            
                            <input type="text" class="form-control expiration-date border-0" id="usageStartDate" [placeholder]="'SELECT_DATE' | translate"
                                bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placement="top" formControlName="usageStartDate" 
                               *ngIf="!isMobile; else inputUsageStartDateMobile">

                            <ng-template #inputUsageStartDateMobile>
                                <input type="date" class="form-control" id="usageStartDate" [placeholder]="'SELECT_DATE' | translate"
                                    formControlName="usageStartDate" >
                            </ng-template>
    
                            <i class="icon bi bi-calendar-check text-black-50"></i>
                        </div>
                        
                    </div>

                    <div class="col-sm-12 col-md-6 col-xl-6 form-group">
                        <label for="usageEndDate" class="form-label">{{'END_DATE' | translate}}</label>
    
                        <div class="input-group d-flex align-items-center border rounded">
                            
                            <input type="text" class="form-control expiration-date border-0" id="usageEndDate" [placeholder]="'SELECT_DATE' | translate"
                                bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placement="top" formControlName="usageEndDate" 
                               *ngIf="!isMobile; else inputUsageEndDateMobile">

                            <ng-template #inputUsageEndDateMobile>
                                <input type="date" class="form-control" id="usageEndDate" [placeholder]="'SELECT_DATE' | translate"
                                    formControlName="usageEndDate" >
                            </ng-template>
    
                            <i class="icon bi bi-calendar-check text-black-50"></i>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="fields?.length > 0">
                <h4 class="fs-5 mt-3">{{'TASKS.MODAL_NEW_TASK.INFORMATION' | translate}}</h4>
                <div class="mt-3">
                    <formly-form [form]="taskForm" [fields]="fields"></formly-form>
                </div>
            </div>

        </form>
    </div>  
    <div class="dynamic-modal-footer">
        <button class="btn-gs-secondary" (click)="bsModalRef.hide()">{{'ACTIONS.CANCEL' | translate}}</button>
        <button class="btn-gs-primary" type="submit" [disabled]="taskForm.invalid" (click)="onCreateTask()">{{'ACTIONS.NEW' | translate}}</button>
    </div>
</div>