import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from '../../../dropdown/dropdown.module';
import { ToastrService } from 'ngx-toastr';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { AutocompleteModule } from '../../../inputs/autocomplete-primeng/autocomplete.module';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { UserModel } from 'src/app/shared/models/user/users';
import { Asset } from 'src/app/shared/models/assets/asset';
import { Headquarter } from 'src/app/shared/models/headquarter/headquarter';
import { Tag } from 'src/app/shared/models/tag/tag';
import * as CostFilters from 'src/app/shared/constant/params/cost-filters';

@Component({
    selector: 'filter-cost-modal',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        DropdownModule,
        AutocompleteModule
    ],
    templateUrl: './filter-cost-modal.component.html',
    styleUrls: ['./filter-cost-modal.component.scss']
})

export class FilterCostModalComponent implements OnInit, OnDestroy {
  form: FormGroup;

  private _destroy$ = new Subject<void>();

  constructor(
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _centralQueryParamsService: CentralQueryParamsService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      taskTypeId: new FormControl(null),

      vehicleId: new FormControl(null),
      vehicleDisplayName: new FormControl(null),
      vehicleLicensePlate: new FormControl(null),

      userId: new FormControl(null),
      userDisplayName: new FormControl(null),

      assetId: new FormControl(null),
      assetDisplayName: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      tagId: new FormControl(null),
      tagName: new FormControl(null),

      startUserId: new FormControl(null),
      startUserDisplayName: new FormControl(null),

      assignedUserId: new FormControl(null),
      assignedUserDisplayName: new FormControl(null),

      hasExpirationDate: new FormControl(null),
      hasExpirationMileage: new FormControl(null),
      hasComments: new FormControl(null),
      hasAssignedUser: new FormControl(null),
      hasVehicle: new FormControl(null),
      hasUser: new FormControl(null),
      hasAsset: new FormControl(null),

      closeOnApply: new FormControl(true),
    });
  }

  ngOnInit() {
    // Non è più necessario inizializzare gli observable per l'autocomplete
  }

  onTaskTypeSelected($event: DropdownOption) {
    this.form.patchValue({ taskTypeId: $event.value });
  }

  onVehicleDisplayNameSelected(vehicle: Vehicle) {
    if (vehicle) {
      this.form.patchValue({
        vehicleId: vehicle.id,
        vehicleDisplayName: vehicle.displayName,
        vehicleLicensePlate: vehicle.licensePlate
      });
    }
  }

  onVehicleLicensePlateSelected(vehicle: Vehicle) {
    if (vehicle) {
      this.form.patchValue({
        vehicleId: vehicle.id,
        vehicleDisplayName: vehicle.displayName,
        vehicleLicensePlate: vehicle.licensePlate
      });
    }
  }

  onUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        userId: user.id,
        userDisplayName: user.displayName
      });
    }
  }

  onAssetSelected(asset: Asset) {
    if (asset) {
      this.form.patchValue({
        assetId: asset.id,
        assetDisplayName: asset.displayName
      });
    }
  }

  onHeadquarterSelected(headquarter: Headquarter) {
    if (headquarter) {
      this.form.patchValue({
        headquarterId: headquarter.id,
        headquarterName: headquarter.name
      });
    }
  }

  onTagSelected(tag: Tag) {
    if (tag) {
      this.form.patchValue({
        tagId: tag.id,
        tagName: tag.name
      });
    }
  }

  onStartUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        startUserId: user.id,
        startUserDisplayName: user.displayName
      });
    }
  }

  onAssignedUserSelected(user: UserModel) {
    if (user) {
      this.form.patchValue({
        assignedUserId: user.id,
        assignedUserDisplayName: user.displayName
      });
    }
  }

  onSelectedHasExpirationDate(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasExpirationDate: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasExpirationDate: false });
    } else {
      this.form.patchValue({ hasExpirationDate: null });
    }
  }

  onSelectedHasExpirationMileage(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasExpirationMileage: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasExpirationMileage: false });
    } else {
      this.form.patchValue({ hasExpirationMileage: null });
    }
  }

  onSelectedHasComments(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasComments: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasComments: false });
    } else {
      this.form.patchValue({ hasComments: null });
    }
  }

  onSelectedHasAssignedUser(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasAssignedUser: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasAssignedUser: false });
    } else {
      this.form.patchValue({ hasAssignedUser: null });
    }
  }

  onSelectedHasVehicle(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasVehicle: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasVehicle: false });
    } else {
      this.form.patchValue({ hasVehicle: null });
    }
  }

  onSelectedHasUser(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasUser: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasUser: false });
    } else {
      this.form.patchValue({ hasUser: null });
    }
  }

  onSelectedHasAsset(optionSelected: DropdownOption) {
    if (optionSelected.value === 'active') {
      this.form.patchValue({ hasAsset: true });
    } else if (optionSelected.value === 'inactive') {
      this.form.patchValue({ hasAsset: false });
    } else {
      this.form.patchValue({ hasAsset: null });
    }
  }
  
  apply() {
    this._toastrService.success(this._translateService.instant('FILTER_APPLYED'));

    const taskTypeId = this.form.controls['taskTypeId'].value;
    const vehicleId = this.form.controls['vehicleId'].value;
    const userId = this.form.controls['userId'].value;
    const assetId = this.form.controls['assetId'].value;
    const headquarterId = this.form.controls['headquarterId'].value;
    const tagId = this.form.controls['tagId'].value;
    const startUserId = this.form.controls['startUserId'].value;
    const assignedUserId = this.form.controls['assignedUserId'].value;
    const hasExpirationDate = this.form.controls['hasExpirationDate'].value;
    const hasExpirationMileage = this.form.controls['hasExpirationMileage'].value;
    const hasComments = this.form.controls['hasComments'].value;
    const hasAssignedUser = this.form.controls['hasAssignedUser'].value;
    const hasVehicle = this.form.controls['hasVehicle'].value;
    const hasUser = this.form.controls['hasUser'].value;
    const hasAsset = this.form.controls['hasAsset'].value;

    if (taskTypeId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.TASK_TYPE, taskTypeId);
    }
    if (vehicleId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.VEHICLE, vehicleId);
    }
    if (userId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.USER, userId);
    }
    if (assetId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.ASSET, assetId);
    }
    if (headquarterId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HEADQUARTER, headquarterId);
    }
    if (tagId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.TAG, tagId);
    }
    if (startUserId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.START_USER, startUserId);
    }
    if (assignedUserId) {
      this._centralQueryParamsService.addQueryParam(CostFilters.ASSIGNED_USER, assignedUserId);
    }
    if (hasExpirationDate !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_EXPIRATION_DATE, hasExpirationDate.toString());
    }
    if (hasExpirationMileage !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_EXPIRATION_MILEAGE, hasExpirationMileage.toString());
    }
    if (hasComments !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_COMMENTS, hasComments.toString());
    }
    if (hasAssignedUser !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_ASSIGNED_USER, hasAssignedUser.toString());
    }
    if (hasVehicle !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_VEHICLE, hasVehicle.toString());
    }
    if (hasUser !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_USER, hasUser.toString());
    }
    if (hasAsset !== null) {
      this._centralQueryParamsService.addQueryParam(CostFilters.HAS_ASSET, hasAsset.toString());
    }

    if (this.form.controls['closeOnApply'].value) {
      this.bsModalRef.hide();
      this.form.reset();
    }
  }

  cancel() {
    this.form.reset();
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}