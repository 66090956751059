import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'button-choose',
    imports: [
        CommonModule,
        TranslateModule,
    ],
    templateUrl: './button-choose.component.html',
    styleUrls: ['./button-choose.component.scss']
})
export class ButtonChooseComponent {

  @Input() inputId: string;

  @Input() isChoose: boolean = false;
  @Output() isChooseChange = new EventEmitter<InputOption>();

  @Input() isDisabled: boolean = false;
  @Input() readonly: boolean = false;

  emitChoose() {
    
    if (this.isChoose) {
      this.isChoose = false;
    }
    else {
      this.isChoose = true;
    }
    
    this.isChooseChange.emit({ id: this.inputId, isChoose: this.isChoose });
  }

}

export interface InputOption {
  id: string;
  isChoose: boolean;
}
