<div class="container-vehicle" *ngFor="let vehicle of vehiclesToShow" [ngClass]="{'isCheckedVehicle': vehicle.vehicle.isChecked}">
    <div class="avatar-placeholder-sm vehicle-placeholder">
        <img *ngIf="vehicle.vehicle.srcImage$ | async as vehicleImageUrl" [src]="vehicleImageUrl" alt="vehicle-image">
    </div>
    <div class="vehicle-content" [id]="vehicle.vehicle.id">

        <div class="vehicle-content-header">
            <div class="license-plate">{{vehicle.vehicle.licensePlate}} <badge-booking [number]="vehiclesToShow.indexOf(vehicle) + 1"></badge-booking></div>
            <div class="mileage" *ngIf="vehicle.vehicle?.mileage">{{vehicle.vehicle.mileage}} km</div>
        </div>

        <div class="vehicle-content-make-model d-flex gap-2">
            <div class="vehicle-make">{{vehicle.vehicle.vehicleMake?.name}}</div>
            <div class="vehicle-model">{{vehicle.vehicle.vehicleModel?.name}}</div>
        </div>

        <div class="vehicle-version">{{vehicle.vehicle.vehicleVersion?.name}}</div>

        <div class="vehicle-content-important">
            <div class="vehicle-type">{{vehicle.vehicle.vehicleType?.name ?? 'VEHICLE_TYPE_NOT_PRESENT' | translate }}</div>
            •
            <div class="vehicle-fuel-type">{{vehicle.vehicle.fuelType?.name ?? 'VEHICLE_FUEL_TYPE_NOT_PRESENT' | translate}}</div>
        </div>

        <div class="vehicle-content-actions">                                
            <button-choose [inputId]="vehicle.vehicle.id" [isChoose]="vehicle.vehicle.isChecked" (isChooseChange)="changeVehicleChecked($event)"></button-choose>
        </div>
    </div>
</div>