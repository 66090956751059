import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { ModalUtilsService } from '../../shared/utilities/modal-utils.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Modals from 'src/app/shared/constant/params/modals';
import { TypeImporters } from 'src/app/shared/components/modals/import-modal/upload-import-modal/type-importers';

@Injectable({
  providedIn: 'root'
})
export class CentralQueryParamsService {

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _modalUtilsService: ModalUtilsService,
    private _router: Router,
  ) {
    this.subscribeToQueryParams();
  }

  subscribeFilter<T>(
    filterKey: string,
    serviceCall: (id: string) => Observable<T>,
    assignResult: (result: T | null) => void,
    assignId: (id: string) => void
  ): void {
    this.getQueryParam$(filterKey)
      .pipe(
        switchMap((id: string) => {
          if (id) {
            assignId(id);
            return serviceCall(id);
          }
          return of(null);
        })
      )
      .subscribe((result: T | null) => {
        assignResult(result);
      });
  }

  getQueryParam$(key: string) : Observable<string | null> {
    return this._activatedRoute.queryParams.pipe(
      map((params: Params) => params[key] || null)
    );
  }

  addQueryParam(key: string, value: string) : void {
    this._router.navigate([], {
      queryParams: { [key]: value },
      queryParamsHandling: 'merge'
    });
  }

  removeQueryParam(key: string) : void {
    this._router.navigate([], {
      queryParams: { [key]: null },
      queryParamsHandling: 'merge'
    });
  }

  removeAllQueryParams() : void {
    this._router.navigate([], {
      queryParams: {}
    });
  }

  private subscribeToQueryParams() {
    this._activatedRoute.queryParams.subscribe(params => {
      const taskId = params[Modals.EDIT_TASK_MODAL] as string;
      if (taskId) {
        this.openEditTaskModal(taskId);
      }

      const bookingId = params[Modals.FRINGE_BENEFIT_MODAL] as string;
      if (bookingId) {
        this.openEditFringeBenefitModal(bookingId);
      }

      const createTaskModal = params[Modals.CREATE_TASK_MODAL] as string;
      if (createTaskModal) {
        this.openCreateTaskModal();
      }

      const createMyBookingModal = params[Modals.CREATE_MY_BOOKING_MODAL] as string;
      if (createMyBookingModal) {
        this.openCreateBookingModal();
      }

      const assistanceModal = params[Modals.ASSISTANCE_MODAL] as string;
      if (assistanceModal) {
        let subscription = this._modalUtilsService.openAssistanceModal().pipe(
          switchMap(modal => this.handleModalEvent(modal, Modals.ASSISTANCE_MODAL))
        ).subscribe({
          complete: () => {
            subscription.unsubscribe();
          }
        });
      }

      const importModal = params[Modals.IMPORT_MODAL] as string;
      if (importModal === TypeImporters.Vehicles) {
        let subscription = this._modalUtilsService.openCreateImportModal(TypeImporters.Vehicles).pipe(
          switchMap(modal => this.handleModalEvent(modal, Modals.IMPORT_MODAL))
        ).subscribe({
          complete: () => {
            subscription.unsubscribe();
          }
        });
      }
    });
  }

  private openCreateTaskModal() {
    let subscription = this._modalUtilsService.openCreateTaskModal().pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_TASK_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditTaskModal(taskId: string) {
    let subscription = this._modalUtilsService.openEditTaskModal(taskId).pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_TASK_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openEditFringeBenefitModal(bookingId: string) {
    let subscription = this._modalUtilsService.openFringeBenefitModal(bookingId).pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.FRINGE_BENEFIT_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private openCreateBookingModal() {
    let subscription = this._modalUtilsService.openBookingModal().pipe(
      switchMap(modal => this.handleModalEvent(modal, Modals.CREATE_MY_BOOKING_MODAL))
    ).subscribe({
      complete: () => {
        subscription.unsubscribe();
      }
    });
  }

  private handleModalEvent(modal: BsModalRef<any>, key: string) {
    if (!modal) {
      return of(null);
    }

    return modal.onHide.pipe(
      tap(() => {
        this._router.navigate([], {
          queryParams: { [key]: null }
        })
      }));
  }

}
