import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { InputDatePickerComponent } from '../../inputs/input-date-picker/input-date-picker.component';

@Component({
    selector: 'card-task-usage-date',
    imports: [
        CommonModule,
        TranslateModule,
        InputDatePickerComponent,
        ReactiveFormsModule
    ],
    templateUrl: './card-task-usage-date.component.html',
    styleUrls: ['./card-task-usage-date.component.scss']
})
export class CardTaskUsageDateComponent implements OnInit {
  @Input() startDateUsage: string;
  @Input() endDateUsage: string;

  @Input() isMobile: boolean = false;

  @Output() dateUsageChange = new EventEmitter<CardTaskUsageDate>();

  form: FormGroup;

  isEditMode: boolean = false;

  constructor() {
    this.form = new FormGroup({
      startDateUsage: new FormControl(),
      endDateUsage: new FormControl()
    });
  }

  ngOnInit() {
    this.form.get('startDateUsage')!.valueChanges.subscribe(() => this.validateDates());
    this.form.get('endDateUsage')!.valueChanges.subscribe(() => this.validateDates());

    if (this.startDateUsage) {
      let startDateUsage = new Date(this.startDateUsage);
      this.form.get('startDateUsage')!.setValue(startDateUsage);
    }

    if (this.endDateUsage) {
      let endDateUsage = new Date(this.endDateUsage);
      this.form.get('endDateUsage')!.setValue(endDateUsage);
    }
  }

  validateDates() {
    let startDateUsage = this.form.get('startDateUsage')!.value;
    let endDateUsage = this.form.get('endDateUsage')!.value;

    if (endDateUsage && !startDateUsage) {
      this.form.get('startDateUsage')!.setErrors({ 'invalidDateRange': true });
    }

    if (startDateUsage && endDateUsage) {
      if (startDateUsage > endDateUsage) {
        this.form.get('endDateUsage')!.setErrors({ 'invalidDateRange': true });
      }
    }
  }

  editMode() {
    this.isEditMode = true;
  }

  cancel() {
    this.isEditMode = false;
  }

  onSumit() {
    this.isEditMode = false;
    if (this.form.invalid) {
      return;
    }

    this.startDateUsage = this.form.value.startDateUsage;
    this.endDateUsage = this.form.value.endDateUsage;

    this.dateUsageChange.emit({
      startDateUsage: this.form.value.startDateUsage,
      endDateUsage: this.form.value.endDateUsage
    });
  }
}

export interface CardTaskUsageDate {
  startDateUsage: string;
  endDateUsage: string;
}
