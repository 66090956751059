import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TracksTableComponent } from '../../table/tracks-table/tracks-table.component';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { Subject, takeUntil } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'tracks-modal',
    imports: [
        TracksTableComponent,
        TranslateModule
    ],
    template: `
    <div class="dynamic-modal">
      <div class="dynamic-modal-header d-flex justify-content-between align-items-center p-3">
        <h4 class="modal-title fs-5 pull-left">{{ 'TRACKS_MODAL.TITLE' | translate: { vehicle: vehicleDisplayName } }}</h4>
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
      </div>
      <tracks-table [vehicleId]="vehicleId" (showHoverMapEvent)="hideModal()"></tracks-table>
    </div>
  `,
    styleUrl: './tracks-modal.component.scss'
})
export class TracksModalComponent implements OnInit, OnDestroy {
  @Input() vehicleId: string;
  tenantId: string;
  vehicleDisplayName: string;

  private _destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService
  ) {
    this.tenantId = this._tenantService.getTenantId();
  }

  ngOnInit() {
    this._vehiclesService.getVehicle$(this.tenantId, this.vehicleId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((vehicle) => {
        this.vehicleDisplayName = vehicle.displayName;
      });
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
