import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, tap } from 'rxjs';
import { CommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { InvoiceFileRequest, InvoiceRequest } from '../models/invoice/invoice-request';
import { Invoice, InvoiceFile } from '../models/invoice/invoice';
import { InvoiceResponse, InvoiceDataResponse, InvoiceFieldResponse, InvoiceFileResponse } from '../models/invoice/invoice-response';
import { InvoiceFilter, InvoicesFilter } from '../models/invoice/invoice-filter';
import { MappingModel } from '../models/user/users';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  listInvoiceFields$(tenantId: string): Observable<InvoiceFieldResponse> {
    return this._httpClient.get<InvoiceFieldResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/invoice-fields`);
  }

  createInvoice$(tenantId: string, invoice: InvoiceRequest): Observable<Invoice> {
    return this._httpClient.post<Invoice>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices`, invoice)
      .pipe(
        tap({
          next: response => {
            if (response) {
              this._toasterService.success(this._translateService.instant("INVOICES.MESSAGES.INVOICE_CREATED"));
            }
          },
          error: error => {
            this._toasterService.error(this._translateService.instant("INVOICES.MESSAGES.INVOICE_CREATED_ERROR"));
            this._commonService.handleError(error, false)
          }
        })
      );
  }

  listInvoices$(tenantId: string, params?: InvoicesFilter): Observable<InvoiceResponse> {
    return this._httpClient.get<InvoiceResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices`,
      { params: this._commonService.buildParams(params) });
  }

  getInvoice$(tenantId: string, invoiceId: string, params?: InvoiceFilter): Observable<Invoice> {
    return this._httpClient.get<Invoice>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  updateInvoice$(tenantId: string, invoiceId: string, invoice: InvoiceRequest): Observable<Invoice> {
    return this._httpClient.patch<Invoice>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}`, invoice)
      .pipe(
        tap({
          next: response => {
            if (response) {
              this._toasterService.success(this._translateService.instant("INVOICES.MESSAGES.INVOICE_UPDATED"));
            }
          },
          error: error => {
            this._toasterService.error(this._translateService.instant("INVOICES.MESSAGES.INVOICE_UPDATED_ERROR"));
            this._commonService.handleError(error, false)
          }
        })
      );
  }

  deleteInvoice$(tenantId: string, invoiceId: string): Observable<HttpResponse<Object>> {
    return this._httpClient.delete(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}`,
      { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant("INVOICES.MESSAGES.INVOICE_DELETED_ERROR"));
            this._commonService.handleError(error, false)
          }
        })
      );
  }

  exportInvoices$(tenantId: string, exportData: MappingModel, params?: InvoicesFilter): Observable<HttpResponse<Blob>> {
    return this._httpClient.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/invoices`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          next: response => {
            const now = new Date();
            const filename = `export_invoices_${now.toLocaleDateString('it-IT')}.xlsx`;
            this._commonService.downloadFile(response, filename);
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listInvoiceFiles$(tenantId: string, invoiceId: string): Observable<InvoiceFileResponse> {
    return this._httpClient.get<InvoiceFileResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}/files`);
  }

  createInvoiceFile$(tenantId: string, invoiceId: string, file: InvoiceFileRequest): Observable<HttpResponse<Object>> {
    return this._httpClient.post<InvoiceFile>(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}/files`, file,
      { observe: 'response' })
      .pipe(
        tap({
          next: response => {
            if (response) {
              this._toasterService.success(this._translateService.instant("INVOICES.MESSAGES.INVOICE_LINKED"));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }

  downloadInvoiceFile$(tenantId: string, invoiceId: string, fileId: string): Observable<HttpResponse<Blob>> {
    return this._httpClient.get(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}/files/${fileId}`,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  deleteInvoiceFile$(tenantId: string, invoiceId: string, fileId: string): Observable<HttpResponse<Object>> {
    return this._httpClient.delete(`${VECTORE_API_BASE_URL}/${tenantId}/invoices/${invoiceId}/files/${fileId}`,
      { observe: 'response' })
      .pipe(
        tap({
          next: response => {
            if (response) {
              this._toasterService.success(this._translateService.instant("INVOICES.MESSAGES.INVOICE_LINKED_DELETED"));
            }
          },
          error: error => this._commonService.handleError(error)
        })
      );
  }
}
