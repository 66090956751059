import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { Task } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { CreateTaskRequest } from "src/app/shared/models/task/task-request";
import { Subject, takeUntil } from "rxjs";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

@Component({
    selector: 'task-information-edit',
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormlyModule, FormlyBootstrapModule],
    templateUrl: './task-information-edit.component.html',
    styleUrls: ['./task-information-edit.component.scss'],
})
export class TaskInformationEditComponent implements OnInit, OnDestroy {
  isEditMode: boolean = false;

  @Input() tenantId: string;
  @Input() task: Task;
  taskViewModel: Task;
  typeExtraFields: TypeExtraField[];
  @Input() taskRequest: CreateTaskRequest;

  form: FormGroup;
  fields: FormlyFieldConfig[];

  objectKeys = Object.keys;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tasksService: TasksService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    if (this.task) {
      this.taskViewModel = JSON.parse(JSON.stringify(this.task));
      this.getExtraFields();
    }
  }

  getExtraFields() {
    this._tasksService.listTaskFields$({ taskTypeId: this.taskViewModel.taskTypeId })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);

          this.taskViewModel.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.taskViewModel.extraFields);
          this.taskViewModel.extraFields = this._extraFieldsUtilsService.parseBoolName(this.typeExtraFields, this.taskViewModel.extraFields);

          this.taskRequest.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.taskRequest.extraFields, false);

          this.setValueForm();
        }
      });
  }

  editMode() {
    this.isEditMode = true;
  }

  cancel() {
    this.isEditMode = false;
  }

  setValueForm() {
    this.fields = this.typeExtraFields.map(field =>
      this._extraFieldsUtilsService.createFieldConfig(field, this.taskRequest.extraFields));
  }

  onSubmit() {
    if (this.typeExtraFields.length > 0) {
      this.taskRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.form.value, this.taskRequest);
      this.taskRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.taskRequest.extraFields);
    }

    if (!this.taskRequest.extraFields) {
      this.taskRequest.extraFields = {};
    }

    this.setValueForm();

    let extraFields = JSON.parse(JSON.stringify(this.taskRequest.extraFields));

    extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, extraFields);
    extraFields = this._extraFieldsUtilsService.parseBoolName(this.typeExtraFields, extraFields);
    this.taskViewModel.extraFields = extraFields;

    this.isEditMode = false;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}